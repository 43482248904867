import React, {useMemo} from "react";
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@material-ui/core";
import {PropertyDescriptor, PropertySource} from "./PropertyDescriptor";
import {uuid} from "uuidv4";

interface PropertiesViewProps {
    source?: PropertySource | null,
    onApply: (source: PropertySource, name: string, newValue: any) => void,
}

const PropertiesView: React.FC<PropertiesViewProps> = ({source, onApply}) => {
    const tableRows = useMemo(() => {
        return source?.getPropertyDescriptors()
            .map(property => <TableEditableRow
                property={property}
                key={uuid()}
                onApply={value => onApply(source, property.name, value)}
            />);
    }, [source?.getPropertyDescriptors()]);

    return <div className='properties-view'>
        <div className='properties-view-title'>
            <p>Properties{source?.title && `: ${source.title}`}</p>
        </div>
        <TableContainer className='properties-view-table'>
            <Table title='Properties' size='small'>
                <TableHead>
                    <TableRow>
                        <TableCell>Property</TableCell>
                        <TableCell>Value</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {tableRows}
                </TableBody>
            </Table>
        </TableContainer>
    </div>;
};

const TableEditableRow: React.FC<{ property: PropertyDescriptor, onApply: (value: any) => void }> = ({property, onApply}) => {
    function displayValue(value: any) {
        if (value === undefined || value === null)
            return '';
        return value + '';
    }

    return <TableRow hover title={displayValue(property?.displayValue || property.value)}>
        <TableCell>{property.displayName}</TableCell>
        <TableCell>
            {property.readOnly || !property.getCellEditor ?
                displayValue(property?.displayValue || property.value) :
                property.getCellEditor({
                    editor: {
                        onApply: val => onApply(val),
                        validate: property.validate,
                        value: property.value,
                    }
                })
            }
        </TableCell>
    </TableRow>
}

export default PropertiesView;