import {capitalize} from "@material-ui/core";

export const uniqueName = (name: string, names: string[]) => {
    let count = 1;
    while (true) {
        if (!names.includes(name))
            return name;
        if (!names.includes(`${name}${count}`))
            return `${name}${count}`;
        count++;
    }
}

export const prettifyEnum = (key: string) => {
    return capitalize(key.toLowerCase().replace(/_/gi, ' '));
}

export const _noop = () => {};