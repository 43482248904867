import config from "../../../config";
import HttpClient, {CancelToken} from "axios";
import { AdminCreateEntity, UpdateEntityContent } from "../../observables/Entity";
import { mapToEntity } from "../EntityService";

export class AdminEntityService {
    readonly BASE = `/${config.api_version}/cdm/admin/entity`;

    createEntity(entity: AdminCreateEntity, cancelToken?: CancelToken) {
        return HttpClient.post(this.BASE, entity, {
            headers: {'Content-Type': 'application/json'},
            cancelToken,
        }).then(({data}) => mapToEntity(data.entity))
    }

    validateEntityContent(content: UpdateEntityContent, cancelToken?: CancelToken) {
        return HttpClient.post(`${this.BASE}/content/validate`, content, {
            headers: {'Content-Type': 'application/json'},
            cancelToken,
        }).then(({data}) => data);
    }

    updateEntityContent(content: UpdateEntityContent, cancelToken?: CancelToken) {
        return HttpClient.put(`${this.BASE}/content`, content, {
            headers: {'Content-Type': 'application/json'},
            cancelToken,
        }).then(({data}) => data);
    }
}