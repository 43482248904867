import React, { useState, useEffect } from "react";
import Entity, { UpdateEntityContent } from "../../core/observables/Entity";
import {
    Button,
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
    DialogContentText,
    makeStyles,
    Theme,
    createStyles
} from "@material-ui/core";
import ReactJson from "react-json-view";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-dreamweaver";
import { CDMManager } from "../../core/CDMManager";
import { NegroniIcon } from "../icon/NegronIIcon";

const jsonStyles = makeStyles((theme: Theme) =>
    createStyles({
        jsonView: {
            [theme.breakpoints.down('lg')]: {
                width: '800px',
            },
            width: '1200px',
            overflow: 'auto',
        },
    }),
);

interface EntityJsonViewProp {
    entity: Entity;
    onUpdateEntityContent: (content: UpdateEntityContent, refresh: boolean) => Promise<string | undefined>;
    onApply: (content: string) => void;
}

export const EntityJsonView: React.FC<EntityJsonViewProp> = ({
    entity,
    onUpdateEntityContent,
    onApply,
}) => {
    const classes = jsonStyles();
    const [content, setContent] = useState<string>(entity.content || '{}');
    const [openEditSource, setOpenEditSource] = useState(false);
    const [isDirty, setIsDirty] = useState(false);
    const [refresh, setRefresh] = useState(false);

    useEffect(() => {
        const setContentObject = JSON.parse(content);
        setContentObject.id = entity.id;
        setContentObject.content = undefined;
        setContent(JSON.stringify(setContentObject));
    }, []);

    const applyContent = (content: string) => {
        setContent(content);
        onApply(content);
        setIsDirty(true);
    }

    return <div style={{padding: '10px'}}>
        <div style={{display: 'flex', justifyContent: 'flex-end'}}>
            <div hidden={!isDirty}>
                <Button
                    style={{
                        marginRight: '10px',
                    }}
                    size='small'
                    variant='contained'
                    color='secondary'
                    onClick={() => {
                        onUpdateEntityContent({content: content} as UpdateEntityContent, refresh);
                        localStorage.setItem(CDMManager.NEGRONI_RESET_SELECTED_NODE, 'true');
                    }}
                    disableElevation
                >
                    <NegroniIcon iconClass="save-icon" />
                </Button>
            </div>
            <Button
                size='small'
                variant='contained'
                color='secondary'
                onClick={() => {
                    setOpenEditSource(true);    
                }}
                disableElevation
            >
                <NegroniIcon iconClass="edit-icon" /> Edit Source
            </Button>
        </div>
        <div className={classes.jsonView}>
            <ReactJson
                src={JSON.parse(content)}
                name={entity.name}
                iconStyle='circle'
                onEdit={e => {
                    const parseContent = JSON.parse(JSON.stringify(e.updated_src));
                    if(entity.name !== parseContent.name) {
                        setRefresh(true);
                    }
                    setContent(JSON.stringify(e.updated_src));
                    setIsDirty(true);
                }}
                onAdd={e => {
                    setContent(JSON.stringify(e.updated_src));
                    setIsDirty(true);
                }}
                onDelete={e => {
                    setContent(JSON.stringify(e.updated_src));
                    setIsDirty(true);
                }}
                collapseStringsAfterLength={120}
            />
        </div>
        <EditSourceDialog
            id={entity.id}
            content={content}
            open={openEditSource}
            onClose={() => {
                setOpenEditSource(false);
            }}
            onApply={applyContent}
        />
    </div>
}

interface EditSourceDialogProp {
    id: number;
    content: string;
    open: boolean;
    onClose: () => void;
    onApply: (content: string) => void;
}

export const EditSourceDialog: React.FC<EditSourceDialogProp> = ({
    id,
    content,
    open,
    onClose,
    onApply,
}) => {
    const [contentObject, setContentObject] = useState(JSON.parse(content || '{}'));
    const [editorJsonString, setEditorJsonString] = useState<string>(JSON.stringify(contentObject, null, 4) || '');
    const [error, setError] = useState<string>('');

    useEffect(() => {
        contentObject.id = id;
        setEditorJsonString(JSON.stringify(contentObject, null, 4));
    }, []);

    return <Dialog
        open={open}
        onClose={onClose}
    >
        <DialogTitle>Edit Source</DialogTitle>
        <DialogContent dividers style={{overflow: 'hidden', maxWidth: '500px', width: '500px', minHeight: '500px'}}>
            { error !== '' && <DialogContentText style={{color:'var(--light-error-color)'}}>
                {error}
            </DialogContentText> }
            <AceEditor
                mode="json"
                theme="dreamweaver"
                showPrintMargin={true}
                showGutter={true}
                highlightActiveLine={true}
                value={editorJsonString}
                width="450px"
                setOptions={{ useWorker: false }}
                annotations={[]}
                onChange={value => {
                    try {
                        setEditorJsonString(value);
                        setContentObject(JSON.parse(value));
                        setError('');
                    } catch(error) {
                        setError('Syntax Error: ' + error?.message);
                    }
                }}
            />
        </DialogContent>
        <DialogActions>
            <Button onClick={onClose}>
                Cancel
            </Button>
            <Button
                variant='contained'
                color='secondary'
                disableElevation
                disabled={error !== ''}
                onClick={_ => {
                    onApply(JSON.stringify(contentObject));
                    onClose();
                }}
            >
                Apply
            </Button>
        </DialogActions>
    </Dialog>
}

export default EntityJsonView;