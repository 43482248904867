import React, { useState, useEffect, useCallback } from "react";
import { observer } from "mobx-react";
import Manifest from "../../core/observables/Manifest";
import useService from "../service/useService";
import { Dialog, DialogTitle, DialogContent, FormControl, DialogActions, Button, CircularProgress } from "@material-ui/core";
import { CancelTokenSource } from "axios";
import PaginatedResponse from "../../core/services/PaginatedResponse";
import { SelectOption, SelectGroupOption, PaginatedSelect } from "../select/PaginatedSelect";
import { Filter } from "../../core/Filter";
import useBuildManifest from "../manifest/build/useBuildManifest";

export interface ExportPackageWizardProp {
    open: boolean;
    onClose: () => void;
}

export const ExportPackageWizard: React.FC<ExportPackageWizardProp> = ({
    open,
    onClose,
}) => {
    const { manifestService } = useService();
    const [manifest, setManifest] = useState<Manifest>();
    const [page, setPage] = useState<Number>(1);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string>('');
    const openBuildManifest = useBuildManifest();

    useEffect(() => {
        if(open) {
            setManifest(undefined)
            setPage(1)
            setLoading(false)
            setError('')
        }
    }, [open]);

    const nextPage = () => {
        if(page === 1) {
            setPage(2)
            if(manifest) {
                manifestService.getManifestById(manifest.id).then(data => {
                    onClose();
                    openBuildManifest(data, false, true)
                })
            }
        }
    }

    const backPage = () => {
        if(page === 2) {
            setPage(1)
            setError('')
        }
    }

    const handleNextOrFinish = () => {
        if(page === 2) {
            setLoading(true);
        } else {
            nextPage();
        }
    }

    const handleLoadManifestOptions = useCallback((page: number, limit: number, search?: string, cancelTokenSource?: CancelTokenSource): Promise<PaginatedResponse<SelectOption[] | SelectGroupOption[]>> => {
        return manifestService.getManifests(Filter.PRIVATE, undefined, limit, page, search, cancelTokenSource?.token)
            .then(response => ({
                ...response,
                result: response.result.map(manifest => ({
                    label: manifest.name,
                    value: manifest,
                }))
            }))
    }, []);

    return <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="form-dialog-title">Export Manifest as Martini Package</DialogTitle>
        <DialogContent dividers style={{minHeight: '350px', minWidth: '550px'}}>
            {page === 1 ? <div>
                <p className='dialog-label'>Select a manifest to export</p>
                <FormControl variant='filled' fullWidth={true}>
                    <PaginatedSelect
                        getOptions={handleLoadManifestOptions}
                        valueMapper={value => value ? ({label: value.name, value: value.value}) : null}
                        placeholder='Select Manifest'
                        defaultValue={manifest}
                        onValueChanged={setManifest}
                        formatOptionLabel={option => {
                            return <div style={{display: 'flex', flexDirection: 'column'}}>
                                {option.label}
                            </div>;
                        }}
                        autoFocus={true}
                    />
                </FormControl>
            </div> : <></> }
        </DialogContent>
        <DialogActions>
            <Button onClick={onClose} style={{marginRight: 'auto'}}>
                Cancel
            </Button>
            <Button
                variant='contained'
                color='secondary'
                disabled={page === 1}
                onClick={backPage}
            >
                Back
            </Button>
            <Button
                variant='contained'
                color='secondary'
                disabled={loading || (!!error && error.length > 0) || (page === 1 && manifest === undefined) }
                disableElevation
                onClick={handleNextOrFinish}
                startIcon={loading && <CircularProgress size={18}/>}
            >
                {page === 2 ? "Finish" : "Next" }
            </Button>
        </DialogActions>
    </Dialog>
}

export default observer(ExportPackageWizard);