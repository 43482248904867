import React, { ReactElement, useEffect, useState } from "react";
import {
  debounce,
  Divider,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import { observer } from "mobx-react";
import { NegroniIcon } from "./icon/NegronIIcon";

interface SidebarProps {
  showSearch: boolean;
  children: (search: string) => ReactElement;
}

const Sidebar: React.FC<SidebarProps> = ({ showSearch, children }) => {
  const [search, setSearch] = useState("");

  const handleSearch = debounce((search) => {
    setSearch(search);
  }, 300);

  useEffect(() => {}, []);

  return (
    <>
      {showSearch ? (
        <>
          <SearchMenuBar onSearch={(search) => handleSearch(search)} />
          <Divider />
        </>
      ) : (
        <></>
      )}
      {children(search)}
    </>
  );
};

interface SearchMenuBarProps {
  onSearch: (query: string) => void;
}

const SearchMenuBar: React.FC<SearchMenuBarProps> = ({
  onSearch,
  children,
}) => {
  const [search, setSearch] = React.useState("");

  return (
    <div style={{ display: "flex" }}>
      <TextField
        placeholder="Search"
        style={{ flexGrow: 1, paddingLeft: "10px" }}
        value={search}
        onChange={(e) => {
          const search = e.target.value;
          onSearch(search);
          setSearch(search);
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <NegroniIcon iconClass="search-icon" />
            </InputAdornment>
          ),
        }}
        variant="standard"
      />
      {children}
    </div>
  );
};

export default observer(Sidebar);
