import React, {FC, useMemo} from "react";
import HttpInterceptorContext from "./HttpInterceptorContext";
import axios from "axios";
import axiosRetry from "axios-retry"
import config from "../../config";
import useAuth from "../auth/useAuth";
import {useLocation} from "react-router-dom";
import useSessionExpired from "../session_expired/useSessionExpired";
import useSession from "../session/useSession";
import { CDMManager } from "../../core/CDMManager";

const ORG_KEY = 'X-TORO-ECC-ORG';

/**
 * This provider intercepts the request and response of http.
 *   The request interceptor will inject the user's org in the header.
 *   The response interceptor handles the error messages.
 *     It also handles the token when it gets expired a dialog will pop up to ask the user to log in again.
 *
 * @param children
 */
const HttpInterceptorProvider: FC = ({children}) => {
    const {openSessionExpiredDialog} = useSessionExpired();
    const {logout, updateAuthState, ssoRefreshToken} = useAuth();
    const {isLoggedIn, hasRefreshToken, getRefreshToken, user} = useSession();
    const location = useLocation();

    axiosRetry(axios, {
        retries: 1,
        retryDelay: (retryCount) => {
            return retryCount * 2000; // time interval between retries
        },
        retryCondition: (error) => {
            if(error.response?.status === 401) {
                if(hasRefreshToken()) {
                    const refreshToken = getRefreshToken();
                    ssoRefreshToken( refreshToken ).then( _ => {
                        return true
                    }).catch(e => {
                        updateAuthState({isAuthRunning: false, isAuthenticated: false});
                        logout();
                        if (!location.pathname.includes('login') && localStorage.getItem(CDMManager.NEGRONI_LOGIN) !== 'TRUE') openSessionExpiredDialog();
                        return false
                    });
                }
            }
            return error.response?.status === 401;
        }
    })

    axios.defaults.baseURL = config.base_url;

    useMemo(() => {
        axios.interceptors.request.use((request) => {
            if(request.url?.startsWith('/1.0/cdm/sso/refresh')) return request;

            if(isLoggedIn()) {
                request.headers = {
                    [ORG_KEY]: user()?.organisationCode
                }
            } else {
                request.headers = {
                    [ORG_KEY]: user()?.organisationCode
                }
            }
            request.withCredentials = true;
            return request;
        });

        axios.interceptors.response.use(undefined, (error) => {
            if (axios.isCancel(error))
                return Promise.reject(error.message);

            if (!error.response) return window.location.href = '/500.html';

            if (error.response.status === 401) {
                logout();
                return window.location.href = '/401.html';
            }

            if (error.response.status < 200 || error.response.status > 299)
                return Promise.reject(error.response.data?.message || `Request failed with reason - ${error.response.data}`);
            return error;
        });
    }, [isLoggedIn, logout, user]);

    return <>
        <HttpInterceptorContext.Provider value={undefined as any}>
            {children}
        </HttpInterceptorContext.Provider>
    </>;
};

export default HttpInterceptorProvider;