import config from "../../config";
import HttpClient, {CancelToken} from "axios";
import PaginatedResponse from "./PaginatedResponse";
import DocumentItem from "../observables/DocumentItem";
import { CDMDocumentType } from "../observables/CDMDocument";
import { UploadPackage } from "../observables/Instance";

export class WorkspaceService {
    readonly BASE = `/${config.api_version}/cdm`;

    getWorkspaces(page: number = 0, size: number = 1000, cancelToken?: CancelToken): Promise<PaginatedResponse<DocumentItem[]>> {
        return HttpClient.get(`${this.BASE}/workspaces`, {
            params: {
                page,
                size,
            },
            cancelToken,
        }).then(({data}) => ({
            totalElements: data.size,
            size: data.numberOfElements,
            page: data.number,
            status: data.result,
            message: data.message,
            result: data.workspaces.content.map(rawContentToInstance),
        }));
    }

    getWorkspacePackages(uploadPackage: UploadPackage, cancelToken?: CancelToken) {
        return HttpClient.post(`${this.BASE}/workspace/packages`, uploadPackage, {
                headers: {'Content-Type': 'application/json'},
                cancelToken,
        }).then(({data}) => data)
    }

    uploadPackage(packageId: number, uploadPackage: UploadPackage, cancelToken?: CancelToken) {
        return HttpClient.post(`${this.BASE}/packages/${packageId}/upload`, uploadPackage, {
                headers: {'Content-Type': 'application/json'},
                cancelToken,
        }).then(({data}) => data)
    }
    
}

export const rawContentToInstance = (raw: any): DocumentItem => {
    return {
        name: `${raw.hostname} (${raw.url})`,
        path: raw.url,
        documentType: CDMDocumentType.INSTANCE,
        id: raw?.id || -1,
        isDefault: false,
        type: 'documentItem',
    } as DocumentItem;
}