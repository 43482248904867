import React, { FC, useState } from "react";
import { makeStyles, createStyles, Toolbar, Tooltip, IconButton, Drawer, Menu, MenuItem, ListItemIcon, ListItemText } from "@material-ui/core";
import useSession from "../session/useSession";
import { NegroniIcon } from "../icon/NegronIIcon";

const toolbarStyles = makeStyles(() =>
    createStyles({
        drawer: {
            width: 'calc(100%)',
            flexShrink: 0,
        },
        drawerPaper: {
            width: 'calc(100%)',
            borderRight: 0,
            marginTop: 45,
            height: 44,
            backgroundColor: '#2B263A',
        },
        toolBar: {
            minHeight: 36,
            paddingLeft: 0,
        },
        navigatorIcon: {
            minWidth: 35,
        },
    }),
);

interface MainToolbarProps {
    manifestSize: number;
    openManifestWizard: () => void;
    openEntityWizard: () => void;
    openExportDatabaseStructure: () => void;
    openExportCdmSchema: () => void;
    openExportPackage: () => void;
    openImportWizard: () => void;
}

export const MainToolbar: FC<MainToolbarProps> = ({
    manifestSize,
    openManifestWizard,
    openEntityWizard,
    openExportDatabaseStructure,
    openExportCdmSchema,
    openExportPackage,
    openImportWizard,
}) => {
    const classes = toolbarStyles();
    const {subscription} = useSession();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    
    const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    return (
      <>
        <Drawer
          id="negroni-toolbar-drawer"
          open={true}
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="permanent"
          elevation={0}
          anchor="left"
        >
          <Toolbar id="negroni-toolbar" className={classes.toolBar}>
            <Tooltip title="New Manifest">
              <IconButton
                id="negroni-toolbar-manifest-wizard"
                color="primary"
                onClick={handleMenuClick}
              >
                <NegroniIcon iconClass="add-square-icon" color="#DDF1F0" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Generate Database Schema">
              <IconButton
                id="negroni-toolbar-generate-database"
                disabled={subscription() === "FREE" || manifestSize <= 0}
                style={
                  subscription() === "FREE" || manifestSize <= 0
                    ? { color: "var(--gray-color)" }
                    : { color: "var(--primary-color)" }
                }
                onClick={openExportDatabaseStructure}
              >
                <NegroniIcon iconClass="storage-icon" color="#DDF1F0" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Generate CDM Schema">
              <IconButton
                id="negroni-toolbar-generate-cdm"
                disabled={manifestSize <= 0}
                style={
                  manifestSize <= 0
                    ? { color: "var(--gray-color)" }
                    : { color: "var(--primary-color)" }
                }
                onClick={openExportCdmSchema}
              >
                <NegroniIcon iconClass="description-icon" color="#DDF1F0" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Generate Martini Package">
              <IconButton
                id="negroni-toolbar-generate-martini"
                disabled={manifestSize <= 0}
                style={
                  manifestSize <= 0
                    ? { color: "var(--gray-color)" }
                    : { color: "var(--primary-color)" }
                }
                onClick={openExportPackage}
              >
                <NegroniIcon iconClass="wrench-icon" color="#DDF1F0" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Import">
              <IconButton
                id="negroni-toolbar-import-wizard"
                color="primary"
                onClick={openImportWizard}
              >
                <NegroniIcon iconClass="import-icon" color="#DDF1F0" />
              </IconButton>
            </Tooltip>
          </Toolbar>
        </Drawer>
        <Menu
          id="new-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          onBlur={handleMenuClose}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          transformOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <MenuItem onClick={openManifestWizard}>
            <ListItemIcon className={classes.navigatorIcon}>
              <NegroniIcon iconClass="assignment-icon" />
            </ListItemIcon>
            <ListItemText primary="Manifest" />
          </MenuItem>
          <MenuItem onClick={openEntityWizard}>
            <ListItemIcon className={classes.navigatorIcon}>
              <NegroniIcon iconClass="description-icon" />
            </ListItemIcon>
            <ListItemText primary="Entity" />
          </MenuItem>
        </Menu>
      </>
    );
}

export default MainToolbar;