import React, {FC, ReactElement, useState} from "react";
import {createStyles, Drawer, Fab, Hidden, Theme, useTheme} from "@material-ui/core";
import clsx from "clsx";
import {makeStyles} from "@material-ui/core/styles";
import { NegroniIcon } from "./icon/NegronIIcon";

const drawerWidth = 280;

const navigatorStyles = makeStyles((theme: Theme) =>
    createStyles({
        drawer: {
            [theme.breakpoints.up('sm')]: {
                width: drawerWidth,
                flexShrink: 0,
            },
        },
        drawerPaper: {
            width: drawerWidth,
            marginTop: 0,
            borderRight: 0,
            [theme.breakpoints.up('sm')]: {
                marginTop: 90,
                height: 'calc(100% - 90px)',
            },
        },
        persistentDrawerPaper: {
            width: drawerWidth,
            flexShrink: 0,
            whiteSpace: 'nowrap',
            marginTop: 0,
            [theme.breakpoints.up('sm')]: {
                marginTop: 90,
                height: 'calc(100% - 45px)',
            },
            overflowX: 'hidden',
            backgroundColor: "#FBF8FF",
        },
        persistentDrawerOpen: {
            width: drawerWidth,
            transition: theme.transitions.create('all', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        persistentDrawerClose: {
            transition: theme.transitions.create('all', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            overflowX: 'hidden',
            marginLeft: `-${drawerWidth - 70}px`
        },
        navigatorIcon: {
            minWidth: 35,
        },
        fab: {
            marginLeft: '-10px',
            marginTop: '144px',
        },
        splitterOpen: {
            position: 'fixed',
            width: '5px',
            zIndex: 1201,
            height: 'calc(100% - 70px)',
            display: 'block',
            cursor: 'pointer',
            '&:hover': {
                borderLeft: 'solid var(--secondary-color) 2px',
            },
            marginLeft: `${drawerWidth - 2}px`,
        },
        splitterClose: {
            position: 'fixed',
            width: '5px',
            zIndex: 1201,
            height: 'calc(100% - 70px)',
            display: 'block',
            cursor: 'pointer',
            '&:hover': {
                borderLeft: 'solid var(--secondary-color) 2px',
            },
            marginLeft: '70px',
        },
    }),
);

interface SidebarContainerProps {
    children: (openPersistentDrawer: boolean) => ReactElement;
    onMobileClose: () => void;
    mobile: boolean;
}

const SidebarContainer: FC<SidebarContainerProps> = ({children, onMobileClose, mobile}) => {
    const theme = useTheme();
    const [openPersistentDrawer, setOpenPersistentDrawer] = useState(true);
    const [showSidebarFab, setShowSidebarFab] = useState(false);
    const classes = navigatorStyles();

    const handlePersistentDrawerToggle = () => {
        setOpenPersistentDrawer(!openPersistentDrawer);
    }

    return <>
        <div id="negroni-sidebar-container" className={openPersistentDrawer ? classes.drawer : undefined} style={{marginTop: '140px'}}>
            <Hidden smUp>
                <Drawer
                    container={window.document.body}
                    variant="temporary"
                    anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                    open={mobile}
                    onClose={onMobileClose}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                    elevation={0}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                >
                    {children(openPersistentDrawer)}
                </Drawer>
            </Hidden>
            <Hidden xsDown>
                <div className={openPersistentDrawer ? classes.splitterOpen : classes.splitterClose}
                     onClick={handlePersistentDrawerToggle}
                     onMouseOver={_ => {
                         if (!showSidebarFab)
                             setShowSidebarFab(true)
                     }}
                     onMouseOut={_ => {
                         if (showSidebarFab)
                             setShowSidebarFab(false)
                     }}
                >
                    <div hidden={!showSidebarFab}>
                        <Fab
                            onClick={handlePersistentDrawerToggle}
                            size='small'
                            className={classes.fab}
                            onMouseOver={_ => {
                                if (!showSidebarFab)
                                    setShowSidebarFab(true)
                            }}
                            onMouseOut={_ => {
                                if (showSidebarFab)
                                    setShowSidebarFab(false)
                            }}
                        >
                            <NegroniIcon iconClass={openPersistentDrawer ? 'chevron_left-icon' : 'chevron_right-icon'} />
                        </Fab>
                    </div>
                </div>
                <Drawer
                    className={clsx(classes.persistentDrawerPaper, {
                        [classes.persistentDrawerOpen]: openPersistentDrawer,
                        [classes.persistentDrawerClose]: !openPersistentDrawer,
                    })}
                    classes={{
                        paper: clsx(classes.persistentDrawerPaper, {
                            [classes.persistentDrawerOpen]: openPersistentDrawer,
                            [classes.persistentDrawerClose]: !openPersistentDrawer,
                        })
                    }}
                    variant="permanent"
                    elevation={0}
                    anchor='left'
                >
                    {children(openPersistentDrawer)}
                </Drawer>
            </Hidden>
        </div>
    </>;
}

export default SidebarContainer;