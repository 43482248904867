import React, {FC, useState} from "react";
import SessionExpiredDialogContext from "./SessionExpiredContext";
import SessionExpiredDialog from "../auth/SessionExpiredDialog";

const SessionExpiredProvider: FC = ({children}) => {
    const [openSessionExpiredDialog, setOpenSessionExpiredDialog] = useState(false);

    const handleCloseDialog = () => setOpenSessionExpiredDialog(false);

    return <>
        <SessionExpiredDialogContext.Provider value={{
            openSessionExpiredDialog: () => setOpenSessionExpiredDialog(true),
            closeSessionExpiredDialog: handleCloseDialog,
        }}>
            {children}
        </SessionExpiredDialogContext.Provider>
        <SessionExpiredDialog
            open={openSessionExpiredDialog}
            onClose={handleCloseDialog}
        />
    </>;
};

export default SessionExpiredProvider;