import Package from "../observables/Package";
import HttpClient, {CancelToken} from "axios";
import config from "../../config";

export enum PackageFilter {
    PRIVATE = 'private',
    PUBLIC = 'public',
}

export enum PackageStatus {
    PROCESSING = 'PROCESSING',
    FAILED = 'FAILED',
    SUCCESS = 'SUCCESS',
}

export default class PackageService {
    readonly BASE = `/${config.api_version}/cdm/packages`

    getPackage(filter: PackageFilter = PackageFilter.PRIVATE, cancelToken?: CancelToken): Promise<Package[]> {
        return HttpClient.get(`${this.BASE}`, {
            params: {filter},
            cancelToken,
        }).then(({data}) => data.packages.map(mapToPackage))
    }

    getPackageByManifest(filter: PackageFilter = PackageFilter.PRIVATE, manifestId: number, cancelToken?: CancelToken): Promise<Package[]> {
        return HttpClient.get(`${this.BASE}`, {
            params: {filter, manifestId},
            cancelToken,
        }).then(({data}) => data.packages.map(mapToPackage))
    }

    getPackageStatus(packageIds: number[], cancelToken?: CancelToken): Promise<{ id: number, status: PackageStatus }[]> {
        return HttpClient.get(`${this.BASE}/status?packageIds=${packageIds}`, {cancelToken})
            .then(({data}) => data.packages);
    }

    downloadPackage(packageId: number, version: string, cancelToken?: CancelToken) {
        return HttpClient.get(`${this.BASE}/${packageId}/download`, {
            params: {version},
            responseType: 'arraybuffer',
            cancelToken,
        }).then(({data}) => data);
    }

    deletePackage(packageId: number, version: string, cancelToken?: CancelToken) {
        return HttpClient.delete(`${this.BASE}/${packageId}`, {
            params: {version},
            cancelToken,
        }).then(({data}) => data);
    }
}

export const mapToPackage = (raw: any): Package => {
    const eccPackage = new Package(raw.id, raw.userId, raw.guid, raw.manifestName, raw.manifestPath, raw.versions);
    eccPackage.status = raw.status;
    return eccPackage;
}