import { observer } from "mobx-react"
import {
    DialogTitle,
    DialogActions,
    Button,
    DialogContent,
    CircularProgress,
    TextField,
    FormControlLabel,
    Checkbox,
    TableContainer,
    Table,
    TableRow,
    TableCell,
    FormControl,
    Tooltip,
    Toolbar,
    Typography,
    IconButton,
    TableHead,
    TableBody,
    Grid,
    Paper,
    Tab,
    FormHelperText,
    Radio,
    Box,
    makeStyles,
    createStyles,
    Dialog
} from "@material-ui/core";
import React, { useState, useCallback, useEffect } from "react";
import Manifest, { MartiniProperties, ConnectionPoolType, ApiTypes, CreateManifestWizard, makeCreateManifestWizard, ApiSecurity } from "../../core/observables/Manifest";
import { Filter } from "../../core/Filter";
import { CancelTokenSource } from "axios";
import PaginatedResponse from "../../core/services/PaginatedResponse";
import { SelectGroupOption, SelectOption, PaginatedSelect } from "../select/PaginatedSelect";
import DocumentItem from "../../core/observables/DocumentItem";
import useService from "../service/useService";
import { Clear, FilterList, Add } from "@material-ui/icons";
import {Position} from "../ContextMenu";
import { Alert, TabContext, TabList, TabPanel } from "@material-ui/lab";
import { ManageEntityTabValue } from "../entity/ManageEntityDialog";
import Entity, { CreateEntityType } from "../../core/observables/Entity";
import { NAME_PATTERN } from "../../core/constants";
import * as yup from "yup";
import Modal from "react-modal"
import { NegroniIcon } from "../icon/NegronIIcon";

enum GenerateType {
    MANIFEST = 'Manifest',
    DATABASE = 'Database',
    DATABASEAPI = 'DatabaseApi',
}

const ManifestWizardStyles = makeStyles(() =>
    createStyles({
        boxModal: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '400',
            border: '2px solid #000',
            boxShadow: '24',
            p: '4',
            minHeight: '350px',
            minWidth: '550px',
        },
    }),
);

export interface ManifestWizardProp {
    open: boolean;
    manifestSize: number;
    initialManifestName: string;
    onClose: () => void;
    onCreateManifestViaWizard: (manifest: CreateManifestWizard) => Promise<string | undefined>;
    joyrideRunning?: boolean;
    joyrideIndex?: number;
    indexTrigger?: (index: number) => void;
    joyrideTrigger?: boolean;
    endTour?: () => void;
}

export const ManifestWizard: React.FC<ManifestWizardProp> = ({
    open,
    manifestSize,
    initialManifestName,
    onClose,
    onCreateManifestViaWizard,
    joyrideRunning,
    joyrideIndex,
    indexTrigger,
    joyrideTrigger,
    endTour,
}) => {
    const {entityService, manifestService} = useService();
    const classes = ManifestWizardStyles();
    const [manifest, setManifest] = useState<Manifest>(new Manifest(-1, initialManifestName, ''));
    const [duplicateManifest, setDuplicateManifest] = useState<DocumentItem>();
    const [page, setPage] = useState<Number>(1);
    const [loading, setLoading] = useState(false);
    const [existingManifest, setExistingManifest] = useState(false);
    const [, setNameInput] = useState<HTMLInputElement>();
    const [error, setError] = useState<string | null>();
    const [finishError, setFinishError] = useState<string | null>();
    const [filter, setFilter] = React.useState<Filter>(Filter.RESOLVED);
    const [advanceFilter, setAdvanceFilter] = useState(false);
    const [folderFilter, setFolderFilter] = useState(false);
    const [folderFilterValue, setFolderFilterValue] = useState<string>('');
    const [pathFilter, setPathFilter] = useState(false);
    const [pathFilterValue, setPathFilterValue] = useState<string>('');
    const [addEntity, setAddEntity] = React.useState<DocumentItem | null>(null);
    const [updateAddEntity, setUpdateAddEntity] = useState(0);
    const [doNotAddEntity, setDoNotAddEntity] = useState(true);
    const [doNotAddExclusiveEntity, setDoNotAddExclusiveEntity] = useState(false);
    const [selectedEntities, setSelectedEntities] = useState<DocumentItem[]>([]);
    const [, setSelection] = useState<DocumentItem | null>(null);
    const [entityList, setEntityList] = useState<DocumentItem[]>([]);
    const [tablePage] = useState(0);
    const [rowsPerPage] = useState(50);
    const [, setPosition] = useState<Position | null>(null);
    const [withDatabase, setWithDatabase] = useState(false);
    const [withApi, setWithApi] = useState(false);
    const [tab, setTab] = useState<ManageEntityTabValue>(ManageEntityTabValue.ADD_ENTITY);
    const [exclusiveEntity, setExclusiveEntity] = useState<Entity>(new Entity(-1, 'new_entity', '', CreateEntityType.EXCLUSIVE, -1));
    const [generateType, setGenerateType] = useState<GenerateType>(GenerateType.MANIFEST);
    const [version, setVersion] = useState<string>('1.0.0');
    const [, setVersionError] = useState<string>('');
    let entitiesList: DocumentItem[] = [];

    const [currentBuild, setCurrentBuild] = useState<MartiniProperties>({
        manifestName: '',
        packageProperties: {
            connectionPoolInfo: {
                type: ConnectionPoolType.PostgreSQL,
                databaseName: '',
                password: '',
                username: '',
                url: '',
            },
            customProperties: [],
        },
        configurationBuild: {
            apiConfiguration: {
                type: [ApiTypes.REST],
                security: ApiSecurity.NONE,
                excludeOperations: [],
            },
            sqlConfiguration: {
                applyAutoIncrement: true,
                applyCoalesce: false,
                applyLimitOffset: false,
                applyJoin: false,
            },
            serviceConfiguration: {
                applyMultiTenancy: false,
                applyCustomField: false,
                applySot: false,
                sotEntities: [],
            },
            aggregateQueries: [],
        },
    });

    useEffect(() => {
        if(joyrideIndex) {
            if(joyrideIndex === 3) {
                setPage(3)
            }
            if(joyrideIndex === 4) {
                setTab(ManageEntityTabValue.CREATE_EXCLUSIVE)
            }
        }
    }, [joyrideIndex]);

    const nextPage = () => {
        if(page === 1) {
            if(existingManifest) {
                setPage(2);
            } else {
                setPage(3)
            }
            if(joyrideRunning && indexTrigger) {
                indexTrigger(3)
            }
        }
        if(page === 2) {
            setPage(3)
            if(joyrideRunning && indexTrigger) {
                indexTrigger(4)
            }
        }
    }

    const backPage = () => {
        if(page === 2)
            setPage(1)
        if(page === 3) {
            if(existingManifest) {
                setPage(2);
            } else {
                setPage(1)
            }
        }
    }

    const handleManifestType = () => {
        setExistingManifest(!existingManifest);
    }



    const isSelected = (entity: DocumentItem) => selectedEntities.indexOf(entity) !== -1;

    function handleContextMenu(e: React.MouseEvent<HTMLTableRowElement>, entity: DocumentItem) {
        setSelection(entity);

        e.preventDefault();
        setPosition({
            mouseX: e.clientX,
            mouseY: e.clientY,
        });
    }

    const addEntityToList = () => {
        if(addEntity) {
            setEntityList([...entityList, addEntity]);
            setAddEntity(null);
        }
    }

    const addExclusiveEntityToList = (entity: Entity) => {
        setEntityList([...entityList, {
            id: -1,
            name: entity.name,
            path: 'Excluse entity to be created'
        } as DocumentItem]);
        setDoNotAddExclusiveEntity(true);
        if(joyrideRunning && indexTrigger) {
            indexTrigger(7)
        }
    }

    useEffect(() => {
        if(open) {
            setPage(1);
            setTab(ManageEntityTabValue.ADD_ENTITY);
            setManifest(new Manifest(-1, initialManifestName, ''));
            setDuplicateManifest(undefined);
            setVersion('1.0.0');
            setGenerateType(GenerateType.MANIFEST);
            setEntityList([]);
            setAddEntity(null);
            setExclusiveEntity(new Entity(-1, 'new_entity', '', CreateEntityType.EXCLUSIVE, manifest?.id));
            setFilter(Filter.RESOLVED);
            setWithDatabase(false);
            setWithApi(false);
            setDoNotAddExclusiveEntity(false);
            setDoNotAddEntity(true);
            setExistingManifest(false);
            setVersionError('');
            setError(null);
            setFinishError(null);
            setCurrentBuild({
                manifestName: '',
                packageProperties: {
                    connectionPoolInfo: {
                        type: ConnectionPoolType.PostgreSQL,
                        databaseName: '',
                        password: '',
                        username: '',
                        url: '',
                    },
                    customProperties: [],
                },
                configurationBuild: {
                    apiConfiguration: {
                        type: [ApiTypes.REST],
                        security: ApiSecurity.NONE,
                        excludeOperations: [],
                    },
                    sqlConfiguration: {
                        applyAutoIncrement: true,
                        applyCoalesce: false,
                        applyLimitOffset: false,
                        applyJoin: false,
                    },
                    serviceConfiguration: {
                        applyMultiTenancy: false,
                        applyCustomField: false,
                        applySot: false,
                        sotEntities: [],
                    },
                    aggregateQueries: [],
                },
            })
        }
    }, [open]);

    useEffect(() => {
        if(filter === Filter.PRIVATE) {
            resetFilter();
        }
        refreshAddEntity();
    }, [filter]);

    useEffect(() => {
        validateName(exclusiveEntity.name);

        if(entityList.find(entity => entity.name === exclusiveEntity.name)) {
            setDoNotAddExclusiveEntity(true);
        } else {
            setDoNotAddExclusiveEntity(false);
        }
    }, [exclusiveEntity.name]);

    useEffect(() => {
        validateName(manifest.name);
    }, [manifest.name]);

    useEffect(() => {
        if(entityList.find(entity => entity.id === addEntity?.id || entity.name === addEntity?.name)) {
            setDoNotAddEntity(true);
        } else {
            setDoNotAddEntity(false);
        }
    }, [addEntity]);

    useEffect(() => {
        if(withDatabase) {
            setGenerateType(GenerateType.DATABASE);
        }
        if(!withDatabase && !withApi) {
            setGenerateType(GenerateType.MANIFEST);
        }
    }, [withDatabase]);

    useEffect(() => {
        if(withApi) {
            setGenerateType(GenerateType.DATABASEAPI);
        }
        if(!withDatabase && !withApi) {
            setGenerateType(GenerateType.MANIFEST);
        }
    }, [withApi]);

    useEffect(() => {
        setDuplicateManifest(undefined);
    }, [existingManifest])

    useEffect(() => {
        if(duplicateManifest) {
            manifestService.getEntitiesByManifest(duplicateManifest.id, -1)
                .then(response => {
                    setEntityList([...response.result])
                })
        }
    }, [duplicateManifest])

    useEffect(() => {
        manifest.setEntityPaths = entityList
    }, [entityList])

    const validateName = (name: String) => {
        try {
            yup.string()
                .required()
                .min(Entity.MIN_NAME_LENGTH)
                .max(Entity.MAX_NAME_LENGTH)
                .matches(NAME_PATTERN, 'this must only contain alphanumeric and no underscore consecutively')
                .validateSync(name);
            setError('');
        } catch (e) {
            setError(e.message);
        }
    };

    const getEntities = (page: number = 0, limit: number = 20, search: string = '', cancelTokenSource?: CancelTokenSource): Promise<PaginatedResponse<SelectGroupOption[] | SelectOption[]>> => {
        return entityService.getEntities(filter, limit, page, search, -1, folderFilterValue, pathFilterValue, cancelTokenSource?.token)
            .then(response => {
                return {
                    ...response,
                    result: response.result.map((entity) => ({label: entity.name, value: entity}))
                } as PaginatedResponse<SelectOption[]>;
            });
    };

    const getCoreRelativePath = (location: string): string => {
        location = location.replace('cdm:/core/', '');
        let splitLocation = location.split('/');
        splitLocation.splice(splitLocation.length - 1, 1);
        return splitLocation.join('/');
    }

    const refreshAddEntity = () => {
        setUpdateAddEntity(updateAddEntity + 1);
    }

    const handleFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFilter(event.target.checked ? Filter.RESOLVED : Filter.PRIVATE);
        if( filter === Filter.PRIVATE ) {
            resetFilter();
        }
    }

    const handleAdvanceFilter = () => setAdvanceFilter(!advanceFilter);

    const handleFolderFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFolderFilter(event.target.checked);
        setFolderFilterValue('');
        refreshAddEntity();
    }

    const handlePathFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPathFilter(event.target.checked);
        setPathFilterValue('');
        refreshAddEntity();
    }

    const resetFilter = () => {
        setAdvanceFilter(false);
        setFolderFilter(false);
        setFolderFilterValue('');
        setPathFilter(false);
        setPathFilterValue('');
        refreshAddEntity();
    }

    const hideFilter = () => {
        setAdvanceFilter(false);
    }

    function handleClick(entity: DocumentItem) {
        const selectedIndex = selectedEntities.indexOf(entity)
        let newSelected: DocumentItem[] = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selectedEntities, entity);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selectedEntities.slice(1));
        } else if (selectedIndex === selectedEntities.length - 1) {
            newSelected = newSelected.concat(selectedEntities.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selectedEntities.slice(0, selectedIndex),
                selectedEntities.slice(selectedIndex + 1),
            );
        }

        setSelectedEntities(newSelected);
    }

    function deleteEntities(entities: DocumentItem[]) {
        entitiesList = entityList
        entities.forEach(entity => {
            const deleteIndex = entitiesList.indexOf(entity, 0);
            entitiesList.splice(deleteIndex, 1);
        });
        setSelectedEntities([]);
        setEntityList([...entitiesList]);
    }

    function handleDeleteButton() {
        if (selectedEntities.length > 0) {
            deleteEntities(selectedEntities)
        }
    }

    const handleLoadManifestOptions = useCallback((page: number, limit: number, search?: string, cancelTokenSource?: CancelTokenSource): Promise<PaginatedResponse<SelectOption[] | SelectGroupOption[]>> => {
        return manifestService.getManifests(Filter.PRIVATE, undefined, limit, page, search, cancelTokenSource?.token)
            .then(response => ({
                ...response,
                result: response.result.map(manifest => ({
                    label: manifest.name,
                    value: manifest,
                }))
            }))
    }, []);

    const handleSubmit = () => {
        setLoading(true)
        onCreateManifestViaWizard(makeCreateManifestWizard(
            manifest,
            duplicateManifest?.id || -1,
            entityList,
            [currentBuild], 
            version,
            generateType,
        ))
            .then((error: string | undefined) => {
                setLoading(false);
                if (error && error.length > 0) {
                    setFinishError(error);
                } else {
                    onClose();
                }
                if(joyrideRunning && endTour) {
                    endTour()
                }
            });
    }

    const handleNextOrFinish = () => {
        if( page === 3 ) {
            handleSubmit();
        } else {
            nextPage();
        }
    }

    return !joyrideTrigger ?
    <Dialog
        id="negroni-manifest-wizard-dialog"
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="form-dialog-title">Create Manifest</DialogTitle>
        <DialogContent id="negroni-manifest-wizard-content" dividers style={{minHeight: '350px', minWidth: '550px'}}>
            {page === 1 ? <div>
                <TextField
                    autoFocus={true}
                    inputRef={setNameInput}
                    margin="dense"
                    id="manifestName"
                    label="Manifest Name"
                    fullWidth
                    color='primary'
                    variant='filled'
                    value={manifest?.name}
                    onChange={e => setManifest(new Manifest(-1, e.target.value, ''))}
                    error={!!error}
                    helperText={error}
                />
                { manifestSize !== 0 ? <>
                    <Grid container>
                        <FormControlLabel
                            control={<Radio checked={!existingManifest} onChange={handleManifestType}
                                            inputProps={{'aria-label': 'Select Empty Manifest'}}/>}
                            label="Empty Manifest"
                        />
                    </Grid>
                    <Grid container>
                        <FormControlLabel
                            control={<Radio checked={existingManifest} onChange={handleManifestType}
                                            inputProps={{'aria-label': 'Select Existing Manifest'}}/>}
                            label="Duplicate Existing Manifest"
                        />
                    </Grid>
                </> : <></> }
                </div> : <></> }
            {page === 2 ? <div>
                <p className='dialog-label'>Select a manifest to duplicate</p>
                <FormControl variant='filled' fullWidth={true}>
                    <PaginatedSelect
                        getOptions={handleLoadManifestOptions}
                        valueMapper={value => value ? ({label: value.name, value: value.value}) : null}
                        placeholder='Select Manifest'
                        defaultValue={duplicateManifest}
                        onValueChanged={setDuplicateManifest}
                        formatOptionLabel={option => {
                            return <div style={{display: 'flex', flexDirection: 'column'}}>
                                {option.label}
                            </div>;
                        }}
                        autoFocus={true}
                    />
                </FormControl>
            </div> : <></> }
            {page === 3 ? <div>
                {finishError && (
                <Alert severity="error" style={{ width: "100%", marginBottom: "0.5rem" }}>
                  {" "}
                  {finishError}{" "}
                </Alert>
              )}
                <p className='dialog-label'>Manage Entities</p>
                <TabContext value={tab}>
                    <Paper elevation={0} square>
                        <TabList
                            value={tab}
                            onChange={(e, value) => {
                                setTab(value);
                            }}
                            variant='fullWidth'
                        >
                            <Tab value={ManageEntityTabValue.ADD_ENTITY} label='Choose Existing Entity'/>
                            <Tab value={ManageEntityTabValue.CREATE_EXCLUSIVE} label='Create Local Entity'/>
                        </TabList>
                    </Paper>
                    <TabPanel value={ManageEntityTabValue.ADD_ENTITY}>
                        <Grid container style={{marginTop: 10, marginBottom: 10}}>
                            <Grid item xs={5}>
                                <FormControlLabel
                                    control={<Checkbox checked={filter === Filter.RESOLVED} onChange={handleFilter}
                                                    inputProps={{'aria-label': 'Select custom entities'}}/>}
                                    label="Show Core Entities"
                                />
                            </Grid>
                            <Grid item xs={7} style={{ display: "flex", justifyContent: "flex-end" }}>
                                <Tooltip
                                    title={'Add Entity'}
                                >
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        style={{marginLeft: '10px'}}
                                        disabled={doNotAddEntity || (addEntity === null)}
                                        onClick={addEntityToList}
                                    >
                                        <Add/>
                                    </Button>
                                </Tooltip>
                            </Grid>
                        </Grid>
                        <FormControl variant='filled' fullWidth={true} style={{display: 'flex', flexDirection: 'row'}}>
                            <div style={{width: '100%'}}>
                                <PaginatedSelect
                                    autoFocus={tab === ManageEntityTabValue.ADD_ENTITY}
                                    getOptions={getEntities}
                                    valueMapper={value => value ? ({label: value.name, value: value.value}) : null}
                                    onValueChanged={setAddEntity}
                                    update={updateAddEntity}
                                    formatOptionLabel={option => {
                                        return <div style={{display: 'flex', flexDirection: 'column'}}>
                                            {option.label}
                                            {(filter === Filter.RESOLVED && option.value?.path) && <small
                                                style={{color: 'var(--label-1-color)'}}>{getCoreRelativePath(option?.value?.path)}</small>}
                                        </div>;
                                    }}
                                    hideFilter={hideFilter}
                                />
                            </div>
                            {filter === Filter.RESOLVED ? <Tooltip
                                title={advanceFilter ? 'Hide Advance Filters' : 'Advance Filters'}
                            >
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    style={{marginLeft: '10px'}}
                                    onClick={handleAdvanceFilter}
                                >
                                    {advanceFilter ? <Clear/> : <FilterList/>}
                                </Button>
                            </Tooltip> : undefined}
                        </FormControl>
                        <FormHelperText error={!!error && error.length > 0}>{error}</FormHelperText>
                        { advanceFilter && filter === Filter.RESOLVED  ? <FormControl variant='filled' fullWidth={true} style={{display: 'flex', flexDirection: 'column'}}>
                            <div>
                                <FormControlLabel
                                    control={<Checkbox checked={folderFilter} onChange={handleFolderFilter}
                                                    inputProps={{'aria-label': 'Filter by folder'}}/>}
                                    label="By Folder"
                                />
                                { folderFilter ? <TextField
                                    value={folderFilterValue}
                                    onChange={e => {
                                        setFolderFilterValue(e.target.value);
                                        refreshAddEntity();
                                    }}
                                    type='text'
                                    autoFocus
                                    fullWidth={true}
                                /> : undefined }
                            </div>
                            <div>
                                <FormControlLabel
                                    control={<Checkbox checked={pathFilter} onChange={handlePathFilter}
                                                    inputProps={{'aria-label': 'Filter by path'}}/>}
                                    label="By Path"
                                />
                                { pathFilter ? <TextField
                                    value={pathFilterValue}
                                    onChange={e => {
                                        setPathFilterValue(e.target.value);
                                        refreshAddEntity();
                                    }}
                                    type='text'
                                    autoFocus
                                    fullWidth={true}
                                /> : undefined }
                            </div>
                        </FormControl> : undefined}
                    </TabPanel>
                    <TabPanel value={ManageEntityTabValue.CREATE_EXCLUSIVE}>
                        <Grid container>
                            <Grid item xs={10}>
                                <TextField
                                    autoFocus={tab === ManageEntityTabValue.CREATE_EXCLUSIVE}
                                    inputRef={setNameInput}
                                    margin="dense"
                                    id="entityName"
                                    label="Entity Name"
                                    fullWidth
                                    color='primary'
                                    variant='filled'
                                    value={exclusiveEntity.name}
                                    onChange={e => setExclusiveEntity(new Entity(-1, e.target.value, '', CreateEntityType.EXCLUSIVE, -1))}
                                    error={!!error}
                                    helperText={error}
                                />
                            </Grid>
                            <Grid item xs={2} style={{placeSelf: 'center'}}>
                                <Tooltip
                                    title={'Add Entity'}
                                >
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        style={{marginLeft: '10px'}}
                                        onClick={() => { addExclusiveEntityToList(exclusiveEntity)}}
                                        disabled={doNotAddExclusiveEntity || (!!error && error.length > 0)}
                                    >
                                        <Add/>
                                    </Button>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </TabPanel>
                </TabContext>
                { entityList.length > 0 ? <TableContainer style={{width: '100%'}}>
                    {selectedEntities.length > 0 && <Toolbar style={{marginTop: 10}} className='table-toolbar' variant='dense'>
                        <Typography color="inherit" variant="subtitle1" component="div">
                            {selectedEntities.length} Selected
                        </Typography>
                        <Tooltip title="Delete">
                            <IconButton onClick={handleDeleteButton} color="inherit">
                                <NegroniIcon iconClass="delete-icon" color="#fff" />
                            </IconButton>
                        </Tooltip>
                    </Toolbar>}
                    <Table size='small'>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{width: '5%'}}/>
                                <TableCell style={{width: '25%'}}>Name</TableCell>
                                <TableCell style={{width: '70%'}}>Path</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {entityList.filter(entity => entity.name.toLowerCase())
                                .slice(tablePage * rowsPerPage, (tablePage * rowsPerPage) + rowsPerPage)
                                .map((entity, index) => (
                                    <TableRow
                                        onClick={_ => {
                                            handleClick(entity);
                                        }}
                                        hover
                                        key={index}
                                        style={{cursor: 'pointer'}}
                                        onContextMenu={e => handleContextMenu(e, entity)}
                                        selected={!!selectedEntities.find(ta => ta.name === entity.name)}
                                    >
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                checked={isSelected(entity)}
                                                inputProps={{'aria-label': 'Select type attribute'}}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Grid container direction="row" alignItems="center">
                                                {entity.name}
                                            </Grid>
                                        </TableCell>
                                        <TableCell>
                                            {entity.path}
                                        </TableCell>
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer> : <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '100%',
                    padding: '10px'
                }}>No Entities to display</div> }
            </div> : <></> }
        </DialogContent>
        <DialogActions id="negroni-manifest-wizard-actions">
            <Button onClick={onClose} style={{marginRight: 'auto'}}>
                Cancel
            </Button>
            <Button
                variant='contained'
                color='secondary'
                disabled={page === 1}
                onClick={backPage}
            >
                Back
            </Button>
            <Button
                variant='contained'
                color='secondary'
                disabled={loading || (!!error && error.length > 0) || (page === 2 && duplicateManifest === undefined)}
                disableElevation
                onClick={handleNextOrFinish}
                startIcon={loading && <CircularProgress size={18}/>}
            >
                {page === 3 ? "Finish" : "Next" }
            </Button>
        </DialogActions>
    </Dialog> :
    <Modal
        id="negroni-manifest-wizard-dialog"
        isOpen={open}
        onRequestClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <Box className={classes.boxModal}>
            <DialogTitle id="form-dialog-title">Create Manifest</DialogTitle>
            <DialogContent id="negroni-manifest-wizard-content" dividers style={{minHeight: '350px', minWidth: '550px'}}>
                {page === 1 ? <div>
                    <TextField
                        autoFocus={true}
                        inputRef={setNameInput}
                        margin="dense"
                        id="manifestName"
                        label="Manifest Name"
                        fullWidth
                        color='primary'
                        variant='filled'
                        value={manifest?.name}
                        onChange={e => setManifest(new Manifest(-1, e.target.value, ''))}
                        error={!!error}
                        helperText={error}
                    />
                    { manifestSize !== 0 ? <>
                        <Grid container>
                            <FormControlLabel
                                control={<Radio checked={!existingManifest} onChange={handleManifestType}
                                                inputProps={{'aria-label': 'Select Empty Manifest'}}/>}
                                label="Empty Manifest"
                            />
                        </Grid>
                        <Grid container>
                            <FormControlLabel
                                control={<Radio checked={existingManifest} onChange={handleManifestType}
                                                inputProps={{'aria-label': 'Select Existing Manifest'}}/>}
                                label="Duplicate Existing Manifest"
                            />
                        </Grid>
                    </> : <></> }
                    </div> : <></> }
                {page === 2 ? <div>
                    <p className='dialog-label'>Select a manifest to duplicate</p>
                    <FormControl variant='filled' fullWidth={true}>
                        <PaginatedSelect
                            getOptions={handleLoadManifestOptions}
                            valueMapper={value => value ? ({label: value.name, value: value.value}) : null}
                            placeholder='Select Manifest'
                            defaultValue={duplicateManifest}
                            onValueChanged={setDuplicateManifest}
                            formatOptionLabel={option => {
                                return <div style={{display: 'flex', flexDirection: 'column'}}>
                                    {option.label}
                                </div>;
                            }}
                            autoFocus={true}
                        />
                    </FormControl>
                </div> : <></> }
                {page === 3 ? <div>
                    <p className='dialog-label'>Manage Entities</p>
                    <TabContext value={tab}>
                        <Paper elevation={0} square>
                            <TabList
                                value={tab}
                                onChange={(e, value) => {
                                    setTab(value);
                                }}
                                variant='fullWidth'
                            >
                                <Tab value={ManageEntityTabValue.ADD_ENTITY} label='Choose Existing Entity'/>
                                <Tab value={ManageEntityTabValue.CREATE_EXCLUSIVE} label='Create Local Entity'/>
                            </TabList>
                        </Paper>
                        <TabPanel value={ManageEntityTabValue.ADD_ENTITY}>
                            <Grid container style={{marginTop: 10, marginBottom: 10}}>
                                <Grid item xs={5}>
                                    <FormControlLabel
                                        control={<Checkbox checked={filter === Filter.RESOLVED} onChange={handleFilter}
                                                        inputProps={{'aria-label': 'Select custom entities'}}/>}
                                        label="Show Core Entities"
                                    />
                                </Grid>
                                <Grid item xs={7} style={{ display: "flex", justifyContent: "flex-end" }}>
                                    <Tooltip
                                        title={'Add Entity'}
                                    >
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            style={{marginLeft: '10px'}}
                                            disabled={doNotAddEntity || (addEntity === null)}
                                            onClick={addEntityToList}
                                        >
                                            <Add/>
                                        </Button>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                            <FormControl variant='filled' fullWidth={true} style={{display: 'flex', flexDirection: 'row'}}>
                                <div style={{width: '100%'}}>
                                    <PaginatedSelect
                                        autoFocus={tab === ManageEntityTabValue.ADD_ENTITY}
                                        getOptions={getEntities}
                                        valueMapper={value => value ? ({label: value.name, value: value.value}) : null}
                                        onValueChanged={setAddEntity}
                                        update={updateAddEntity}
                                        formatOptionLabel={option => {
                                            return <div style={{display: 'flex', flexDirection: 'column'}}>
                                                {option.label}
                                                {(filter === Filter.RESOLVED && option.value?.path) && <small
                                                    style={{color: 'var(--label-1-color)'}}>{getCoreRelativePath(option?.value?.path)}</small>}
                                            </div>;
                                        }}
                                        hideFilter={hideFilter}
                                    />
                                </div>
                                {filter === Filter.RESOLVED ? <Tooltip
                                    title={advanceFilter ? 'Hide Advance Filters' : 'Advance Filters'}
                                >
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        style={{marginLeft: '10px'}}
                                        onClick={handleAdvanceFilter}
                                    >
                                        {advanceFilter ? <Clear/> : <FilterList/>}
                                    </Button>
                                </Tooltip> : undefined}
                            </FormControl>
                            <FormHelperText error={!!error && error.length > 0}>{error}</FormHelperText>
                            { advanceFilter && filter === Filter.RESOLVED  ? <FormControl variant='filled' fullWidth={true} style={{display: 'flex', flexDirection: 'column'}}>
                                <div>
                                    <FormControlLabel
                                        control={<Checkbox checked={folderFilter} onChange={handleFolderFilter}
                                                        inputProps={{'aria-label': 'Filter by folder'}}/>}
                                        label="By Folder"
                                    />
                                    { folderFilter ? <TextField
                                        value={folderFilterValue}
                                        onChange={e => {
                                            setFolderFilterValue(e.target.value);
                                            refreshAddEntity();
                                        }}
                                        type='text'
                                        autoFocus
                                        fullWidth={true}
                                    /> : undefined }
                                </div>
                                <div>
                                    <FormControlLabel
                                        control={<Checkbox checked={pathFilter} onChange={handlePathFilter}
                                                        inputProps={{'aria-label': 'Filter by path'}}/>}
                                        label="By Path"
                                    />
                                    { pathFilter ? <TextField
                                        value={pathFilterValue}
                                        onChange={e => {
                                            setPathFilterValue(e.target.value);
                                            refreshAddEntity();
                                        }}
                                        type='text'
                                        autoFocus
                                        fullWidth={true}
                                    /> : undefined }
                                </div>
                            </FormControl> : undefined}
                        </TabPanel>
                        <TabPanel value={ManageEntityTabValue.CREATE_EXCLUSIVE}>
                            <Grid container id="negroni-create-exclusive-entity-content">
                                <Grid item xs={10}>
                                    <TextField
                                        autoFocus={tab === ManageEntityTabValue.CREATE_EXCLUSIVE}
                                        inputRef={setNameInput}
                                        margin="dense"
                                        id="entityName"
                                        label="Entity Name"
                                        fullWidth
                                        color='primary'
                                        variant='filled'
                                        value={exclusiveEntity.name}
                                        onChange={e => setExclusiveEntity(new Entity(-1, e.target.value, '', CreateEntityType.EXCLUSIVE, -1))}
                                        error={!!error}
                                        helperText={error}
                                    />
                                </Grid>
                                <Grid item xs={2} style={{placeSelf: 'center'}}>
                                    <Tooltip
                                        title={'Add Entity'}
                                    >
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            style={{marginLeft: '10px'}}
                                            onClick={() => { addExclusiveEntityToList(exclusiveEntity)}}
                                            disabled={doNotAddExclusiveEntity || (!!error && error.length > 0)}
                                        >
                                            <Add/>
                                        </Button>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        </TabPanel>
                    </TabContext>
                    { entityList.length > 0 ? <TableContainer style={{width: '100%'}}>
                        {selectedEntities.length > 0 && <Toolbar style={{marginTop: 10}} className='table-toolbar' variant='dense'>
                            <Typography color="inherit" variant="subtitle1" component="div">
                                {selectedEntities.length} Selected
                            </Typography>
                            <Tooltip title="Delete">
                                <IconButton onClick={handleDeleteButton} color="inherit">
                                    <NegroniIcon iconClass="delete-icon" color="#fff" />
                                </IconButton>
                            </Tooltip>
                        </Toolbar>}
                        <Table size='small'>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{width: '5%'}}/>
                                    <TableCell style={{width: '25%'}}>Name</TableCell>
                                    <TableCell style={{width: '70%'}}>Path</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {entityList.filter(entity => entity.name.toLowerCase())
                                    .slice(tablePage * rowsPerPage, (tablePage * rowsPerPage) + rowsPerPage)
                                    .map((entity, index) => (
                                        <TableRow
                                            onClick={_ => {
                                                handleClick(entity);
                                            }}
                                            hover
                                            key={index}
                                            style={{cursor: 'pointer'}}
                                            onContextMenu={e => handleContextMenu(e, entity)}
                                            selected={!!selectedEntities.find(ta => ta.name === entity.name)}
                                        >
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    checked={isSelected(entity)}
                                                    inputProps={{'aria-label': 'Select type attribute'}}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <Grid container direction="row" alignItems="center">
                                                    {entity.name}
                                                </Grid>
                                            </TableCell>
                                            <TableCell>
                                                {entity.path}
                                            </TableCell>
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer> : <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        height: '100%',
                        padding: '10px'
                    }}>No Entities to display</div> }
                </div> : <></> }
            </DialogContent>
            <DialogActions id="negroni-manifest-wizard-actions">
                <Button onClick={onClose} style={{marginRight: 'auto'}}>
                    Cancel
                </Button>
                <Button
                    variant='contained'
                    color='secondary'
                    disabled={page === 1}
                    onClick={backPage}
                >
                    Back
                </Button>
                <Button
                    variant='contained'
                    color='secondary'
                    disabled={loading || !!finishError || (!!error && error.length > 0) || (page === 2 && duplicateManifest === undefined)}
                    disableElevation
                    onClick={handleNextOrFinish}
                    startIcon={loading && <CircularProgress size={18}/>}
                >
                    {page === 3 ? "Finish" : "Next" }
                </Button>
            </DialogActions>
        </Box>
    </Modal>
}

export default observer(ManifestWizard);