import React, { FC } from "react";
import { Grid, Typography, Icon, makeStyles, Theme, createStyles, Button } from "@material-ui/core";
import useQuery from "../router/useQuery";
import { useHistory } from "react-router-dom";

const errorStyles = makeStyles(() =>
    createStyles({
        errorIcon: {
            fontSize: 100,
        },
    })
);

const Error: FC = () => {
    const classes = errorStyles();
    const history = useHistory();
    const query = useQuery();
    const params = new URLSearchParams(window.location.search)
    const errorCode = query.get('code') || params.get('code');
    const error = query.get('error') || params.get('error');
    const errorMessage = query.get('message') || params.get('message');

    const handleRedirectHomepage = () => {
        window.history.pushState( '', '', '/' );
        history.push('/');
    }

    return <Grid 
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
        style={{ minHeight: '100vh' }}
    >
        <Grid
            style={{ marginBottom: '20px' }}
        >
            <Icon color="error" className={classes.errorIcon}>error</Icon>
        </Grid>
        <Grid>
        <Typography variant="h4">
            {errorCode + " - " + error?.toUpperCase()}
        </Typography>
        </Grid>
        <Grid>
        <Typography variant="h6">
            {errorMessage}
        </Typography>
        </Grid>
        <Grid
            style={{ marginTop: '20px' }}
        >
            <Button
                onClick={handleRedirectHomepage}
                variant='contained'
                color='secondary'
            >
                Go To Homepage
            </Button>
        </Grid>
    </Grid>
}

export default Error;