import React, {useEffect, useRef, useState} from "react";
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    TextField,
    Typography
} from "@material-ui/core";
import Manifest, {
    BuildManifest,
    ConnectionPoolType,
    ConnectionPoolUrl,
    MartiniProperties,
    ApiTypes,
    ApiSecurity,
} from "../../../core/observables/Manifest";
import useService from "../../service/useService";
import {LoadingProgress} from "../../LoadingProgress";
import {Alert} from "@material-ui/lab";
import BuildManifestFormContainer from "./BuildManifestFormContainer";
import ManifestTree, {ManifestNode} from "../tree/ManifestTree";
import semver from "semver";
import {PackageFilter} from "../../../core/services/PackageService";
import useSession from "../../session/useSession";

export interface BuildManifestDialogProp {
    manifest: Manifest;
    open: boolean;
    onClose: () => void;
    onFinish: (manifestId: number, buildManifest: BuildManifest) => Promise<void>;
    hideConfiguration: boolean;
    customBuild: boolean;
}

const BuildManifestDialog: React.FC<BuildManifestDialogProp> = ({
    manifest,
    open,
    onClose,
    onFinish,
    hideConfiguration,
    customBuild,
}) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const {manifestService, packageService} = useService();
    const [preErrorMessage, setPreErrorMessage] = useState<string>('');
    const [isPreLoading, setIsPreLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [selection, setSelection] = useState<ManifestNode | undefined>();
    const [versions, setVersions] = useState<string[]>([]);
    const [version, setVersion] = useState<string>('1.0.0');
    const [versionError, setVersionError] = useState<string>('');
    const [manifestNode, setManifestNode] = useState<ManifestNode>({
        id: manifest.id,
        name: manifest.name,
        subManifests: manifest?.subManifestPaths?.map(subManifest => ({
            id: subManifest.id,
            name: subManifest.name,
            subManifests: [],
        })) || [],
    });
    const {limits} = useSession();
    const [currentBuild, setCurrentBuild] = useState<MartiniProperties>({
        manifestName: '',
        packageProperties: {
            connectionPoolInfo: {
                type: ConnectionPoolType.PostgreSQL,
                databaseName: '',
                password: '',
                username: '',
                url: '',
            },
            customProperties: [],
        },
        configurationBuild: {
            apiConfiguration: {
                type: [ApiTypes.REST],
                security: ApiSecurity.NONE,
                excludeOperations: [],
            },
            sqlConfiguration: {
                applyAutoIncrement: true,
                applyCoalesce: false,
                applyLimitOffset: false,
                applyJoin: false,
            },
            serviceConfiguration: {
                applyMultiTenancy: false,
                applyCustomField: false,
                applySot: false,
                sotEntities: [],
            },
            aggregateQueries: [],
        },
    });

    const makeManifestBuild = (manifestNode: ManifestNode): MartiniProperties => {
        return {
            manifestName: manifestNode.name,
            packageProperties: {
                customProperties: [{
                    name: '',
                    value: '',
                }],
                connectionPoolInfo: {
                    url: limits().allowedDb.includes('SQL') ? ConnectionPoolUrl[ConnectionPoolType.PostgreSQL] : ConnectionPoolUrl[ConnectionPoolType.THIN],
                    username: '',
                    password: '',
                    databaseName: manifestNode.name,
                    type: limits().allowedDb.includes('SQL') ? ConnectionPoolType.PostgreSQL : ConnectionPoolType.THIN,
                }
            },
            configurationBuild: {
                apiConfiguration: {
                    type: [ApiTypes.REST],
                    security: ApiSecurity.NONE,
                    excludeOperations: [],
                },
                sqlConfiguration: {
                    applyAutoIncrement: true,
                    applyCoalesce: false,
                    applyLimitOffset: false,
                    applyJoin: false,
                },
                serviceConfiguration: {
                    applyMultiTenancy: false,
                    applyCustomField: false,
                    applySot: false,
                    sotEntities: [],
                },
                aggregateQueries: [],
            },
        };
    };

    const builds = useRef<Map<string, MartiniProperties>>(new Map<string, MartiniProperties>());

    useEffect(() => {
        if (open) {
            builds.current.clear();
            setErrorMessage('');
            setIsPreLoading(true);
            setVersions([]);

            packageService.getPackageByManifest(PackageFilter.PRIVATE, manifest.id)
                .then(packages => {
                    if ( packages && packages.length > 0) {
                        //get the first element from the list (expects to contains only ONE element)
                        const thePackage = packages[0];
                        const currentVersion = thePackage.versions[0];
                        const incrementedVersion = semver.inc(currentVersion, 'patch');
                        if (incrementedVersion) setVersion(incrementedVersion);
                    } else {
                        //there are no package available, which means this manifest isn't built yet
                        //set the version with default value = 1.0.0
                        setVersion('1.0.0')
                    }
                });

            manifestService.validateManifest(manifest.id)
                .then(_ => {
                    setPreErrorMessage('');
                    const manifestNode = {
                        id: manifest.id,
                        name: manifest.name,
                        subManifests: manifest?.subManifestPaths?.map(subManifest => ({
                            id: subManifest.id,
                            name: subManifest.name,
                        })),
                    };
                    setManifestNode(manifestNode);

                    builds.current.set(manifest.name, makeManifestBuild(manifestNode));
                    setSelection(manifestNode);
                })
                .catch(setPreErrorMessage)
                .finally(() => setIsPreLoading(false));
        }
    }, [open]);

    useEffect(() => {
        if (selection) {
            let manifestBuild = builds.current.get(selection.name);
            if (!manifestBuild)
                builds.current.set(selection.name, manifestBuild = makeManifestBuild(selection));
            if (manifestBuild)
                setCurrentBuild(manifestBuild);
        }
    }, [selection]);

    useEffect(() => {
        let error = '';
        if (!semver.valid(version)) {
            error = 'Invalid version pattern.';
        } else if (versions.includes(version)) {
            error = 'this version is already built'
        }
        setVersionError(error);
    }, [version, versions]);

    const handleBuild = () => {
        setIsLoading(true);
        const buildManifest: BuildManifest = {
            manifestProperties: Array.from(builds.current.values()),
            version,
            customBuild,
        };
        buildManifest.manifestProperties.forEach(properties => {
            if(properties.packageProperties.connectionPoolInfo.type.includes('oracle')) {
                properties.packageProperties.connectionPoolInfo.type = ConnectionPoolType.ORACLE;
            }
        });
        onFinish(manifest.id, buildManifest).then(() => onClose())
            .catch((error: Error) => setErrorMessage(error.message))
            .finally(() => setIsLoading(false));
    }

    return <>
        <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title" maxWidth='sm' fullWidth>
            <DialogTitle id="form-dialog-title">Build Manifest</DialogTitle>
            {isPreLoading ?
                <DialogContent dividers>
                    <h3 style={{textAlign: 'center'}}>Validating...</h3>
                    <LoadingProgress/>
                </DialogContent> :
                <>
                    <DialogContent dividers>
                        {!!preErrorMessage && preErrorMessage.length > 0 ?
                            <Alert severity='error'> {preErrorMessage} </Alert> :
                            <>
                                <Grid container>
                                    <Grid item xs={4}>
                                        <Typography variant='subtitle1'>
                                            Manifests
                                        </Typography>
                                        <div style={{display: 'flex', overflow: 'auto'}}>
                                            <ManifestTree
                                                manifestNode={manifestNode}
                                                onSelectedNode={node => {
                                                    const [id, name] = node.split('~');
                                                    setSelection({
                                                        id: Number(id),
                                                        name,
                                                    });
                                                }}
                                            />
                                        </div>
                                    </Grid>
                                    <Grid item xs={8} style={{maxHeight: '450px', overflowY: 'auto', overflowX: 'hidden'}}>

                                        {!!errorMessage && errorMessage.length > 0 &&
                                        <Alert severity='error'>
                                            {errorMessage}
                                        </Alert>}
                                        <DialogContentText>
                                            Enter the build version of package.
                                        </DialogContentText>
                                        <TextField
                                            label='Martini Package Version'
                                            value={version}
                                            onChange={e => setVersion(e.target.value)}
                                            style={{margin: '5px', width: '98%'}}
                                            helperText={!!versionError && versionError}
                                            error={versionError.length > 0}
                                        />
                                        {!hideConfiguration && <>
                                            <DialogContentText>
                                                Enter the connection pool details.
                                            </DialogContentText>
                                            <BuildManifestFormContainer
                                                manifest={manifest}
                                                onValidate={_ => undefined}
                                                onChange={manifestBuild => {
                                                    builds.current.set(manifestBuild.manifestName, manifestBuild);
                                                }}
                                                initialValues={currentBuild}
                                            />
                                        </>}
                                    </Grid>
                                </Grid>
                            </>}
                    </DialogContent>
                    <DialogActions>
                        {(!!preErrorMessage && preErrorMessage.length > 0) ?
                            <Button onClick={onClose}>
                                Close
                            </Button> :
                            <>
                                <Button onClick={onClose}>
                                    Cancel
                                </Button>
                                <Button
                                    disableElevation
                                    variant='contained'
                                    startIcon={isLoading && <CircularProgress size={18}/>}
                                    disabled={versionError.length > 0 || isLoading}
                                    onClick={handleBuild}
                                    color='secondary'
                                >
                                    Build
                                </Button>
                            </>}
                    </DialogActions>
                </>}
        </Dialog>
    </>;
}

export default BuildManifestDialog;