import {createContext} from "react";
import User, {Org, Subscription, LicenseLimits} from "../auth/User";

export interface SessionContextValue {
    isLoggedIn: () => boolean;
    hasRefreshToken: () => boolean;
    getRefreshToken: () => string;
    hasSessionToken: () => boolean;
    getSessionToken: () => string;
    user: () => User | undefined;
    getUser: () => User | undefined;
    setUser: (user: User | undefined) => void;
    setOrg: (orgCode: string, orgName: string) => void;
    org: () => Org;
    subscription: () => Subscription;
    limits: () => LicenseLimits;
}

export default createContext<SessionContextValue>({} as SessionContextValue);