import React, { useState, useEffect, useCallback, ChangeEvent } from "react";
import { Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    Grid,
    FormControlLabel,
    FormControl,
    CircularProgress,
    FormHelperText,
    Radio,
} from "@material-ui/core";
import Manifest from "../../core/observables/Manifest";
import { observer } from "mobx-react";
import { CancelTokenSource } from "axios";
import PaginatedResponse from "../../core/services/PaginatedResponse";
import { SelectOption, SelectGroupOption, PaginatedSelect } from "../select/PaginatedSelect";
import { Filter } from "../../core/Filter";
import useService from "../service/useService";
import { NegroniIcon } from "../icon/NegronIIcon";

export interface ImportWizardProp {
    open: boolean;
    onClose: () => void;
    importEntity: (entityFile: FormData, manifest?: Manifest) => Promise<string | undefined>;
    importManifest: (manifestFile: FormData) => Promise<string | undefined>;
    skipPage?: number;
    defaultManifest?: Manifest;
}

export const ImportWizard: React.FC<ImportWizardProp> = ({
    open,
    onClose,
    importEntity,
    importManifest,
    skipPage,
    defaultManifest,
}) => {
    const {manifestService} = useService();
    const [manifest, setManifest] = useState<Manifest>();
    const [page, setPage] = useState<Number>(1);
    const [fileName, setFileName] = useState('');
    const [selectedFile, setSelectedFile] = useState();
    const [isEntityImport, setIsEntityImport] = useState(true);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>();

    useEffect(() => {
        if(open) {
            setManifest(undefined)
            setFileName('')
            setSelectedFile(undefined)
            setIsEntityImport(true)
            setLoading(false)
            setError(null)
            if(skipPage) {
                setPage(skipPage)
            } else {
                setPage(1)
            }
            if(defaultManifest) {
                setManifest(defaultManifest)
            }
        }
    }, [open])

    const nextPage = () => {
        if(page === 1) {
            if(isEntityImport) {
                setPage(2)
            } else {
                setPage(3)
            }
        }
        if(page === 2) {
            setPage(3)
        }
    }

    const backPage = () => {
        if(page === 2) {
            setPage(1)
        }
        if(page === 3) {
            if(isEntityImport) {
                setPage(2)
            } else {
                setPage(1)
            }
        }
    }

    const handleFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files) {
          return;
        }
        setSelectedFile(e.target.files[0])
        const file = e.target.files[0];
        const { name } = file;
        setFileName(name);
    };

    const handleImportType = () => {
        setIsEntityImport(!isEntityImport);
    }

    const handleLoadManifestOptions = useCallback((page: number, limit: number, search?: string, cancelTokenSource?: CancelTokenSource): Promise<PaginatedResponse<SelectOption[] | SelectGroupOption[]>> => {
        return manifestService.getManifests(Filter.PRIVATE, undefined, limit, page, search, cancelTokenSource?.token)
            .then(response => ({
                ...response,
                result: response.result.map(manifest => ({
                    label: manifest.name,
                    value: manifest,
                }))
            }))
    }, []);

    const handleNextOrFinish = () => {
        if( page === 3 ) {
            setLoading(true);
            const formData = new FormData();
            if(isEntityImport) {
                formData.append('entityFile', selectedFile)
                importEntity(formData, manifest)
                    .then((error: string | undefined) => {
                        setLoading(false);
                        if (error && error.length > 0) {
                            setError(error);
                        } else {
                            onClose();
                        }
                    });
            } else {
                formData.append('manifestFile', selectedFile)
                importManifest(formData)
                    .then((error: string | undefined) => {
                        setLoading(false);
                        if (error && error.length > 0) {
                            setError(error);
                        } else {
                            onClose();
                        }
                    });
            }
        } else {
            nextPage();
        }
    }

    return <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="form-dialog-title">Import</DialogTitle>
        <DialogContent dividers style={{minHeight: '350px', minWidth: '550px'}}>
            {page === 1 ? <div>
                <p className='dialog-label'>Select type of document to import.</p>
                <Grid container>
                    <FormControlLabel
                        control={<Radio checked={isEntityImport} onChange={handleImportType}
                                        inputProps={{'aria-label': 'Entity'}}/>}
                        label="Entity"
                    />
                </Grid>
                <Grid container>
                    <FormControlLabel
                        control={<Radio checked={!isEntityImport} onChange={handleImportType}
                                        inputProps={{'aria-label': 'Manifest'}}/>}
                        label="Manifest"
                    />
                </Grid>
            </div> : <></>}
            {page === 2 ? <div>
                <p className='dialog-label'>Select a manifest to where the entity will be imported</p>
                <FormControl variant='filled' fullWidth={true}>
                    <PaginatedSelect
                        getOptions={handleLoadManifestOptions}
                        valueMapper={value => value ? ({label: value.name, value: value.value}) : null}
                        placeholder='Select Manifest'
                        defaultValue={manifest}
                        onValueChanged={setManifest}
                        formatOptionLabel={option => {
                            return <div style={{display: 'flex', flexDirection: 'column'}}>
                                {option.label}
                            </div>;
                        }}
                        autoFocus={true}
                    />
                </FormControl>
            </div> : <></>}
            {page === 3 ? <div>
                <FormHelperText error={!!error && error.length > 0}>{error}</FormHelperText>
                <Grid container>
                    {isEntityImport ?
                        <p className='dialog-label'>Upload entity file to import.</p> :
                        <p className='dialog-label'>Upload manifest file to import.</p>}
                    <Grid item xs={10}>
                        <TextField
                            margin="dense"
                            label="File Name"
                            fullWidth
                            variant='filled'
                            value={fileName}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={2} style={{placeSelf: 'center'}}>
                        {isEntityImport ? <>
                            <input
                                accept=".json"
                                id="upload-file"
                                style={{display: 'none'}}
                                multiple
                                type="file"
                                onChange={handleFileUpload}
                            />
                            <label htmlFor="upload-file">
                                <Button
                                    component="span"
                                    variant="contained"
                                    color="secondary"
                                    style={{marginLeft: '10px'}}
                                >
                                    <NegroniIcon iconClass="file-upload-icon" />
                                </Button>
                            </label></> : <>
                            <input
                                accept=".zip"
                                id="upload-file"
                                style={{display: 'none'}}
                                multiple
                                type="file"
                                onChange={handleFileUpload}
                            />
                            <label htmlFor="upload-file">
                                <Button
                                    component="span"
                                    variant="contained"
                                    color="secondary"
                                    style={{marginLeft: '10px'}}
                                >
                                    <NegroniIcon iconClass="file-upload-icon" />
                                </Button>
                            </label></>}
                    </Grid>
                </Grid>
            </div> : <></> }
        </DialogContent>
        <DialogActions>
            <Button onClick={onClose} style={{marginRight: 'auto'}}>
                Cancel
            </Button>
            <Button
                variant='contained'
                color='secondary'
                disabled={page === 1}
                onClick={backPage}
            >
                Back
            </Button>
            <Button
                variant='contained'
                color='secondary'
                disabled={loading || (!!error && error.length > 0) }
                disableElevation
                onClick={handleNextOrFinish}
                startIcon={loading && <CircularProgress size={18}/>}
            >
                {page === 3 ? "Finish" : "Next" }
            </Button>
        </DialogActions>
    </Dialog>
}

export default observer(ImportWizard);