import { sortBy } from "lodash";
import { action, observable } from "mobx";
import React from "react";
import { CellEditorProps, TextCellEditor } from "../../component/properties/CellEditor";
import { PropertyDescriptor, PropertySource } from "../../component/properties/PropertyDescriptor";
import { AsyncSelectCellEditor } from "../../component/properties/SelectCellEditor";
import { ManifestService, mapToManifest } from "../services/ManifestService";
import CDMDocument, { CDMDocumentType } from "./CDMDocument";
import DocumentItem from "./DocumentItem";
import Entity from "./Entity";

export default class Manifest implements CDMDocument {
    static MIN_NAME_LENGTH = 2;
    static MAX_NAME_LENGTH = 50;
    @observable id: number;
    @observable name: string;
    @observable path: string;
    @observable entityPaths: DocumentItem[] = [];
    @observable subManifestPaths: DocumentItem[] = [];
    @observable entities: Entity[] = [];
    @observable subManifests: Manifest[] = [];
    @observable isDefault: boolean = true;
    @observable relationships: EntityRelationship[] = [];
    @observable parentManifestId?: number;
    @observable latestVersion: string = 'None';
    @observable currentVersion: string = 'None';
    private _parent: any;
    readonly documentType = CDMDocumentType.MANIFEST;

    constructor(id: number, name: string, path: string, entityPaths?: DocumentItem[], subManifestPaths?: DocumentItem[]) {
        this.id = id;
        this.name = name;
        this.path = path;
        if (entityPaths) {
            for (let entity of entityPaths)
                this.addEntityPath(entity);
        }
        if (subManifestPaths) {
            for (let subManifest of subManifestPaths)
                this.addSubManifestPath(subManifest);
        }
    }

    @action
    addEntityPath(entity: DocumentItem) {
        this.entityPaths.push(entity);
    }

    @action
    addSubManifestPath(subManifest: DocumentItem) {
        this.subManifestPaths.push(subManifest);
    }

    @action
    addRelationship(relationship: EntityRelationship) {
        this.relationships.push(relationship);
    }

    set parent(parent: any) {
        this._parent = parent;
    }

    get parent() {
        return this._parent;
    }

    set setEntityPaths(entityPaths: DocumentItem[]) {
        this.entityPaths = entityPaths;
    }
}

export class ManifestPropertySource implements PropertySource {

    private readonly manifest: Manifest;
    private readonly descriptors: PropertyDescriptor[] = [];
    private manifestService: ManifestService;
    documentType = CDMDocumentType.MANIFEST;

    constructor(manifest: Manifest, manifestService: ManifestService) {
        this.manifest = manifest;
        this.manifestService = manifestService;

        async function handleManifestVersions() {
            let versions: { label: string, value: string }[] = []
            try {
                await manifestService.getManifestVersionsById(manifest.id)
                    .then(manifest => {
                        manifest.manifestVersions.forEach((version:any) => {
                            versions.push({
                                label: version.VERSION,
                                value: version.VERSION
                            })
                        })
                        return sortBy(versions, "label");
                    });
                return versions
            } catch (_) {
                return [];
            }
        }

        this.descriptors.push({
            readOnly: true,
            defaultValue: this.manifest.name,
            displayName: 'Name',
            description: 'Name of the manifest',
            hasDefaultValue: true,
            name: 'name',
            value: this.manifest.name,
            target: this.manifest,
            getCellEditor: (props: CellEditorProps<any>) => <TextCellEditor {...props} label='Name'
                                                                            description='Name of the manifest'/>,
            validate: _ => undefined,
        }, 
        {
            readOnly: false,
            defaultValue: this.manifest.latestVersion,
            displayName: 'Version',
            description: 'The current version of the manifest',
            hasDefaultValue: true,
            name: 'latestVersion',
            value: this.manifest.latestVersion,
            displayValue: this.manifest.latestVersion || 'None',
            target: this.manifest,
            getCellEditor: (props: CellEditorProps<any>) => <AsyncSelectCellEditor
            {...props}
            loadOptions={handleManifestVersions}
        />,
        validate: _ => undefined
        });
    }

    get iconClass(): string {
        return '';
    }

    get title(): string {
        return 'Manifest';
    }

    dispose(): void {
    }

    getPropertyDescriptors(): PropertyDescriptor[] {
        return this.descriptors;
    }

    async setProperty(name: string, newValue: any): Promise<void> {
        switch (name) {
            case 'name':
                const oldName = this.manifest.name;
                this.manifest.name = newValue as string;
                this.manifest.path.replace(oldName, newValue);
                break;
            case 'latestVersion':
                const version = (await this.manifestService.getManifestVersion(this.manifest.id, newValue));
                const manifest = JSON.parse((version).manifest.CONTENT);
               
                this.manifest.entities = manifest.entities.map((entity: any, index: string) => ({
                    id: entity,
                    name: "manifest" + index
                }));

                this.manifest.subManifests = manifest.subManifests.map((manifest: any, index: string) => ({
                    id: manifest,
                    name: "manifest" + index
                }));

                Object.assign(this.manifest, mapToManifest(this.manifest.id, this.manifest, newValue));
                break;
        }
    }

    getModel(): CDMDocument {
        return this.manifest;
    }
}

export class EntityRelationship {
    fromEntity: string;
    fromAttribute: string;
    toEntity: string;
    toAttribute: string;

    constructor(fromEntity: string, fromAttribute: string, toEntity: string, toAttribute: string) {
        this.fromEntity = fromEntity;
        this.fromAttribute = fromAttribute;
        this.toEntity = toEntity;
        this.toAttribute = toAttribute;
    }
}

export const makeAdminCreateManifest = (org: string, manifest: Manifest): AdminCreateManifest => {
    return {
        org: org,
        manifest: {
            manifestName: manifest.name,
            importsPath: [],
            entities: manifest.entityPaths.map(e => e.id),
            subManifests: manifest.subManifestPaths.map(sm => sm.id),
        }
    };
}

export interface AdminCreateManifest {
    org: string;
    manifest: {
        manifestName?: string;
        importsPath: string[];
        entities: number[];
        subManifests: number[];
    }
}

export const makeCreateManifest = (manifest: Manifest): CreateManifest => {
    return {
        manifestName: manifest.name,
        importsPath: [],
        entities: manifest.entityPaths.map(e => e.id),
        subManifests: manifest.subManifestPaths.map(sm => sm.id),
    };
}

export const makeCreateManifestWizard = (manifest: Manifest, duplicateManifestId: number, entities: DocumentItem[], manifestProperties: MartiniProperties[], version: string, generateType: string): CreateManifestWizard => {
    let entitiesId: number[] = []
    let exclusiveEntities: string[] = []
    entities.forEach(entity => {
        if(entity.path === 'Excluse entity to be created') {
            exclusiveEntities.push(entity.name)
        } else {
            entitiesId.push(entity.id)
        }
    })
    return {
        manifestName: manifest.name,
        duplicateManifest: duplicateManifestId,
        importsPath: [],
        entities: entitiesId,
        exclusiveEntities: exclusiveEntities,
        subManifests: [],
        manifestProperties: manifestProperties,
        version: version,
        generateType: generateType
    }
}

export interface CreateManifest {
    manifestName?: string;
    importsPath: string[];
    entities: number[];
    subManifests: number[];
}

export interface CreateManifestWizard {
    manifestName: string;
    duplicateManifest: number;
    importsPath: string[];
    entities: number[];
    exclusiveEntities: string[];
    subManifests: number[];
    manifestProperties: MartiniProperties[];
    version: string;
    generateType: string;
}

export interface UpdateManifest extends CreateManifest {
}

export interface ManifestBuildProp {
    name: string;
    value: string;
}

export interface BuildManifest {
    manifestProperties: MartiniProperties[];
    version: string;
    customBuild: boolean;
}

export interface MartiniProperties {
    packageProperties: PackageProperties;
    configurationBuild: ConfigurationBuild;
    manifestName: string;
}

export interface PackageProperties {
    customProperties: ManifestBuildProp[];
    connectionPoolInfo: {
        url: string;
        username: string;
        password: string;
        type: ConnectionPoolType;
        databaseName: string;
    },
}

export interface AggregateQueries {
    name: string;
    entity: string;
    column: AggregateQueriesColumn[];
}

export interface AggregateQueriesColumn {
    field: string;
    type: AggregateType;
    alias: string;
}

export interface ConfigurationBuild {
    apiConfiguration: {
        type: ApiTypes[];
        security: ApiSecurity;
        excludeOperations: string[];
    }
    sqlConfiguration: {
        applyAutoIncrement: boolean;
        applyCoalesce: boolean;
        applyLimitOffset: boolean;
        applyJoin: boolean;
    }
    serviceConfiguration: {
        applyMultiTenancy: boolean;
        applyCustomField: boolean;
        applySot: boolean;
        sotEntities: string[];
    },
    aggregateQueries: AggregateQueries[];
}

export enum ApiTypes {
    REST = 'REST',
    SOAP = 'SOAP',
    GRAPHQL = 'GRAPHQL',
}

export enum ApiSecurity {
    NONE = 'NONE',
    BASIC = 'BASIC',
    OAUTH2 = 'OAUTH2',
}

export enum ConnectionPoolType {
    PostgreSQL = 'postgresql',
    MySQL = 'mysql',
    HSQL = 'hsql',
    MSSQL = 'mssql',
    ORACLE = 'oracle',
    THIN = 'oracle_thin',
    OCI8 = 'oracle_oci8',
    OCI9 = 'oracle_oci9',
}

export enum AggregateType {
    COUNT = 'COUNT',
    SUM = 'SUM',
    MIN = 'MIN',
    MAX = 'MAX',
    AVG = 'AVG',
    GROUPBY = 'GROUPBY',
}

export const ConnectionPoolUrl = {
    [ConnectionPoolType.MySQL]: "jdbc:mysql://[HOST]:[PORT:3306]/[DB]?allowMultiQueries=true&sessionVariables=sql_mode='ANSI'",
    [ConnectionPoolType.PostgreSQL]: 'jdbc:postgresql://[HOST]:[PORT:5432]/[DB]',
    [ConnectionPoolType.HSQL]: 'jdbc:hsqldb:file:[PATH-TO-DATABASE-DIRECTORY]/[DATABASE-NAME].db;hsqldb.tx=MVCC;sql.syntax_mys=true',
    [ConnectionPoolType.MSSQL]: 'jdbc:sqlserver://[HOST]:[PORT:1433];databaseName=[DB];',
    [ConnectionPoolType.ORACLE]: 'jdbc:oracle:thin:@[HOST]:[PORT:1521]:[SID]',
    [ConnectionPoolType.THIN]: 'jdbc:oracle:thin:@[HOST]:[PORT:1521]:[SID]',
    [ConnectionPoolType.OCI8]: 'jdbc:oracle:oci8:@[HOST]:[PORT:1521]:[SID]',
    [ConnectionPoolType.OCI9]: 'jdbc:oracle:oci:@[HOST]:[PORT:1521]:[SID]',
};