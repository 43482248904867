import React from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Icon} from "@material-ui/core";

export interface ConfirmDialogOptions {
    title: string;
    message: string;
}

interface ConfirmDialogProps {
    options?: ConfirmDialogOptions;
    open: boolean;
    onClose: () => void;
    onOkay?: () => void;
    onCancel: () => void;
}

export const ConfirmDialog: React.FC<ConfirmDialogProps> = ({options, open, onClose, onOkay, onCancel}) => {
    return <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xs"
        aria-labelledby="confirmation-dialog-title"
        open={open}
        onClose={onClose}
    >
        <DialogTitle id="confirmation-dialog-title">{options?.title}</DialogTitle>
        <DialogContent dividers style={{display: 'flex', alignItems: 'center'}}>
            <Icon fontSize='large' style={{marginRight: '5px', color: 'var(--warning-color)'}}>warning</Icon>
            <p dangerouslySetInnerHTML={{__html: options?.message || ''}}></p>
        </DialogContent>
        <DialogActions>
            <Button autoFocus onClick={onCancel}>
                Cancel
            </Button>
            <Button
                onClick={_ => {
                    if (onOkay)
                        onOkay();
                }}
                color='secondary'
                disableElevation
                variant='contained'
            >
                Ok
            </Button>
        </DialogActions>
    </Dialog>;
};