import React, {useEffect, useRef, useState} from "react";
import {LoadContentValue} from "./useContent";
import {useSnackbar} from "notistack";
import {LoadingProgress} from "../LoadingProgress";
import ContentContext from "./ContentContext";
import axios, {CancelTokenSource} from "axios";
import useConfirm from "../confirm/useConfirm";
import CDMDocument, { CDMDocumentType } from "../../core/observables/CDMDocument";
import ReloadContent from "./ReloadContent";

export enum TriggerType {
    LOGOUT = 'logout',
    PACKAGE = 'package',
}

export const ContentProvider: React.FC = ({children}) => {
    const [content, setContent] = useState<React.ReactElement>(<div/>);
    const [dirty, setDirty] = useState(false);
    const onCancelWindow = useRef<(() => void) | undefined>();
    const [loadContent, setLoadContent] = useState<LoadContentValue | undefined>();
    const [triggerType, setTriggerType] = useState<TriggerType | undefined>();
    const onSaveChangesListeners = useRef<((document: CDMDocument) => Promise<boolean>)[]>([]);
    const currentDocument = useRef<CDMDocument | undefined>(undefined);
    const [currentDocumentType, setCurrentDocumentType] = useState<CDMDocumentType>(CDMDocumentType.UNKNOWN);
    const busy = useRef<boolean>(false);
    const {enqueueSnackbar} = useSnackbar();
    const confirm = useConfirm();

    useEffect(() => {
        const handleBeforeUnload = (e: BeforeUnloadEvent) => {
            if (dirty) {
                e.preventDefault();
                if (onCancelWindow.current) setTimeout(onCancelWindow.current, 100);
                return e.returnValue = 'Leaving';
            }
        };
        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [dirty]);

    useEffect(() => {
        const cancelToken = axios.CancelToken.source();
        if (!busy.current) {
            busy.current = true;
            if (loadContent) {
                if (dirty) {
                    if(triggerType) {
                        setDirty(false);
                        busy.current = false;
                    } else {
                        processSaveChanges(loadContent, cancelToken)
                            .catch(_ => renderContent(loadContent, cancelToken))
                            .finally(() => {
                                setDirty(false);
                                busy.current = false;
                                setTriggerType(undefined);
                            });
                    }
                } else {
                    renderContent(loadContent, cancelToken);
                    busy.current = false;
                    setTriggerType(undefined);
                }
            } else {
                setContent(<div/>);
                busy.current = false;
                setTriggerType(undefined);
            }
        }

        return () => {
            cancelToken.cancel();
        };
    }, [loadContent, triggerType]);

    const setOnCancelWindow = (_onCancelWindow: () => void) => {
        onCancelWindow.current = _onCancelWindow;
    };

    const processSaveChanges = async (loadContent: LoadContentValue, cancelToken: CancelTokenSource) => {
        const { current: doc } = currentDocument;
        if (doc && doc.latestVersion !== doc.currentVersion) return;
    
        await confirm({message: 'Save Changes?', title: 'Save Changes' });
        if (currentDocument.current) {
            for (const onSaveChangesListener of onSaveChangesListeners.current) {
                if (await onSaveChangesListener(currentDocument.current)) {
                    renderContent(loadContent, cancelToken);
                    break;
                }
            }
        }
    };

    const addOnSaveChangesListener = (onSaveChangesListener: (document: CDMDocument) => Promise<boolean>) => {
        onSaveChangesListeners.current.push(onSaveChangesListener);
    };

    const removeOnSaveChangesListener = (onSaveChangesListener: (document: CDMDocument) => Promise<boolean>) => {
        onSaveChangesListeners.current.splice(onSaveChangesListeners.current.indexOf(onSaveChangesListener), 1);
    };

    const clearOnSaveChangesListener = () => onSaveChangesListeners.current.splice(0, onSaveChangesListeners.current.length);

    const renderContent = (loadContent: LoadContentValue, cancelToken: CancelTokenSource) => {
        setCurrentDocumentType(currentDocument.current?.documentType || CDMDocumentType.UNKNOWN)
        setContent(() => loadContent.options?.loader || <LoadingProgress/>);
        if (loadContent.document) {
            loadContent.document(cancelToken)
                .then(document => {
                    const view = loadContent.view(currentDocument.current = document);
                    setContent(view);
                })
                .catch(e => {
                    if (e) enqueueSnackbar(e, {variant: 'error'});
                    setContent(<ReloadContent onReload={() => renderContent(loadContent, cancelToken)}/>);
                });
        } else {
            setContent(<div/>);
        }
    };

    return <>
        <ContentContext.Provider value={{
            content,
            setLoadContent,
            setTriggerType,
            dirty,
            setDirty,
            busy,
            addOnSaveChangesListener,
            removeOnSaveChangesListener,
            clearOnSaveChangesListener,
            setOnCancelWindow,
        }}>
            {children}
        </ContentContext.Provider>
    </>
}