import { PropertySourceProvider } from "./PropertySourceProvider";
import { PropertySource } from "./PropertyDescriptor";
import { OrgPropertySource, OrgDocument } from "../admin/OrganizationNavigatorContentProvider";
import { CDMDocumentType } from "../../core/observables/CDMDocument";

export default class OrganisationPropertySourceProvider implements PropertySourceProvider {

    getPropertySource(property: any): PropertySource | null {
        return new OrgPropertySource(property as OrgDocument);
    }

    support(property: any): boolean {
        return property?.documentType === CDMDocumentType.ORG;
    }
}