export default interface CDMDocument {
    documentType: CDMDocumentType;
    parent: any;
    currentVersion?: string;
    latestVersion?: string;
}

export enum CDMDocumentType {
    UNKNOWN,
    WORKSPACE,
    MANIFEST_WORKSPACE,
    MANIFEST,
    ENTITY,
    SUB_MANIFEST,
    ENTITY_ATTRIBUTE,
    TYPE_ATTRIBUTE,
    IMPORT,
    ATTRIBUTE,
    ENTITY_FOLDER,
    MANIFEST_FOLDER,
    ORG,
    INSTANCE,
}