import React from "react";
import {CircularProgress} from "@material-ui/core";

export const LoadingProgress: React.FC = () => (
    <div
        style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%',
            padding: '10px'
        }}>
        <CircularProgress/>
    </div>
)