import React, {useEffect, useRef} from "react";
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormHelperText,
} from "@material-ui/core";
import {observer} from "mobx-react";
import Manifest from "../../core/observables/Manifest";
import DocumentItem from "../../core/observables/DocumentItem";
import {Filter} from "../../core/Filter";
import {ManifestService} from "../../core/services/ManifestService";
import {PaginatedSelect} from "../select/PaginatedSelect";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import axios, {CancelTokenSource} from "axios";
import { AdminOrganisationService } from "../../core/services/admin/AdminOrganisationService";

interface AddSubManifestDialogProps {
    open: boolean;
    onClose: () => void;
    onFinish: (subManifest: DocumentItem, manifest: Manifest) => Promise<string | undefined>;
    manifest: Manifest | undefined;
    manifestService: ManifestService;
    adminOrgService: AdminOrganisationService;
    viewMode: string;
    defaultSubManifest?: DocumentItem;
    manifestOrg?: string;
    orgLabel?: string;
}

export const AddSubManifestDialog: React.FC<AddSubManifestDialogProps> = ({
    manifest,
    open,
    onClose,
    onFinish,
    manifestService,
    adminOrgService,
    viewMode,
    defaultSubManifest,
    manifestOrg,
    orgLabel,
}) => {
    const [subManifest, setSubManifest] = React.useState<DocumentItem | null>(defaultSubManifest || null);
    const [isLoading, setIsLoading] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
    const [filter, setFilter] = React.useState<Filter>(Filter.DEFAULT);
    const [updateAddSubManifest, setUpdateAddSubManifest] = React.useState(0);
    const manifestOptionsCancelToken = useRef<CancelTokenSource | undefined>(undefined);

    useEffect(() => refreshSubManifestSelect(), [filter]);

    useEffect(() => {
        if (open) {
            setFilter(Filter.DEFAULT);
            setSubManifest(null);
            setErrorMessage(null);
        }
    }, [open])

    const refreshSubManifestSelect = () => {
        setUpdateAddSubManifest(updateAddSubManifest + 1);
        setSubManifest(null);
    }

    const handleManifestOptions = (page: number, limit: number, search?: string) => {
        if (manifestOptionsCancelToken.current) manifestOptionsCancelToken.current.cancel();
        manifestOptionsCancelToken.current = axios.CancelToken.source();

        if(viewMode === 'Admin' && filter === Filter.PRIVATE) {
            return adminOrgService.getManifestsByOrg(manifestOrg || 'TORO', limit, page, search, manifest?.id, manifestOptionsCancelToken.current.token)
                .then(response => {
                    manifestOptionsCancelToken.current = undefined;
                    return {
                        ...response,
                        result: response.result.map(manifest => ({
                            label: manifest.name,
                            value: manifest,
                        }))
                    };
                })
        }

        return manifestService.getManifests(filter, manifest?.id, limit, page, search, manifestOptionsCancelToken.current.token)
            .then(response => {
                manifestOptionsCancelToken.current = undefined;
                return {
                    ...response,
                    result: response.result.map(manifest => ({
                        label: manifest.name,
                        value: manifest,
                    }))
                };
            })
    };

    const handleFilter = (event: React.ChangeEvent<HTMLInputElement>) =>
        setFilter(event.target.checked ? Filter.DEFAULT : Filter.PRIVATE);

    const getCoreRelativePath = (location: string): string => {
        location = location.replace('cdm:/core/', '');
        let splitLocation = location.split('/');
        splitLocation.splice(splitLocation.length - 1, 1);
        return splitLocation.join('/');
    };

    const hasError = (): boolean => {
        return errorMessage !== null && errorMessage.length > 0;
    }

    const handleSubManifestSubmit = () => {
        setErrorMessage(null);
        if (subManifest && manifest) {
            setIsLoading(true);
            onFinish(subManifest, manifest)
                .then(error => {
                    setIsLoading(false);
                    if (error && error.length > 0) {
                        setErrorMessage(error);
                    } else {
                        onClose();
                    }
                })
        }
    }

    return <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <form onSubmit={e => {
            e.preventDefault();
            handleSubManifestSubmit();
        }}>
            <DialogTitle id="alert-dialog-title">Add Sub-Manifest</DialogTitle>
            <DialogContent dividers style={{overflowY: 'hidden', maxWidth: '500px', width: '500px', minHeight: '400px'}}>
                { viewMode === 'Admin' && filter === Filter.PRIVATE ?
                    <p className='dialog-label'>Select a sub-manifest from <b>{orgLabel}</b> organisation to add in <b>{manifest?.name}</b> manifest.</p> :
                    <p className='dialog-label'>Select a sub-manifest to add in <b>{manifest?.name}</b> manifest.</p> }
                <FormControlLabel
                    control={<Checkbox checked={filter === Filter.DEFAULT} onChange={handleFilter}
                                    inputProps={{'aria-label': 'Select custom manifests'}}/>}
                    label="Show Core Manifests"
                />
                <FormControl variant='filled' fullWidth={true} error={hasError()}>
                    <PaginatedSelect
                        autoFocus={true}
                        isClearable={true}
                        update={updateAddSubManifest}
                        getOptions={handleManifestOptions}
                        valueMapper={value => value ? ({label: value.name, value}) : null}
                        onValueChanged={subManifest => {
                            setErrorMessage(null);
                            if (subManifest) {
                                if (manifest && manifest.id !== subManifest.id) setSubManifest(subManifest);
                                else setErrorMessage('You can\'t select the same manifest as your sub-manifest.');
                            }
                        }}
                        formatOptionLabel={option => {
                            return <div style={{display: 'flex', flexDirection: 'column'}}>
                                {option.label}
                                {(filter === Filter.DEFAULT && option.value?.path) && <small
                                    style={{color: 'var(--label-1-color)'}}>{getCoreRelativePath(option?.value?.path)}</small>}
                            </div>;
                        }}
                    />
                    <FormHelperText>{errorMessage}</FormHelperText>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>
                    Close
                </Button>
                <Button
                    type='submit'
                    color='secondary'
                    variant='contained'
                    disableElevation
                    disabled={subManifest === null || hasError()}
                    startIcon={isLoading && <CircularProgress size={18}/>}
                >
                    Finish
                </Button>
            </DialogActions>
        </form>
    </Dialog>;
}

export default observer(AddSubManifestDialog);