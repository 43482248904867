import React, { useImperativeHandle, useRef, useState } from 'react'
import Menu from '@material-ui/core/Menu'
import MenuItem, {MenuItemProps} from '@material-ui/core/MenuItem'
import { makeStyles } from '@material-ui/core/styles'
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import clsx from 'clsx';
import MenuIcon from '@material-ui/icons/Menu';

export interface NestedMenuProps extends Omit<MenuItemProps, 'button'> {
    label: string;
    styleType?: string;
    leftIcon?: React.ReactNode;
    rightIcon?: React.ReactNode;
    ContainerProps?: React.HTMLAttributes<HTMLElement> &
    React.RefAttributes<HTMLElement | null>
}

const transparent = 'rgba(0,0,0,0)'
const useMenuItemStyles = makeStyles((theme) => ({
  root: (props: any) => ({
    backgroundColor: props.open ? theme.palette.action.hover : transparent,
    fontSize: props.styleType === 'Menu' ? '16px': '14px',
  })
}))

const NestedMenu = React.forwardRef<
    HTMLLIElement,
    NestedMenuProps
>(function NestedMenu(props, ref) {
    const {
        label,
        styleType = 'Menu',
        leftIcon = <MenuIcon/>,
        rightIcon = <ArrowRightIcon/>,
        children,
        className,
        ContainerProps: ContainerPropsProp = {},
    } = props;

    const {ref: containerRefProp, ...ContainerProps} = ContainerPropsProp;

    const menuItemRef = useRef<HTMLLIElement>(document.body as HTMLLIElement)
    useImperativeHandle(ref, () => menuItemRef.current)

    const containerRef = useRef<HTMLDivElement>(null);
    useImperativeHandle(containerRefProp, () => containerRef.current);

    const menuContainerRef = useRef<HTMLDivElement>(null);

    const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);

    const handleMouseEnter = (event: React.MouseEvent<HTMLElement>) => {
        setIsSubMenuOpen(true);

        if (ContainerProps?.onMouseEnter) {
            ContainerProps.onMouseEnter(event);
        }
    }

    const handleMouseLeave = (event: React.MouseEvent<HTMLElement>) => {
        setIsSubMenuOpen(false);

        if (ContainerProps?.onMouseLeave) {
            ContainerProps.onMouseLeave(event);
        }
    }
    
    const handleFocus = (event: React.FocusEvent<HTMLElement>) => {
        if (event.target === containerRef.current) {
            setIsSubMenuOpen(true);
        }

        if (ContainerProps?.onFocus) {
            ContainerProps.onFocus(event);
        }
    }

    const open = isSubMenuOpen
    const menuItemClasses = useMenuItemStyles({open, styleType})

    return (
        <div
          {...ContainerProps}
          ref={containerRef}
          onFocus={handleFocus}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <MenuItem
            className={clsx(menuItemClasses.root, className)}
            ref={menuItemRef}
          >
            {leftIcon}
            {label}
            {rightIcon}
          </MenuItem>
          <Menu
            style={{pointerEvents: 'none'}}
            anchorEl={menuItemRef.current}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
            open={open}
            autoFocus={false}
            disableAutoFocus
            disableEnforceFocus
            onClose={() => {
              setIsSubMenuOpen(false);
            }}
          >
            <div ref={menuContainerRef} style={{pointerEvents: 'auto'}}>
              {children}
            </div>
          </Menu>
        </div>
      )
})

export default NestedMenu;