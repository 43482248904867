import React, {FC, ReactElement, useState, ReactNode, useEffect} from "react";
import {Accordion, createStyles, Drawer, Hidden, Icon, Theme, withStyles, Tabs, Tab, IconButton, MenuItem} from "@material-ui/core";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import {makeStyles} from "@material-ui/core/styles";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import DocumentTab from "../core/observables/DocumentTab";
import ContextMenu, {Position} from "./ContextMenu";
import { NegroniIcon } from "./icon/NegronIIcon";

const propertiesViewWidth = 300;

const contentStyles = makeStyles((theme: Theme) =>
    createStyles({
        appBar: {
            [theme.breakpoints.up('sm')]: {
                width: `calc(100%)`,
            },
            backgroundColor: "#221E2E"
        },
        // necessary for content to be below app bar
        toolbar: {
            padding: '0px!important',
            ...theme.mixins.toolbar,
        },
        content: {
            display: 'flex',
            flexGrow: 1,
            justifyContent: 'flex-end',
            height: '100%',
            maxHeight: 'calc(100vh - 122px)',
        },
        propertiesView: {
            maxWidth: propertiesViewWidth,
            width: propertiesViewWidth,
            height: '100%',
        },
        propertiesDrawer: {
            width: `${propertiesViewWidth}px`,
            flexShrink: 0,
        },
        propertiesDrawerPaper: {
            width: `${propertiesViewWidth}px`,
            minHeight: 32,
            marginTop: '90px',
        },
        heading: {
            fontSize: theme.typography.pxToRem(15),
            fontWeight: theme.typography.fontWeightRegular,
            margin: 0,
        },
        imageCirle: {
            borderRadius: '50%',
            width: 40,
        },
        tab: {
            maxWidth: 'max-content',
            "&:hover": {
                backgroundColor: "var(--hover-color) !important",
            },
            minHeight: 32,
            maxHeight: 32,
        },
        indicator: {
            top: "0px"
          }
    }),
);

const AccordionDetails = withStyles((theme) => ({
    root: {
        padding: 0,
        transition: 'all 5s ease-in-out',
    },
}))(MuiAccordionDetails);

const AccordionSummary = withStyles({
    root: {
        backgroundColor: 'var(--overlay-color)',
        color: 'var(--white-color)',
        borderBottom: '1px solid rgba(0, 0, 0, .125)',
        marginBottom: -1,
        minHeight: 56,
        '&$expanded': {
            minHeight: 56,
        },
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
})(MuiAccordionSummary);

interface ContentContainerProps {
    propertiesView: ReactElement;
    expandContent: boolean;
    setExpandContent: (expandContent: boolean) => void;
    expandProperties: boolean;
    setExpandProperties: (expandProperties: boolean) => void;
    currentTab: string;
    tabs: DocumentTab[];
    handleTabChange: (document: DocumentTab) => void;
    handleTabClose: (document: DocumentTab) => void;
    handleTablCloseAll: () => void;
    handleTabCloseOthers: (document: DocumentTab) => void;
    handleTabCloseRight: (document: DocumentTab, current: DocumentTab) => void;
    handleTabCloseLeft: (document: DocumentTab, current: DocumentTab) => void;
}

const ContentContainer: FC<ContentContainerProps> = ({
    propertiesView,
    children,
    setExpandContent,
    setExpandProperties,
    expandContent,
    expandProperties,
    currentTab,
    tabs,
    handleTabChange,
    handleTabClose,
    handleTablCloseAll,
    handleTabCloseOthers,
    handleTabCloseRight,
    handleTabCloseLeft
}) => {
    const classes = contentStyles();
    const [currentContent, setCurrentContent] = useState<ReactNode>(children);
    const [selection, setSelection] = useState<DocumentTab | null>(null);
    const [position, setPosition] = useState<Position | null>(null);

    useEffect(() => {
        const document = tabs.find(tab => tab.name === currentTab);
        if(document) {
            setCurrentContent(document.content)
        } else {
            setCurrentContent(null)
        }
    }, [currentTab]);

    function handleCloseContextMenu() {
        setPosition(null);
    }

    function handleContextMenu(e: React.MouseEvent<HTMLDivElement>, document: DocumentTab) {
        setSelection(document);

        e.preventDefault();
        setPosition({
            mouseX: e.clientX,
            mouseY: e.clientY,
        });
    }

    function handleTabCloseContextMenu() {
        if(selection)
            handleTabClose(selection)
    }

    function handleTabCloseOthersContextMenu() {
        if(selection)
            handleTabCloseOthers(selection)
    }

    function handleTabCloseRightContextMenu() {
        const currentDocument = tabs.find(tab => tab.name === currentTab);
        if(selection && currentDocument)
            handleTabCloseRight(selection, currentDocument)
    }

    function handleTabCloseLeftContextMenu() {
        const currentDocument = tabs.find(tab => tab.name === currentTab);
        if(selection && currentDocument)
            handleTabCloseLeft(selection, currentDocument)
    }

    return <>
        <Hidden mdDown>
            <div style={{display: 'flex', flexDirection: 'column', minWidth: `calc(100vw - 580px)`, maxWidth: `calc(100vw - 580px)`}}>
                <Tabs
                    classes={{
                        indicator: classes.indicator
                    }}
                    value={currentTab}
                    style={{marginTop: 90, minHeight: 32, backgroundColor: "var(--data-heading-color)"}}
                    variant="scrollable"
                    scrollButtons="off"
                >
                    {tabs.map((document) => (
                        <Tab
                            className={classes.tab}
                            wrapped={true}
                            onClick={() => { handleTabChange(document)}}
                            onContextMenu={e => handleContextMenu(e, document)}
                            label={
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}>
                                    <NegroniIcon iconClass={document.icon} size="small" extraClass="negroni-menu-icon" />
                                        {document.name}
                                    <IconButton
                                        size="small"
                                        color="inherit"
                                        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                            event.stopPropagation()
                                            handleTabClose(document) 
                                        }}
                                    >
                                        <NegroniIcon iconClass="close-icon" size="small" extraClass="negroni-menu-icon" />
                                    </IconButton>
                                </div>}
                            value={document.name}/>
                    ))}
                </Tabs>
                <ContextMenu handleClose={handleCloseContextMenu} position={position}>
                    <MenuItem
                        disabled={selection === null}
                        dense
                        onClick={_ => {
                            handleCloseContextMenu()
                            handleTabCloseContextMenu()
                        }}
                    >
                        Close
                    </MenuItem>
                    {tabs.length > 1 ? <MenuItem
                        disabled={selection === null}
                        dense
                        onClick={_ => {
                            handleCloseContextMenu()
                            handleTablCloseAll()
                        }}
                    >
                        Close All
                    </MenuItem> : <></> }
                    {tabs.length > 1 ? <MenuItem
                        disabled={selection === null}
                        dense
                        onClick={_ => {
                            handleCloseContextMenu()
                            handleTabCloseOthersContextMenu()
                        }}
                    >
                        Close Others
                    </MenuItem> : <></> }
                    {selection && !!tabs[tabs.indexOf(selection, 0) + 1] ? <MenuItem
                        disabled={selection === null}
                        dense
                        onClick={_ => {
                            handleCloseContextMenu()
                            handleTabCloseRightContextMenu()
                        }}
                    >
                        Close Tabs to the Right
                    </MenuItem> : <></>}
                    {selection && !!tabs[tabs.indexOf(selection, 0) - 1] ? <MenuItem
                        disabled={selection === null}
                        dense
                        onClick={_ => {
                            handleCloseContextMenu()
                            handleTabCloseLeftContextMenu()
                        }}
                    >
                        Close Tabs to the Left
                    </MenuItem> : <></>}
                </ContextMenu>
                <div className={classes.content}>
                    <div style={{display: 'flex', flexGrow: 1, height: '100%'}}>
                        <div style={{flexGrow: 1}}>
                            {currentContent}
                            <div className={classes.propertiesView}>
                                <div className={classes.toolbar}/>
                                <Drawer
                                    className={classes.propertiesDrawer}
                                    variant='permanent'
                                    anchor='right'
                                    classes={{
                                        paper: classes.propertiesDrawerPaper
                                    }}
                                >
                                    {propertiesView}
                                </Drawer>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Hidden>
        <Hidden lgUp={true}>
            <div style={{flexGrow: 1}}>
                <div className={classes.toolbar}/>
                <Accordion square expanded={expandContent} onChange={(e, expand) => setExpandContent(expand)}>
                    <AccordionSummary expandIcon={<Icon>expand</Icon>}>
                        <h5 className={classes.heading}>Content</h5>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div style={{width: '100%'}}>
                            {children}
                        </div>
                    </AccordionDetails>
                </Accordion>
                <Accordion square expanded={expandProperties} onChange={(e, expand) => setExpandProperties(expand)}>
                    <AccordionSummary expandIcon={<Icon>expand</Icon>}>
                        <h5 className={classes.heading}>Properties</h5>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div style={{width: '100%'}}>
                            {propertiesView}
                        </div>
                    </AccordionDetails>
                </Accordion>
            </div>
        </Hidden>
    </>
};

export default ContentContainer;