import React, {createRef} from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import {CDMManager} from "./core/CDMManager";
import {HashRouter} from "react-router-dom";
import App from "./App";
import Logout from "./component/auth/Logout";
import {IconButton, MuiThemeProvider} from "@material-ui/core";
import {theme} from "./TOROTheme";
import PackageView from "./component/package/PackageView";
import ConfirmProvider from "./component/confirm/ConfirmProvider";
import {SnackbarProvider} from "notistack";
import {ContentProvider} from "./component/content/ContentProvider";
import {Compose} from "./component/Compose";
import Admin from "./component/admin/Admin";
import AuthProvider from "./component/auth/AuthProvider";
import ProtectedRoute from './component/router/ProtectedRoute';
import PublicRoute from './component/router/PublicRoute';
import ServiceProvider from "./component/service/ServiceProvider";
import PropertiesProvider from "./component/properties/PropertiesProvider";
import BuildManifestProvider from "./component/manifest/build/BuildManifestProvider";
import SessionExpiredProvider from "./component/session_expired/SessionExpiredProvider";
import HttpInterceptorProvider from "./component/http_interceptor/HttpInterceptorProvider";
import SessionProvider from "./component/session/SessionProvider";
import { NegroniIcon } from './component/icon/NegronIIcon';
import { Login } from './component/auth/Login';
import Error from './component/error/Error';

const cdmManager = new CDMManager();
cdmManager.init();

const notistackRef = createRef<SnackbarProvider>();
const onClickDismiss = (key: string) => () => {
    notistackRef.current?.closeSnackbar(key);
}

ReactDOM.render(
    <Compose components={[
        [MuiThemeProvider, {theme}],
        [SnackbarProvider, {
            ref: notistackRef,
            maxSnack: 3,
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
            },
            action: (key: string) => (
                <IconButton onClick={onClickDismiss(key)}>
                    <NegroniIcon iconClass="close-icon" />
                </IconButton>
            ),
        }],
        SessionProvider,
        ConfirmProvider,
        HashRouter,
        AuthProvider,
        SessionExpiredProvider,
        HttpInterceptorProvider,
        ContentProvider,
        ServiceProvider,
        PropertiesProvider,
        BuildManifestProvider,
    ]}>
        <ProtectedRoute exact path='/'>
            <App cdmManager={cdmManager}/>
        </ProtectedRoute>
        <ProtectedRoute exact path='/admin'>
            <Admin/>
        </ProtectedRoute>
        <ProtectedRoute exact path='/packages'>
            <PackageView/>
        </ProtectedRoute>
        <ProtectedRoute path='/sso/logout'>
            <Logout/>
        </ProtectedRoute>
        <ProtectedRoute path='/error'>
            <Error/>
        </ProtectedRoute>
        <PublicRoute path='/sso/login'>
            <Login/>
        </PublicRoute>
    </Compose>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
