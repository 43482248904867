import React, {FC, useState, useEffect} from "react";
import {observer} from "mobx-react";
import {useHistory} from "react-router-dom";
import useQuery from "../router/useQuery";
import useAuth from "./useAuth";
import { Grid, CircularProgress, Typography } from "@material-ui/core";

export const Login: FC = () => {
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const query = useQuery();
    const {generateState, storeState, getState, getAuthState, updateAuthState, ssoSignIn} = useAuth();
    const params = new URLSearchParams(window.location.search)
    const authCode = query.get('code') || params.get('code');
    const authState = query.get('state') || params.get('state');
    const [textProcess, setTextProcess] = useState('Redirecting...');
    let isAuthRunning = false

    useEffect( () => {
        if ( authCode && !isAuthRunning ) {
            isAuthRunning = true;
            updateAuthState({isAuthRunning: true, isAuthenticated: false});
            setTextProcess('Signing In...');
            if(authState) {
                const sessionState = getState();
                if(authState !== sessionState) {
                    history.push(`/error?code=${401}&error=${'State Mismatch'}&message=${'Could not find state session or incorrect state value'}`);
                }
            } else {
                history.push(`/error?code=${401}&error=${'State Mismatch'}&message=${'State not found on the URL parameter'}`);
            }
            ssoSignIn( authCode ).then( _ => {
                setLoading(false)
                updateAuthState({isAuthRunning: false, isAuthenticated: true});
                window.history.pushState( '', '', '/' );
                history.push('/');
            }).catch(e => {
                const responseData = e.response.data;
                setLoading(false);
                updateAuthState({isAuthRunning: false, isAuthenticated: false});
                window.history.pushState( '', '', '/' );
                history.push(`/error?code=${responseData?.code || 401}&error=${responseData.error}&message=${responseData.message}`);
            });
        } else {
            const signInUrl = process.env.REACT_APP_SSO_LOGIN_URL;
            if ( signInUrl && !getAuthState()?.isAuthenticated ) {
                const stateToken = generateState();
                storeState(stateToken);
                window.location.href = signInUrl + '&state=' + stateToken;
            }
        }
    }, [])

    return <>
        {loading && <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justify="center"
            style={{ minHeight: '100vh' }}
        >
            <Grid
                style={{ marginBottom: '20px' }}
            >
                <CircularProgress size={75}/>
            </Grid>
            <Grid>
            <Typography variant="h5">
                {textProcess}
            </Typography>
            </Grid>
        </Grid>}
    </>;
}

export default observer(Login);