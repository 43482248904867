import React from "react";
import {PaginatedChildren} from "../navigator/NavigatorContentProvider";
import {CancelTokenSource} from "axios";
import {Org} from "../auth/User";
import CDMDocument, {CDMDocumentType} from "../../core/observables/CDMDocument";
import {PaginatedMockItem} from "../navigator/Navigator";
import {CDMContentProvider} from "../../core/observables/Workspace";
import { PropertyDescriptor, PropertySource } from "../properties/PropertyDescriptor";
import { CellEditorProps, TextCellEditor } from "../properties/CellEditor";

export class OrgDocument implements Org, CDMDocument {
    readonly documentType = CDMDocumentType.ORG;
    id: number;
    code: string;
    name: string;
    orgName: string;
    parent: any;
    totalEntities: number = 0;
    totalManifests: number = 0;
    totalPackages: number = 0;

    constructor(id: number, code: string, name: string, orgName: string) {
        this.id = id;
        this.code = code;
        this.name = name;
        this.orgName = orgName;
    }
}

export class OrgPropertySource implements PropertySource {
    private readonly org: OrgDocument;
    private readonly descriptors: PropertyDescriptor[] = [];
    documentType = CDMDocumentType.ORG

    constructor(org: OrgDocument) {
        this.org = org;
        this.descriptors.push({
            readOnly: true,
            defaultValue: this.org.name,
            displayName: 'Name',
            description: 'Name of the organisation',
            hasDefaultValue: true,
            name: 'name',
            value: this.org.name,
            target: this.org,
            getCellEditor: (props: CellEditorProps<any>) => <TextCellEditor {...props} label='Name'
                                                                            description='Name of the organisation'/>,
            validate: _ => undefined,
        }, {
            readOnly: true,
            defaultValue: this.org.code,
            displayName: 'Code',
            description: 'Code of the organisation',
            hasDefaultValue: true,
            name: 'code',
            value: this.org.code,
            target: this.org,
            getCellEditor: (props: CellEditorProps<any>) => <TextCellEditor {...props} label='Code'
                                                                            description='Code of the organisation'/>,
            validate: _ => undefined,
        }, {
            readOnly: true,
            defaultValue: this.org.totalEntities,
            displayName: 'Total Entities',
            description: 'Total Entities of the organisation',
            hasDefaultValue: true,
            name: 'totalEntities',
            value: this.org.totalEntities,
            target: this.org,
            getCellEditor: (props: CellEditorProps<any>) => <TextCellEditor {...props} label='Total Entities'
                                                                            description='Total Entities of the organisation'/>,
            validate: _ => undefined,
        }, {
            readOnly: true,
            defaultValue: this.org.totalManifests,
            displayName: 'Total Manifest',
            description: 'Total Manifest of the organisation',
            hasDefaultValue: true,
            name: 'totalManifest',
            value: this.org.totalManifests,
            target: this.org,
            getCellEditor: (props: CellEditorProps<any>) => <TextCellEditor {...props} label='Total Manifest'
                                                                            description='Total Manifest of the organisation'/>,
            validate: _ => undefined,
        }, {
            readOnly: true,
            defaultValue: this.org.totalPackages,
            displayName: 'Total Package',
            description: 'Total Package of the organisation',
            hasDefaultValue: true,
            name: 'totalPackage',
            value: this.org.totalPackages,
            target: this.org,
            getCellEditor: (props: CellEditorProps<any>) => <TextCellEditor {...props} label='Total Package'
                                                                            description='Total Package of the organisation'/>,
            validate: _ => undefined,
        });
    }

    get title(): string {
        return 'Organisation'
    }

    get iconClass(): string {
        return '';
    }

    dispose(): void {
    }

    getPropertyDescriptors(): PropertyDescriptor[] {
        return this.descriptors;
    }

    async setProperty(name: string): Promise<void> {
        switch (name) {
            case 'name':
                break;
            case 'code':
                break;
            case 'totalEntities':
                break;
            case 'totalManifest':
                break;
            case 'totalPackage':
                break;
        }
    }

    getModel(): CDMDocument {
        return this.org;
    }
}

export default class OrganizationNavigatorContentProvider extends CDMContentProvider {

    async children(node: any, page: number, limit: number, search: string, cancelTokenSource: CancelTokenSource): Promise<any[]> {
        if (super.supported(node)) return super.children(node, page, limit, search, cancelTokenSource);
        if (node?.documentType === CDMDocumentType.ORG) {
            return [
                new PaginatedMockItem(node.name + this.MANIFESTS_SUFFIX,
                    this._getNameByType(CDMDocumentType.MANIFEST),
                    '',
                    (page, limit) => {
                        return this.adminOrgService.getManifestsByOrg(node.path, limit, page, search, -1, cancelTokenSource.token)
                            .then(result => ({
                                children: result.result,
                                totalElements: result.totalElements,
                            } as PaginatedChildren))
                    }, node),
            ];
        }
        return node;
    }

    getIcon(node: any): string {
        const icon = super.getIcon(node);
        if (node.documentType === CDMDocumentType.ORG || node.label === 'Organisations')
            return 'business-icon';
        return icon;
    }

    getId(node: any): string {
        if (node.parent) return this.getId(node.parent) ? (this.getId(node.parent) + '~' + this._getId(node)) :
            this._getId(node);
        return this._getId(node);   
    }

    getLabel(node: any): string {
        const label = super.getLabel(node);
        if (label.length > 0) return label;
        if (node?.organisations) return 'Organisations';
        return node.name;
    }

    getNodeIds(node: any, current: string[]): string[] {
        return super.getNodeIds(node, current);
    }

    getParent(node: any): any {
        return super.getParent(node)
    }

    getPath(node: any): string | undefined {
        return super.getPath(node);
    }

    hasChildren(node: any): boolean {
        if (super.hasChildren(node)) return true;
        return node?.documentType === CDMDocumentType.ORG ||
            node?.label === 'Organisations';
    }

    isPaginated(node: any): boolean {
        return super.isPaginated(node);
    }

    paginatedChildren(node: any, page: number, limit: number, search: string, cancelTokenSource: CancelTokenSource): Promise<PaginatedChildren> | undefined {
        return super.paginatedChildren(node, page, limit, search, cancelTokenSource);
    }

    supported(node: any): boolean {
        if (super.supported(node)) return true;
        return node?.organisations || Array.isArray(node) || node?.documentType === CDMDocumentType.ORG || node?.type === 'mockDataItem';
    }

}