import React, {FC, useState} from "react";
import BuildManifestContext from "./BuildManifestContext";
import Manifest, {BuildManifest, MartiniProperties} from "../../../core/observables/Manifest";
import useService from "../../service/useService";
import {useSnackbar} from "notistack";
import {useHistory} from "react-router-dom";
import BuildManifestDialog from "./BuildManifestDialog";

const BuildManifestProvider: FC = ({children}) => {
    const {manifestService} = useService();
    const {enqueueSnackbar} = useSnackbar();
    const location = useHistory();
    const [open, setOpen] = useState<boolean>(false);
    const [hideConfiguration, setHideConfiguration] = useState<boolean>(false);
    const [manifest, setManifest] = useState<Manifest>({} as Manifest);
    const [customBuild, setcustomBuild] = useState<boolean>(true);

    const handleOpenBuildManifest = (manifest: Manifest, hideConfiguration: boolean, customBuild: boolean) => {
        setManifest(manifest);
        setOpen(true);
        setHideConfiguration(hideConfiguration);
        setcustomBuild(customBuild);
    };

    const handleFinish = async (manifestId: number, buildManifest: BuildManifest): Promise<void> => {
        try {
            await manifestService.buildManifest(manifest.id, buildManifest);
            enqueueSnackbar(`Package '${manifest.name}' successfully built`, {variant: 'success'});
            location.push({pathname: '/packages', search: `?new=${manifest.name}`});
        } catch (error) {
            throw new Error(error || 'Failed to build manifest');
        }
    };

    const handleClose = () => setOpen(false);

    return <>
        <BuildManifestContext.Provider value={handleOpenBuildManifest}>
            {children}
        </BuildManifestContext.Provider>
        <BuildManifestDialog
            manifest={manifest}
            open={open}
            onClose={handleClose}
            onFinish={handleFinish}
            hideConfiguration={hideConfiguration}
            customBuild={customBuild}
        />
    </>
};

export default BuildManifestProvider;