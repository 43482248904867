import React, {FC} from "react";
import Manifest, {MartiniProperties} from "../../../core/observables/Manifest";
import {Formik, FormikErrors} from "formik";
import {_noop} from "../../../util";
import BuildManifestForm from "./BuildManifestForm";

interface BuildManifestFormContainerProps {
    manifest: Manifest;
    initialValues: MartiniProperties;
    onValidate: (buildManifest: MartiniProperties) => FormikErrors<MartiniProperties> | undefined;
    onChange: (buildManifest: MartiniProperties) => void;
}

const BuildManifestFormContainer: FC<BuildManifestFormContainerProps> = ({
    manifest,
    initialValues,
    onValidate,
    onChange,
}) => {
    return <Formik
        initialValues={initialValues}
        initialTouched={{
            manifestName: true,
            packageProperties: {
                connectionPoolInfo: {
                    databaseName: true,
                    password: true,
                    type: true,
                    url: true,
                    username: true,
                }
            }
        }}
        enableReinitialize={true}
        validateOnMount={true}
        validate={async (values: MartiniProperties) => {
            const error = onValidate(values);
            if (!error) onChange(values);
            return error;
        }}
        onSubmit={_noop}
    >
        {props => <BuildManifestForm
            manifest={manifest}
            values={props.values}
            setFieldValue={props.setFieldValue}
            handleBlur={props.handleBlur}
            handleChange={props.handleChange}
            errors={props.errors}
        />}
    </Formik>;
}

export default BuildManifestFormContainer;