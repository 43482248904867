import React from "react";
import {RouteProps} from "react-router";
import {Redirect, Route} from "react-router-dom";
import useSession from "../session/useSession";

const PublicRoute: React.FC<RouteProps> = (props) => {
    const {isLoggedIn} = useSession();

    return <Route
        {...props}
        children={undefined}
        render={() => isLoggedIn() ? <Redirect push to='/'/> : props.children}
    />
}

export default PublicRoute;