import React, {useEffect, useRef, useState} from "react";
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormHelperText,
    Paper,
    Tab,
    Tooltip,
    TextField,
} from "@material-ui/core";
import {TabContext, TabList, TabPanel} from "@material-ui/lab";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {Filter} from "../../core/Filter";
import {PaginatedSelect, SelectGroupOption, SelectOption} from "../select/PaginatedSelect";
import {NewEntity} from "./EntityView";
import Entity, {CreateEntityType} from "../../core/observables/Entity";
import Manifest from "../../core/observables/Manifest";
import PaginatedResponse from "../../core/services/PaginatedResponse";
import {EntityService} from "../../core/services/EntityService";
import DocumentItem from "../../core/observables/DocumentItem";
import {CancelTokenSource} from "axios";
import * as yup from "yup";
import {NAME_PATTERN} from "../../core/constants";
import { AdminOrganisationService } from "../../core/services/admin/AdminOrganisationService";
import { CDMManager } from "../../core/CDMManager";
import { FilterList, Clear } from "@material-ui/icons";

interface ManageEntityDialogProps {
    manifest: Manifest | undefined;
    open: boolean;
    onClose: () => void;
    entityService: EntityService;
    adminOrgService: AdminOrganisationService
    onFinishCreateExclusiveEntity: (exclusiveEntity: Entity, manifest: Manifest) => Promise<string | undefined>;
    onFinishAddEntity: (entity: DocumentItem, manifest: Manifest) => Promise<string | undefined>;
    viewMode: string;
    defaultExistingEntity?: DocumentItem;
    defaultTab?: ManageEntityTabValue;
    manifestOrg?: string;
    orgLabel?: string;
}

export enum ManageEntityTabValue {
    ADD_ENTITY = 'addEntity',
    CREATE_EXCLUSIVE = 'createExclusive',
}

export const ManageEntityDialog: React.FC<ManageEntityDialogProps> = ({
    manifest,
    open,
    onFinishCreateExclusiveEntity,
    onFinishAddEntity,
    onClose,
    entityService,
    adminOrgService,
    viewMode,
    defaultExistingEntity,
    defaultTab,
    manifestOrg,
    orgLabel,
}) => {
    const [tab, setTab] = useState<ManageEntityTabValue>(defaultTab || ManageEntityTabValue.ADD_ENTITY);
    const [filter, setFilter] = React.useState<Filter>(Filter.RESOLVED);
    const [exclusiveEntity, setExclusiveEntity] = useState<Entity>(new Entity(-1, 'new_entity', '', CreateEntityType.EXCLUSIVE, manifest?.id));
    const [addEntity, setAddEntity] = React.useState<DocumentItem | null>(defaultExistingEntity || null);
    const [updateAddEntity, setUpdateAddEntity] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [exclusiveError, setExclusiveError] = useState<string | null>();
    const [error, setError] = useState<string | null>();
    const [nameInput, setNameInput] = useState<HTMLInputElement>();
    const isMounted = useRef(false);
    const [advanceFilter, setAdvanceFilter] = useState(false);
    const [folderFilter, setFolderFilter] = useState(false);
    const [folderFilterValue, setFolderFilterValue] = useState<string>('');
    const [pathFilter, setPathFilter] = useState(false);
    const [pathFilterValue, setPathFilterValue] = useState<string>('');

    const refreshAddEntity = () => {
        setUpdateAddEntity(updateAddEntity + 1);
    }

    useEffect(() => {
        isMounted.current = true;
        setExclusiveEntity(new Entity(-1, 'new_entity', '', CreateEntityType.EXCLUSIVE, manifest?.id));
        return () => {
            isMounted.current = false;
        }
    }, []);

    useEffect(() => {
        if(filter === Filter.PRIVATE) {
            resetFilter();
        }
        refreshAddEntity();
    }, [filter]);

    useEffect(() => {
        if (tab === ManageEntityTabValue.CREATE_EXCLUSIVE && nameInput)
            nameInput.select();
    }, [tab, nameInput]);

    useEffect(() => {
        if (open) {
            setTab(ManageEntityTabValue.ADD_ENTITY);
            setAddEntity(null);
            setExclusiveEntity(new Entity(-1, 'new_entity', '', CreateEntityType.EXCLUSIVE, manifest?.id));
            setFilter(Filter.RESOLVED);
        }
    }, [open]);

    useEffect(() => {
        setError(!addEntity ? 'this is a required field' : '');
    }, [addEntity]);

    useEffect(() => {
        validateName();
    }, [exclusiveEntity.name]);

    const handleAdvanceFilter = () => setAdvanceFilter(!advanceFilter);

    const handleFolderFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFolderFilter(event.target.checked);
        setFolderFilterValue('');
        refreshAddEntity();
    }

    const handlePathFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPathFilter(event.target.checked);
        setPathFilterValue('');
        refreshAddEntity();
    }

    const handleFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFilter(event.target.checked ? Filter.RESOLVED : Filter.PRIVATE);
        if( filter === Filter.PRIVATE ) {
            resetFilter();
        }
    }

    const resetFilter = () => {
        setAdvanceFilter(false);
        setFolderFilter(false);
        setFolderFilterValue('');
        setPathFilter(false);
        setPathFilterValue('');
        refreshAddEntity();
    }

    const hideFilter = () => {
        setAdvanceFilter(false);
    }

    const validateName = () => {
        try {
            yup.string()
                .required()
                .min(Entity.MIN_NAME_LENGTH)
                .max(Entity.MAX_NAME_LENGTH)
                .matches(NAME_PATTERN, 'this must only contain alphanumeric and no underscore consecutively')
                .validateSync(exclusiveEntity.name);
            setExclusiveError('');
        } catch (e) {
            setExclusiveError(e.message);
        }
    };

    const getCoreRelativePath = (location: string): string => {
        location = location.replace('cdm:/core/', '');
        let splitLocation = location.split('/');
        splitLocation.splice(splitLocation.length - 1, 1);
        return splitLocation.join('/');
    }

    const getEntities = (page: number = 0, limit: number = 20, search: string = '', cancelTokenSource?: CancelTokenSource): Promise<PaginatedResponse<SelectGroupOption[] | SelectOption[]>> => {
        if(viewMode === 'Admin' && filter === Filter.PRIVATE) {
            return adminOrgService.getEntitiesByOrg(manifestOrg || 'TORO', limit, page, search, cancelTokenSource?.token)
                .then(response => {
                    return {
                        ...response,
                        result: response.result.map(entity => ({label: entity.name, value: entity}))
                    } as PaginatedResponse<SelectOption[]>;
                });
        }
        return entityService.getEntities(filter, limit, page, search, -1, folderFilterValue, pathFilterValue, cancelTokenSource?.token)
            .then(response => {
                return {
                    ...response,
                    result: response.result.map(entity => ({label: entity.name, value: entity}))
                } as PaginatedResponse<SelectOption[]>;
            });
    };

    const handleCreateExclusiveEntity = () => {
        setIsLoading(true);
        setExclusiveError(null);
        if (manifest) {
            onFinishCreateExclusiveEntity(exclusiveEntity, manifest)
                .then(error => {
                    if (isMounted.current) {
                        setIsLoading(false);
                        if (error && error.length > 0) {
                            setExclusiveError(error);
                        } else {
                            localStorage.setItem(CDMManager.NEGRONI_RESET_SELECTED_NODE, 'true');
                            onClose();
                        }
                    }
                });
        }
    };

    const handleAddEntity = () => {
        if (addEntity) {
            setIsLoading(true);
            setError(null);
            if (manifest) {
                onFinishAddEntity(addEntity, manifest)
                    .then(error => {
                        if (isMounted.current) {
                            setIsLoading(false);
                            if (error && error.length > 0) {
                                setError(error);
                            } else {
                                localStorage.setItem(CDMManager.NEGRONI_RESET_SELECTED_NODE, 'true');
                                onClose();
                            }
                        }
                    });
            }
        }
    };

    return <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">Add Entity</DialogTitle>
        <TabContext value={tab}>
            <Paper elevation={0} square>
                <TabList
                    value={tab}
                    onChange={(e, value) => {
                        setTab(value);
                    }}
                    variant='fullWidth'
                >
                    <Tab value={ManageEntityTabValue.ADD_ENTITY} label='Choose Existing Entity'/>
                    <Tab value={ManageEntityTabValue.CREATE_EXCLUSIVE} label='Create Local Entity'/>
                </TabList>
            </Paper>
            <TabPanel value={ManageEntityTabValue.ADD_ENTITY}>
                <form onSubmit={e => {
                    e.preventDefault();
                    handleAddEntity();
                }}>
                    <DialogContent dividers
                                style={{
                                    overflowY: 'hidden',
                                    maxWidth: '500px',
                                    width: '500px',
                                    minHeight: '400px'
                                }}>
                        { viewMode === 'Admin' && filter === Filter.PRIVATE ?
                            <p className='dialog-label'>Select an entity from <b>{orgLabel}</b> organisation to add in <b>{manifest?.name}</b> manifest.</p> :
                            <p className='dialog-label'>Select an entity to add in <b>{manifest?.name}</b> manifest.</p> }
                        <FormControlLabel
                            control={<Checkbox checked={filter === Filter.RESOLVED} onChange={handleFilter}
                                            inputProps={{'aria-label': 'Select custom entities'}}/>}
                            label="Show Core Entities"
                        />
                        <FormControl variant='filled' fullWidth={true} style={{display: 'flex', flexDirection: 'row'}}>
                            <div style={{width: '100%'}}>
                                <PaginatedSelect
                                    autoFocus={tab === ManageEntityTabValue.ADD_ENTITY}
                                    getOptions={getEntities}
                                    valueMapper={value => value ? ({label: value.name, value: value.value}) : null}
                                    onValueChanged={setAddEntity}
                                    update={updateAddEntity}
                                    formatOptionLabel={option => {
                                        return <div style={{display: 'flex', flexDirection: 'column'}}>
                                            {option.label}
                                            {(filter === Filter.RESOLVED && option.value?.path) && <small
                                                style={{color: 'var(--label-1-color)'}}>{getCoreRelativePath(option?.value?.path)}</small>}
                                        </div>;
                                    }}
                                    hideFilter={hideFilter}
                                />
                            </div>
                            {filter === Filter.RESOLVED ? <Tooltip
                                title={advanceFilter ? 'Hide Advance Filters' : 'Advance Filters'}
                            >
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    style={{marginLeft: '10px'}}
                                    onClick={handleAdvanceFilter}
                                >
                                    {advanceFilter ? <Clear/> : <FilterList/>}
                                </Button>
                            </Tooltip> : undefined}
                        </FormControl>
                        <FormHelperText error={!!error && error.length > 0}>{error}</FormHelperText>
                        { advanceFilter && filter === Filter.RESOLVED  ? <FormControl variant='filled' fullWidth={true} style={{display: 'flex', flexDirection: 'column'}}>
                            <div>
                                <FormControlLabel
                                    control={<Checkbox checked={folderFilter} onChange={handleFolderFilter}
                                                    inputProps={{'aria-label': 'Filter by folder'}}/>}
                                    label="By Folder"
                                />
                                { folderFilter ? <TextField
                                    value={folderFilterValue}
                                    onChange={e => {
                                        setFolderFilterValue(e.target.value);
                                        refreshAddEntity();
                                    }}
                                    type='text'
                                    autoFocus
                                    fullWidth={true}
                                /> : undefined }
                            </div>
                            <div>
                                <FormControlLabel
                                    control={<Checkbox checked={pathFilter} onChange={handlePathFilter}
                                                    inputProps={{'aria-label': 'Filter by path'}}/>}
                                    label="By Path"
                                />
                                { pathFilter ? <TextField
                                    value={pathFilterValue}
                                    onChange={e => {
                                        setPathFilterValue(e.target.value);
                                        refreshAddEntity();
                                    }}
                                    type='text'
                                    autoFocus
                                    fullWidth={true}
                                /> : undefined }
                            </div>
                        </FormControl> : undefined}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={onClose}>
                            Close
                        </Button>
                        <Button
                            type='submit'
                            variant='contained'
                            disableElevation
                            color='secondary'
                            onClick={handleAddEntity}
                            disabled={isLoading || (!!error && error.length > 0)}
                            startIcon={isLoading && <CircularProgress size={18}/>}
                        >
                            Finish
                        </Button>
                    </DialogActions>
                </form>
            </TabPanel>
            <TabPanel value={ManageEntityTabValue.CREATE_EXCLUSIVE}>
                    <DialogContent dividers
                                style={{
                                    overflowY: 'hidden',
                                    maxWidth: '500px',
                                    width: '500px',
                                    minHeight: '400px'
                                }}>
                        <p className='dialog-label'>Create a local entity to add in <b>{manifest?.name}</b> manifest.</p>
                        <NewEntity
                            setNameInput={setNameInput}
                            autoFocus={tab === ManageEntityTabValue.CREATE_EXCLUSIVE}
                            entityName={exclusiveEntity.name}
                            setEntityName={entityName => setExclusiveEntity(new Entity(-1, entityName, '', CreateEntityType.EXCLUSIVE, manifest?.id))}
                            error={exclusiveError}
                            onSubmit={() => {
                                if (!isLoading)
                                    handleCreateExclusiveEntity();
                            }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={onClose}>
                            Close
                        </Button>
                        <Button
                            disableElevation
                            variant='contained'
                            color='secondary'
                            onClick={handleCreateExclusiveEntity}
                            disabled={isLoading || (!!exclusiveError && exclusiveError.length > 0)}
                            startIcon={isLoading && <CircularProgress size={18}/>}
                        >
                            Finish
                        </Button>
                    </DialogActions>
            </TabPanel>
        </TabContext>
    </Dialog>;
}

export default ManageEntityDialog;