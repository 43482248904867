import React, {FC} from "react";
import {ServiceContext} from "./ServiceContext";
import PackageService from "../../core/services/PackageService";
import {ManifestService} from "../../core/services/ManifestService";
import {EntityService} from "../../core/services/EntityService";
import {AdminReloadService} from "../../core/services/admin/AdminReloadService";
import {AdminOrganisationService} from "../../core/services/admin/AdminOrganisationService";
import { AdminEntityService } from "../../core/services/admin/AdminEntityService";
import { AdminManifestService } from "../../core/services/admin/AdminManifestService";
import { WorkspaceService } from "../../core/services/WorkspaceService";
import { NavigatorService } from "../../core/services/NavigatorService";

const ServiceProvider: FC = ({children}) => {
    const packageService = new PackageService();
    const manifestService = new ManifestService();
    const entityService = new EntityService();
    const adminReloadService = new AdminReloadService();
    const adminOrgService = new AdminOrganisationService();
    const adminEntityService = new AdminEntityService();
    const adminManifestService = new AdminManifestService();
    const navigatorService = new NavigatorService();
    const workspaceService = new WorkspaceService

    return <>
        <ServiceContext.Provider value={
            {
                manifestService, 
                entityService, 
                packageService, 
                adminReloadService, 
                adminOrgService, 
                adminEntityService,
                adminManifestService,
                navigatorService,
                workspaceService,
            }
        }>
            {children}
        </ServiceContext.Provider>
    </>
};

export default ServiceProvider;