import React, {useEffect, useState, FocusEventHandler, ChangeEventHandler, useMemo} from "react";
import Manifest, { ConnectionPoolType, MartiniProperties, ApiTypes, ApiSecurity, ConnectionPoolUrl, AggregateQueriesColumn, AggregateQueries, AggregateType, ManifestBuildProp } from "../../../core/observables/Manifest";
import { Skeleton, TabPanel, TabContext } from "@material-ui/lab";
import { Grid, Toolbar, Tooltip, IconButton, makeStyles, Theme, createStyles, Tabs, Tab, FormControlLabel, Radio, TextField, Button, Checkbox, FormControl, ButtonGroup, Table, TableHead, TableRow, TableCell, TableBody, CircularProgress, Divider, Typography } from "@material-ui/core";
import Select from "react-select";
import useService from "../../service/useService";
import { FormikErrors } from "formik";
import { NewAggregateQueryDialog } from "../../aggregate/AggregateQueryDialog";
import AsyncSelect from 'react-select/async'
import useContent from "../../content/useContent";
import useSession from "../../session/useSession";
import { NegroniIcon } from "../../icon/NegronIIcon";

const ManifestConfigurationStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            alignContent: 'end',
            marginTop: '5px',
            '& > *': {
                margin: theme.spacing(1),
            }
        },
        properties: {
            display: 'flex',
            '& > *': {
                margin: theme.spacing(1)
            },
        },
        toolBar: {
            minHeight: 36,
            paddingLeft: 0,
        },
        iconMiddleAlign: {
            verticalAlign : 'middle',
        },
        inputStyle: {
            width: '98%',
        },
        groupStyles: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        groupBadgeStyles: {
            backgroundColor: '#EBECF0',
            borderRadius: '2em',
            color: '#172B4D',
            display: 'inline-block',
            fontSize: 12,
            fontWeight: 'normal',
            lineHeight: '1',
            minWidth: 1,
            padding: '0.16666666666667em 0.5em',
            textAlign: 'center',
        },
        containerPadding: {
            padding: '10px'
        },
        selectStyle: {
            width: '100%'
        },
        tab: {
            background: 'green',
            '&.Mui-selected': {
              background: 'red'
            }
          },
    }),
);

interface ManifestConfigurationProps {
    manifest: Manifest;
    upsertConfiguration: (manifest: Manifest, configuration: MartiniProperties) => Promise<string | undefined>;
    values: MartiniProperties;
    setFieldValue?: (name: string, value: any, validate?: boolean) => void;
    handleBlur?: FocusEventHandler;
    handleChange?: ChangeEventHandler;
    errors?: FormikErrors<MartiniProperties>;
}

interface PathOption {
    label: string,
    operation: string,
    value: string,
}

enum TabValue {
    API_CONFIGURATION = 'API Configuration',
    SQL_CONFIGURATION = 'SQL Configuration',
    SERVICE_CONFIGURATION = 'Service Configuration',
    PROPERTY_CONFIGURATION = 'Property Configuration',
    AGGREGATE_QUERIES = 'Aggregate Queries',
}

enum ConnectionPoolTypeLabel {
    PostgreSQL = 'PostgreSQL',
    MySQL = 'MySQL',
    HSQL = 'Hyper SQL',
    MSSQL = 'Microsoft SQL Server',
    THIN = 'Oracle Thin',
    OCI8 = 'Oracle OCI 8',
    OCI9 = 'Oracle OCI 9+',
}

const connectionPoolTypeLabelToValue = (label: ConnectionPoolTypeLabel): ConnectionPoolType => {
    switch (label) {
        case ConnectionPoolTypeLabel.PostgreSQL:
            return ConnectionPoolType.PostgreSQL;
        case ConnectionPoolTypeLabel.MySQL:
            return ConnectionPoolType.MySQL;
        case ConnectionPoolTypeLabel.HSQL:
            return ConnectionPoolType.HSQL;
        case ConnectionPoolTypeLabel.MSSQL:
            return ConnectionPoolType.MSSQL;
        case ConnectionPoolTypeLabel.THIN:
            return ConnectionPoolType.THIN;
        case ConnectionPoolTypeLabel.OCI8:
            return ConnectionPoolType.OCI8;
        case ConnectionPoolTypeLabel.OCI9:
            return ConnectionPoolType.OCI9;
        default:
            return ConnectionPoolType.PostgreSQL;
    }
};

const connectionPoolTypeToLabel = (label: ConnectionPoolType): ConnectionPoolTypeLabel => {
    switch (label) {
        case ConnectionPoolType.PostgreSQL:
            return ConnectionPoolTypeLabel.PostgreSQL;
        case ConnectionPoolType.MySQL:
            return ConnectionPoolTypeLabel.MySQL;
        case ConnectionPoolType.HSQL:
            return ConnectionPoolTypeLabel.HSQL;
        case ConnectionPoolType.MSSQL:
            return ConnectionPoolTypeLabel.MSSQL;
        case ConnectionPoolType.THIN:
            return ConnectionPoolTypeLabel.THIN;
        case ConnectionPoolType.OCI8:
            return ConnectionPoolTypeLabel.OCI8;
        case ConnectionPoolType.OCI9:
            return ConnectionPoolTypeLabel.OCI9;
        default:
            return ConnectionPoolTypeLabel.PostgreSQL;
    }
};

const ManifestConfiguration: React.FC<ManifestConfigurationProps> = ({
    manifest,
    upsertConfiguration,
    values,
    setFieldValue,
    handleBlur,
    handleChange,
    errors,
}) => {
    const {entityService, manifestService} = useService();
    const classes = ManifestConfigurationStyles();
    const [tab, setTab] = useState<TabValue>(TabValue.API_CONFIGURATION);
    const [connectionTypeOptions, setConnectionTypeOptions] = useState<any>([]);
    const [validConnectionUrl, setValidConnectionUrl] = useState(false);
    const [recommendProperty, setRecommendProperty] = useState<string>('');
    const [openAggregateQueryDialog, setOpenAggregateQueryDialog] = useState(false);
    const [excludedPaths, setExcludedPaths] = useState<string[]>([]);
    const [selectedPath, setSelectedPath] = useState<any>();
    const [loadingManifestPath, setLoadingManifestPath] = useState(false);
    const [defaultAttributeOptions, setDefaultAttributeOptions] = useState<{ label: string, value: string }[]>([]);
    const [aggregateQueries, setAggregateQueries] = useState<AggregateQueries[]>(values?.configurationBuild?.aggregateQueries || [])
    const [selectedPaths, setSelectedPaths] = useState<string[]>([]);
    const {setDirty, dirty} = useContent();
    const [pathLoader, setPathLoader] = useState(false);
    const [manualChanges, setManualChanges] = useState(false);
    const [selectedAggregateQueries, setSelectedAggregateQueries] = useState<AggregateQueries[]>([]);
    const [entitiesList, setEntitiesList] = useState<string[]>([]);
    const session = useSession();
    const subscription = session.subscription();
    const limits = session.limits();

    useEffect(() => {
        const finalOpts = [] as any;
        Object.entries(ConnectionPoolTypeLabel)
            .filter(([type, value]) => !value.includes('Oracle') && (limits.allowedDb.includes('SQL') && (type === "MySQL" || type === "PostgreSQL")))
            .map(([_, value]) => (finalOpts.push({label: value, value, parent: null})));
        const oracleOption = {
            label: 'Oracle Database v12+',
            options: Object.entries(ConnectionPoolTypeLabel)
            .filter(([_, value]) => value.includes('Oracle'))
            .map(([_, value]) => ({label: value, value, parent: 'Oracle'}))
        }
        if(limits.allowedDb.includes('ORC')) finalOpts.push(oracleOption);
        setConnectionTypeOptions(finalOpts);

        manifestService.getManifestConfiguration(manifest.id)
            .then(data => {
                //Package Properties
                setFieldValue?.('packageProperties.customProperties', [...data.packageProperties.customProperties]);

                //Connection Pools
                setFieldValue?.('packageProperties.connectionPoolInfo.url', data.packageProperties.connectionPoolInfo.url || ConnectionPoolUrl[values.packageProperties.connectionPoolInfo.type || connectionTypeOptions[0]], true);
                setFieldValue?.('packageProperties.connectionPoolInfo.type', data.packageProperties.connectionPoolInfo.type || connectionTypeOptions[0], true);
                setFieldValue?.('packageProperties.connectionPoolInfo.databaseName', data.packageProperties.connectionPoolInfo.databaseName, true);
                setFieldValue?.('packageProperties.connectionPoolInfo.username', data.packageProperties.connectionPoolInfo.username, true);
                setFieldValue?.('packageProperties.connectionPoolInfo.password', data.packageProperties.connectionPoolInfo.password, true);

                //API Configuration
                setFieldValue?.('configurationBuild.apiConfiguration.type', data.configurationBuild.apiConfiguration.type, true);
                setFieldValue?.('configurationBuild.apiConfiguration.security', data.configurationBuild.apiConfiguration.security, true);
                setFieldValue?.('configurationBuild.apiConfiguration.excludeOperations', [...data.configurationBuild.apiConfiguration.excludeOperations]);
                setExcludedPaths(data.configurationBuild.apiConfiguration.excludeOperations)

                //SQL Configuration
                setFieldValue?.('configurationBuild.sqlConfiguration.applyAutoIncrement', data.configurationBuild.sqlConfiguration.applyAutoIncrement, true);
                setFieldValue?.('configurationBuild.sqlConfiguration.applyCoalesce', data.configurationBuild.sqlConfiguration.applyCoalesce, true);
                setFieldValue?.('configurationBuild.sqlConfiguration.applyJoin', data.configurationBuild.sqlConfiguration.applyJoin, true);
                setFieldValue?.('configurationBuild.sqlConfiguration.applyLimitOffset', data.configurationBuild.sqlConfiguration.applyLimitOffset, true);

                // Service Configuration
                setFieldValue?.('configurationBuild.serviceConfiguration.applyCustomField', data.configurationBuild.serviceConfiguration.applyCustomField, true);
                setFieldValue?.('configurationBuild.serviceConfiguration.applyMultiTenancy', data.configurationBuild.serviceConfiguration.applyMultiTenancy, true);
                setFieldValue?.('configurationBuild.serviceConfiguration.applySot', data.configurationBuild.serviceConfiguration.applySot, true);
                setEntitiesList(data.configurationBuild.serviceConfiguration.sotEntities)

                //Aggregate Queries
                setAggregateQueries(data.configurationBuild.aggregateQueries)
                setFieldValue?.('configurationBuild.aggregateQueries', [...data.configurationBuild.aggregateQueries]);
                setManualChanges(true);
            })
    }, [])

    useEffect(() => {
        if (values?.packageProperties.connectionPoolInfo.type) {
            setFieldValue?.('packageProperties.connectionPoolInfo.url', ConnectionPoolUrl[values.packageProperties.connectionPoolInfo.type], true);
        }
    }, [values?.packageProperties.connectionPoolInfo.type]);

    useEffect(() => {
        setFieldValue?.('configurationBuild.apiConfiguration.excludeOperations', [...excludedPaths], true);

        handleManifestOperationPaths()
            .then(setDefaultAttributeOptions)
    }, [excludedPaths]);

    useEffect(() => {
        setFieldValue?.('configurationBuild.aggregateQueries', [...aggregateQueries], true);
    }, [aggregateQueries]);

    useEffect(() => {
        switch(values?.packageProperties.connectionPoolInfo.type) {
            case ConnectionPoolType.HSQL:
                setValidConnectionUrl(values?.packageProperties.connectionPoolInfo.url.includes('sql.syntax_mys=true'));
                setRecommendProperty('sql.syntax_mys=true');
                break;
            case ConnectionPoolType.MySQL:
                setValidConnectionUrl(values?.packageProperties.connectionPoolInfo.url.includes(`sessionVariables=sql_mode='ANSI'`));
                setRecommendProperty("sessionVariables=sql_mode='ANSI'");
                break;
            default:
                setValidConnectionUrl(true);
                break;
        }
    }, [values?.packageProperties.connectionPoolInfo.url]);

    useEffect(() => {
        if(manualChanges)
            setDirty(true)
    }, [values])

    async function handleManifestOperationPaths() {
        const finalPaths: PathOption[] = [];
        const firstBatchOperation = ["GET", "POST", "PUT"];
        const secondBatchOperation = ["GET", "DELETE"];
        setLoadingManifestPath(true)
        setPathLoader(true)
        try {
            manifest.entityPaths.forEach(entity => {
                entityService.getEntityById(entity.id)
                    .then(entityResponse => {
                        firstBatchOperation.forEach( operation => {
                            if(!excludedPaths.includes(`${entity.name}.${operation}`.toLowerCase())) {
                                finalPaths.push({
                                    label: "/" + entity.name + ' - ' + operation,
                                    operation: operation,
                                    value: `${entity.name}.${operation}`.toLowerCase()
                                })
                            }
                        })
    
                        secondBatchOperation.forEach( operation => {
                            if(!excludedPaths.includes(`${entity.name}.${entityResponse.primaryKey}.${operation}`.toLowerCase())) {
                                finalPaths.push({
                                    label: '/' + entity.name + '/{' + entityResponse.primaryKey + '}' + ' - ' + operation,
                                    operation: operation,
                                    value: `${entity.name}.${entityResponse.primaryKey}.${operation}`.toLowerCase()
                                })
                            }
                        })
                        setPathLoader(false)
                        setLoadingManifestPath(false)
                    })
            })
            return finalPaths;
        } catch (_) {
            return [];
        }
    }

    const formatGroupLabel = (data: any) => (
        <div className={classes.groupStyles}>
            <h2>{data.label}</h2>
            <span className={classes.groupBadgeStyles}>{data.options.length}</span>
        </div>
    );

    const setKeyProp = (key: string, index: number) => {
        const props = values?.packageProperties.customProperties;
        props[index] = {name: key, value: props[index].value};
        setFieldValue?.('packageProperties.customProperties', [...props]);
    }

    const setValueProp = (value: string, index: number) => {
        const props = values?.packageProperties.customProperties;
        if (props) {
            props[index] = {name: props[index].name, value} as ManifestBuildProp;
            setFieldValue?.('packageProperties.customProperties', [...props]);
        }
    }

    const handleAddProperty = () => {
        if (values?.packageProperties?.customProperties) {
            setFieldValue?.('packageProperties.customProperties', [...values.packageProperties.customProperties, {
                name: '',
                value: ''
            }] as ManifestBuildProp[]);
        }
    }

    const handleRemoveProperty = (index: number) => {
        if (values?.packageProperties?.customProperties) {
            setFieldValue?.('packageProperties.customProperties', values.packageProperties.customProperties.filter((prop, i) => i !== index));
        }
    }

    const propFields = useMemo(() => {
        const props = values?.packageProperties?.customProperties;
        return props?.map((prop, index) => {
            return <div className={classes.properties} key={index}>
                <TextField
                    style={{flexGrow: 1}}
                    margin='normal'
                    label='Name'
                    value={prop.name}
                    onChange={e => setKeyProp(e.target.value, index)}
                />
                <TextField
                    style={{flexGrow: 1}}
                    margin='normal'
                    label='Value'
                    value={prop.value}
                    onChange={e => setValueProp(e.target.value, index)}
                />
                <div>
                    <ButtonGroup>
                        {(props.length - 1) === index && <Button onClick={handleAddProperty}>
                            <NegroniIcon color='action' iconClass="add-square-icon" />
                        </Button>}
                        {index !== 0 && <Button onClick={_ => handleRemoveProperty(index)}>
                            <NegroniIcon iconClass="delete-icon" />
                        </Button>}
                    </ButtonGroup>
                </div>
            </div>
        });
    }, [values?.packageProperties.customProperties]);

    const handleOpenAggregateQueryDialog = () => {
        setOpenAggregateQueryDialog(true);
    };

    const handleCloseAggregateQueryDialog = () => {
        setOpenAggregateQueryDialog(false);
    };

    const addExcludePath = () => {
        setExcludedPaths([...excludedPaths, selectedPath?.value])
    }

    const addAggregateQuery = (name: string, entity: string, column: AggregateQueriesColumn[]) => {
        setAggregateQueries([...aggregateQueries, {name: name, entity: entity, column: column}])
    }

    const isPathSelected = (path: string) => selectedPaths.indexOf(path) !== -1

    const formatPath = (path: string): string => {
        const splittedPath = path.split('.')

        return splittedPath.length === 3 ? `${splittedPath[2].toUpperCase()} - /${splittedPath[0]}/{${splittedPath[1]}}` : `${splittedPath[1].toUpperCase()} - /${splittedPath[0]}`
    }

    const handlePathClick = (path: string) => {
        const selectedIndex = selectedPaths.indexOf(path)
        let newSelected: string[] = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selectedPaths, path);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selectedPaths.slice(1));
        } else if (selectedIndex === selectedPaths.length - 1) {
            newSelected = newSelected.concat(selectedPaths.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selectedPaths.slice(0, selectedIndex),
                selectedPaths.slice(selectedIndex + 1),
            );
        }

        setSelectedPaths(newSelected);
    }

    const deleteSelectedPaths = (selectedPaths: string[]) => {
        selectedPaths.forEach(path => {
            const deleteIndex = excludedPaths.indexOf(path, 0);
            excludedPaths.splice(deleteIndex, 1);
        })
        setSelectedPaths([])
        setExcludedPaths([...excludedPaths])
    }

    const handlePathDelete = () => {
        if(selectedPaths.length > 0)
            deleteSelectedPaths(selectedPaths)
    }

    const isAggregateQuerySelected = (aggregateQuery: AggregateQueries) => selectedAggregateQueries.indexOf(aggregateQuery) !== -1

    const handleAggregateQueryClick = (aggregateQuery: AggregateQueries) => {
        const selectedIndex = selectedAggregateQueries.indexOf(aggregateQuery)
        let newSelected: AggregateQueries[] = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selectedAggregateQueries, aggregateQuery);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selectedAggregateQueries.slice(1));
        } else if (selectedIndex === selectedAggregateQueries.length - 1) {
            newSelected = newSelected.concat(selectedAggregateQueries.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selectedAggregateQueries.slice(0, selectedIndex),
                selectedAggregateQueries.slice(selectedIndex + 1),
            );
        }

        setSelectedAggregateQueries(newSelected);
    }

    const deleteSelectedAggregateQueries = (queries: AggregateQueries[]) => {
        queries.forEach(query => {
            const deleteIndex = aggregateQueries.indexOf(query, 0);
            aggregateQueries.splice(deleteIndex, 1);
        })
        setSelectedAggregateQueries([])
        setAggregateQueries([...aggregateQueries])
    }

    const handleAggregateDelete = () => {
        if(selectedAggregateQueries.length > 0)
            deleteSelectedAggregateQueries(selectedAggregateQueries)
    }

    const isExisting = (entityName: string) => entitiesList.indexOf(entityName) !== -1;

    function handleEntityClick(entityName: string) {
        const selectedIndex = entitiesList.indexOf(entityName)
        let newSelected: string[] = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(entitiesList, entityName);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(entitiesList.slice(1));
        } else if (selectedIndex === entitiesList.length - 1) {
            newSelected = newSelected.concat(entitiesList.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                entitiesList.slice(0, selectedIndex),
                entitiesList.slice(selectedIndex + 1),
            );
        }

        setEntitiesList(newSelected);
        setFieldValue?.('configurationBuild.serviceConfiguration.sotEntities', newSelected, true);
    }

    return <>
        <Grid container>
            <Grid item xs={12}>
                <Toolbar className={classes.toolBar}>
                    <Tabs
                        className={classes.tab}
                        value={tab}
                        onChange={(e, value) => {
                            setTab(value);
                        }}
                    >
                        <Tab value={TabValue.API_CONFIGURATION} label={<div><NegroniIcon iconClass="api-icon" /> API </div>} />
                        <Tab value={TabValue.SQL_CONFIGURATION} label={<div><NegroniIcon iconClass="storage-icon" /> SQL </div>} />
                        <Tab value={TabValue.SERVICE_CONFIGURATION} label={<div><NegroniIcon iconClass="miscellaneous-services-icon" /> Service </div>} />
                        <Tab value={TabValue.PROPERTY_CONFIGURATION} label={<div><NegroniIcon iconClass="data-object-icon" /> Properties </div>} />
                        <Tab value={TabValue.AGGREGATE_QUERIES} label={<div><NegroniIcon iconClass="query-stats-icon" /> Aggregate Queries </div>} />
                    </Tabs>
                </Toolbar>
            </Grid>
        </Grid>
        <Grid container>
            <Grid item xs={12}>
                <Toolbar className={classes.toolBar}>
                    <Tooltip title='Save'>
                        <IconButton
                            disabled={!dirty}
                            style={{color: !dirty ? 'var(--gray-color)' : 'var(--light-secondary-color)'}}
                            onClick={_ => {
                                upsertConfiguration(manifest, values)
                                    .then(_ => {
                                        setDirty(false)
                                    })
                            }}
                        >
                             <NegroniIcon iconClass="save-icon" />
                        </IconButton>
                    </Tooltip>
                    {tab === TabValue.AGGREGATE_QUERIES ? <Tooltip title='Add Aggregate Query'>
                        <IconButton
                            color='primary'
                            onClick={handleOpenAggregateQueryDialog}
                        >
                             <NegroniIcon iconClass="add-circle-icon" />
                        </IconButton>
                    </Tooltip> : <></>}
                </Toolbar>
            </Grid>
        </Grid>
        <TabContext value={tab}>
            <TabPanel value={TabValue.API_CONFIGURATION}>
                <h2 style={{margin: ' 5px'}}>API Type</h2>
                <Grid container className={classes.containerPadding}>
                    <Grid container>
                    <FormControlLabel
                        control={<Checkbox checked={values?.configurationBuild?.apiConfiguration?.type.includes(ApiTypes.REST) && limits.rs} 
                        onChange={(e) => {
                            const { checked } = e.target;
                            const { type } = values.configurationBuild.apiConfiguration;
                            
                            if (checked) {
                              type.push(ApiTypes.REST);
                            } else {
                              values.configurationBuild.apiConfiguration.type = type.filter(
                                (type) => type !== ApiTypes.REST
                              );
                            }
                            setFieldValue?.("configurationBuild.apiConfiguration.type", values.configurationBuild.apiConfiguration.type, true);
                            setDirty(true);
                          }}
                        inputProps={{'aria-label': 'Select REST API type'}}/>}
                        name='configurationBuild.apiConfiguration.type'
                        label="REST API"
                        disabled={!limits.rs}
                    />
                    </Grid>
                    <Grid container>
                    <FormControlLabel
                        control={<Checkbox checked={values?.configurationBuild?.apiConfiguration?.type.includes(ApiTypes.SOAP)}
                        onChange={(e) => {
                            const { checked } = e.target;
                            const { type } = values.configurationBuild.apiConfiguration;
                            
                            if (checked) {
                              type.push(ApiTypes.SOAP);
                            } else {
                              values.configurationBuild.apiConfiguration.type = type.filter(
                                (type) => type !== ApiTypes.SOAP
                              );
                            }
                            setFieldValue?.("configurationBuild.apiConfiguration.type", values.configurationBuild.apiConfiguration.type, true);
                            setDirty(true);
                          }}
                        name='configurationBuild.apiConfiguration.type'
                        inputProps={{'aria-label': 'Select SOAP API type'}}/>}
                        label="SOAP API"
                    />
                    </Grid>
                    <Grid container>
                    <FormControlLabel
                        control={<Checkbox checked={values?.configurationBuild?.apiConfiguration?.type.includes(ApiTypes.GRAPHQL)}
                        onChange={(e) => {
                            const { checked } = e.target;
                            const { type } = values.configurationBuild.apiConfiguration;
                            
                            if (checked) {
                              type.push(ApiTypes.GRAPHQL);
                            } else {
                              values.configurationBuild.apiConfiguration.type = type.filter(
                                (type) => type !== ApiTypes.GRAPHQL
                              );
                            }
                            setFieldValue?.("configurationBuild.apiConfiguration.type", values.configurationBuild.apiConfiguration.type, true);
                            setDirty(true);
                          }}
                        name='configurationBuild.apiConfiguration.type'
                        inputProps={{'aria-label': 'Select GRAPHQL API type'}}/>}
                        label="GRAPHQL API"
                        disabled={!limits.gqls}
                    />
                    </Grid>
                </Grid>
                <h2 style={{margin: ' 5px'}}>Security</h2>
                <Grid container className={classes.containerPadding}>
                    <Grid container>
                        <FormControlLabel
                            control={<Radio checked={values?.configurationBuild?.apiConfiguration?.security === ApiSecurity.NONE}
                            onChange={handleChange}
                            value={ApiSecurity.NONE}
                            name='configurationBuild.apiConfiguration.security'
                            inputProps={{'aria-label': 'Select NONE security type'}}/>}
                            label="None"
                        />
                    </Grid>
                    <Grid container>
                        <FormControlLabel
                            control={<Radio checked={values?.configurationBuild?.apiConfiguration?.security === ApiSecurity.BASIC} 
                            onChange={handleChange}
                            value={ApiSecurity.BASIC}
                            name='configurationBuild.apiConfiguration.security'
                            inputProps={{'aria-label': 'Select BASIC security type'}}/>}
                            label="Basic Authentication"
                        />
                    </Grid>
                    <Grid container>
                        <FormControlLabel
                            control={<Radio checked={values?.configurationBuild?.apiConfiguration?.security === ApiSecurity.OAUTH2} 
                            onChange={handleChange}
                            value={ApiSecurity.OAUTH2}
                            name='configurationBuild.apiConfiguration.security'
                            inputProps={{'aria-label': 'Select OAUTH security type'}}/>}
                            label="OAuth Authentication"
                        />
                    </Grid>
                </Grid>
                <Divider/>
                { pathLoader ? <Grid
                        container
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        style={{padding: '5px'}}
                    >
                        <CircularProgress size='3rem'/> 
                        <h3 style={{color: 'var(data-heading-color)'}}>Fetching Paths...</h3> 
                    </Grid> : <>
                    <h2 style={{margin: ' 5px'}}>Excluded API Operations</h2>
                    <Grid container className={classes.containerPadding}>
                        <Grid item xs={10}>
                            <FormControl fullWidth variant='filled'>
                                <AsyncSelect
                                    value={selectedPath ? {
                                        label: selectedPath?.label,
                                        value: selectedPath?.value
                                    } : undefined}
                                    onChange={(option: any) => setSelectedPath(option)}
                                    loadOptions={handleManifestOperationPaths}
                                    defaultOptions={defaultAttributeOptions}
                                    isLoading={loadingManifestPath}
                                    isDisabled={loadingManifestPath}
                                    placeholder='Operations'
                                    menuPortalTarget={document.body}
                                    styles={{menuPortal: base => ({...base, zIndex: 9999})}}
                                    menuPlacement='auto'
                                    maxMenuHeight={200}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={2}>
                            <Tooltip
                                title={'Exclude Operation'}
                            >
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    style={{marginLeft: '10px'}}
                                    onClick={addExcludePath}
                                >
                                     <NegroniIcon iconClass="close-icon" />
                                </Button>
                            </Tooltip>
                        </Grid>
                    </Grid>
                    {selectedPaths.length > 0 && <Toolbar className='table-toolbar' variant='dense'>
                            <Typography color="inherit" variant="subtitle1" component="div">
                                {selectedPaths.length} Selected
                            </Typography>
                            <Tooltip title="Delete">
                                <IconButton onClick={handlePathDelete} color="inherit">
                                    <NegroniIcon iconClass="delete-icon" color="#fff" />
                                </IconButton>
                            </Tooltip>
                        </Toolbar>}
                    {excludedPaths.length > 0 ? <Grid container className={classes.containerPadding}>
                        <Table size='small'>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{width: '5%'}}/>
                                    <TableCell style={{width: '95%'}}>Path</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {excludedPaths.map((paths, index) => (
                                    <TableRow
                                        onClick={_ => {
                                            handlePathClick(paths)
                                        }}
                                        hover
                                        key={index}
                                        style={{cursor: 'pointer'}}
                                        selected={!!selectedPaths.find(path => path === paths)}
                                    >
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                checked={isPathSelected(paths)}
                                                inputProps={{'aria-label': 'Select path'}}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Grid container direction="row" alignItems="center">
                                                {formatPath(paths)}
                                            </Grid>
                                        </TableCell>
                                    </TableRow>
                                ))}                          
                            </TableBody>
                        </Table>
                    </Grid> : <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        height: '100%',
                        padding: '10px'
                    }}>No excluded paths to display</div>}
                </> }
            </TabPanel>
            <TabPanel value={TabValue.SQL_CONFIGURATION}>
                <h2 style={{margin: ' 5px'}}>Connection Pool Configuration</h2>
                <Grid container className={classes.containerPadding}>
                    <Select
                        autoFocus
                        options={connectionTypeOptions}
                        placeholder='Connection Type'
                        id='packageProperties.connectionPoolInfo.type'
                        name='packageProperties.connectionPoolInfo.type'
                        value={values?.packageProperties.connectionPoolInfo.type && ({
                            label: connectionPoolTypeToLabel(values?.packageProperties.connectionPoolInfo.type),
                            value: connectionPoolTypeToLabel(values?.packageProperties.connectionPoolInfo.type),
                            parent: null,
                        })}
                        onChange={(option: any) => {
                            setFieldValue?.('packageProperties.connectionPoolInfo.type', connectionPoolTypeLabelToValue(option?.value), true);
                        }}
                        formatGroupLabel={formatGroupLabel}
                        formatOptionLabel={option => {
                            return <div style={{display: 'flex'}}>
                                {option?.parent !== null ? <div style={{paddingLeft: '10px'}}>
                                    {option.label}
                                </div> : <div>
                                    {option.label}
                                </div>}
                            </div>;
                        }}
                        menuPortalTarget={document.body}
                        styles={{menuPortal: base => ({...base, zIndex: 9999})}}
                        menuPlacement='auto'
                        maxMenuHeight={200}
                        className={classes.selectStyle}
                    />
                    <TextField
                        margin='dense'
                        label='Connection Url'
                        type='url'
                        name='packageProperties.connectionPoolInfo.url'
                        id='packageProperties.connectionPoolInfo.url'
                        className={classes.inputStyle}
                        value={values?.packageProperties.connectionPoolInfo.url}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={!!errors?.packageProperties?.connectionPoolInfo?.url}
                        helperText={errors?.packageProperties?.connectionPoolInfo?.url && errors?.packageProperties?.connectionPoolInfo?.url}
                    />
                    <TextField
                        margin='dense'
                        label='Connection Username'
                        name='packageProperties.connectionPoolInfo.username'
                        id='packageProperties.connectionPoolInfo.username'
                        className={classes.inputStyle}
                        value={values?.packageProperties.connectionPoolInfo.username}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={!!errors?.packageProperties?.connectionPoolInfo?.username}
                        helperText={errors?.packageProperties?.connectionPoolInfo?.username && errors?.packageProperties?.connectionPoolInfo?.username}
                    />
                    <TextField
                        margin='dense'
                        label='Connection Password'
                        type='password'
                        name='packageProperties.connectionPoolInfo.password'
                        id='packageProperties.connectionPoolInfo.password'
                        className={classes.inputStyle}
                        value={values?.packageProperties.connectionPoolInfo.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!errors?.packageProperties?.connectionPoolInfo?.password}
                        helperText={errors?.packageProperties?.connectionPoolInfo?.password && errors?.packageProperties?.connectionPoolInfo?.password}
                    />
                    <TextField
                        margin='dense'
                        label='Database Name'
                        name='packageProperties.connectionPoolInfo.databaseName'
                        id='packageProperties.connectionPoolInfo.databaseName'
                        className={classes.inputStyle}
                        value={values?.packageProperties.connectionPoolInfo.databaseName}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={!!errors?.packageProperties?.connectionPoolInfo?.databaseName}
                        helperText={errors?.packageProperties?.connectionPoolInfo?.databaseName && errors?.packageProperties?.connectionPoolInfo?.databaseName}
                    />
                </Grid>
                <h2 style={{margin: ' 5px'}}>Query Configuration</h2>
                <Grid container className={classes.containerPadding}>
                    <FormControlLabel
                        className={classes.inputStyle}
                        control={<Checkbox
                            checked={values?.configurationBuild?.sqlConfiguration?.applyAutoIncrement}
                            onChange={handleChange}
                            name="configurationBuild.sqlConfiguration.applyAutoIncrement"/>}
                        label="Apply Auto-Increment"
                    />
                    <FormControlLabel
                        className={classes.inputStyle}
                        control={<Checkbox
                            checked={values?.configurationBuild?.sqlConfiguration?.applyCoalesce}
                            onChange={handleChange}
                            name="configurationBuild.sqlConfiguration.applyCoalesce"/>}
                        label="Apply Coalesce"
                    />
                    <FormControlLabel
                        className={classes.inputStyle}
                        control={<Checkbox
                            checked={values?.configurationBuild?.sqlConfiguration?.applyLimitOffset}
                            onChange={handleChange}
                            name="configurationBuild.sqlConfiguration.applyLimitOffset"/>}
                        label="Apply Limit & Offset"
                    />
                    <FormControlLabel
                        className={classes.inputStyle}
                        control={<Checkbox
                            checked={values?.configurationBuild?.sqlConfiguration?.applyJoin}
                            onChange={handleChange}
                            name="configurationBuild.sqlConfiguration.applyJoin"/>}
                        label="Apply Relationship Join"
                    />
                </Grid>
            </TabPanel>
            <TabPanel value={TabValue.SERVICE_CONFIGURATION}>
                <h2 style={{margin: ' 5px'}}>Implementation Configuration</h2>
                <Grid container className={classes.containerPadding}>
                    <FormControlLabel
                        className={classes.inputStyle}
                        control={<Checkbox
                            checked={values?.configurationBuild?.serviceConfiguration?.applyMultiTenancy}
                            onChange={handleChange}
                            disabled={subscription !== "COMMERCIAL"}
                            name="configurationBuild.serviceConfiguration.applyMultiTenancy"/>}
                        label="Apply Multi-Tenancy"
                    />
                    <FormControlLabel
                        className={classes.inputStyle}
                        control={<Checkbox
                            checked={values?.configurationBuild?.serviceConfiguration?.applyCustomField}
                            onChange={handleChange}
                            disabled={subscription !== "COMMERCIAL"}
                            name="configurationBuild.serviceConfiguration.applyCustomField"/>}
                        label="Apply Custom Fields"
                    />
                    <FormControlLabel
                        className={classes.inputStyle}
                        control={<Checkbox
                            checked={values?.configurationBuild?.serviceConfiguration?.applySot}
                            onChange={handleChange}
                            disabled={subscription !== "COMMERCIAL"}
                            name="configurationBuild.serviceConfiguration.applySot"/>}
                        label="Apply Source of Truth Implementation"
                    />
                </Grid>
                {values?.configurationBuild?.serviceConfiguration?.applySot && <>
                    <h4 style={{margin: ' 5px'}}>Select Source of Truth Entities</h4>
                    {manifest.entityPaths.map(entity => (
                        <FormControlLabel
                            className={classes.inputStyle}
                            control={<Checkbox
                                checked={isExisting(entity.name)}
                                onClick={_ => {
                                    handleEntityClick(entity.name)
                                }}
                                name={"configurationBuild.serviceConfiguration.sotEntities"}/>}
                            label={entity.name}
                        />
                    ))}
                </>}
            </TabPanel>
            <TabPanel value={TabValue.PROPERTY_CONFIGURATION}>
                <Grid>
                    <h2 style={{margin: ' 5px'}}>Properties</h2>
                    {propFields}
                </Grid>
            </TabPanel>
            <TabPanel value={TabValue.AGGREGATE_QUERIES}>
                <h2 style={{margin: ' 5px'}}>Aggregate Queries</h2>
                {selectedAggregateQueries.length > 0 && <Toolbar className='table-toolbar' variant='dense'>
                    <Typography color="inherit" variant="subtitle1" component="div">
                        {selectedAggregateQueries.length} Selected
                    </Typography>
                    <Tooltip title="Delete">
                        <IconButton onClick={handleAggregateDelete} color="inherit">
                            <NegroniIcon iconClass="delete-icon" color="#fff" />
                        </IconButton>
                    </Tooltip>
                </Toolbar>}
                {aggregateQueries.length > 0 ? <Table size='small'>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{width: '5%'}}></TableCell>
                            <TableCell style={{width: '55%'}}>Name</TableCell>
                            <TableCell style={{width: '40%'}}>Associated Entity</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {aggregateQueries.map((aggregate, index) => (
                            <TableRow
                                onClick={_ => {
                                    handleAggregateQueryClick(aggregate)
                                }}
                                hover
                                key={index}
                                style={{cursor: 'pointer'}}
                                selected={!!selectedAggregateQueries.find(queries => queries === aggregate)}
                            >
                                <TableCell padding="checkbox">
                                    <Checkbox
                                        checked={isAggregateQuerySelected(aggregate)}
                                        inputProps={{'aria-label': 'Select aggregate query'}}
                                    />
                                </TableCell>
                                <TableCell>
                                    <Grid container direction="row" alignItems="center">
                                        {aggregate.name}
                                    </Grid>
                                </TableCell>
                                <TableCell>
                                    {aggregate.entity}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table> : <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '100%',
                    padding: '10px'
                }}>No aggregate queries to display</div>}
            </TabPanel>
            <NewAggregateQueryDialog
                manifest={manifest}
                open={openAggregateQueryDialog}
                onClose={handleCloseAggregateQueryDialog}
                onFinish={addAggregateQuery}
            />
        </TabContext>
    </>
}

export const ManifestConfigurationSkeleton = () => {
    return <div style={{padding: '10px'}}>
        <Skeleton animation='pulse' height={30} width={200}/>
        <Skeleton animation='pulse' height={60}/>
        <Skeleton animation='pulse' height={40} width={220}/>
        <div style={{display: 'flex'}}>
            <div style={{flexGrow: 1, paddingRight: '10px'}}>
                <Skeleton animation='pulse' height={20}/>
            </div>
            <div style={{flexGrow: 2, paddingRight: '10px'}}>
                <Skeleton animation='pulse' height={20}/>
            </div>
            <div style={{flexGrow: 1, paddingRight: '10px'}}>
                <Skeleton animation='pulse' height={20}/>
            </div>
            <div style={{flexGrow: 2}}>
                <Skeleton animation='pulse' height={20}/>
            </div>
        </div>
    </div>;
}

export default ManifestConfiguration;