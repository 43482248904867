import Manifest, {BuildManifest, CreateManifest, UpdateManifest, CreateManifestWizard, MartiniProperties} from "../observables/Manifest";
import {Filter} from "../Filter";
import DocumentItem from "../observables/DocumentItem";
import {CDMDocumentType} from "../observables/CDMDocument";
import {CDMManager} from "../CDMManager";
import HttpClient, {CancelToken} from "axios";
import PaginatedResponse from "./PaginatedResponse";
import config from "../../config";
import {rawPathToEntityPath} from "./EntityService";
import { ExportTypes } from "../../component/manifest/ExportManifestDialog";

export class ManifestService {

    readonly BASE = `/${config.api_version}/cdm/manifest`;

    deleteManifest(manifestId: number, cancelToken?: CancelToken) {
        return HttpClient.delete(`${this.BASE}/${manifestId}`, {cancelToken})
            .then(({data}) => data);
    }

    addEntity(manifestId: number, entityIds: number[], cancelToken?: CancelToken): Promise<Manifest> {
        return HttpClient.post(`${this.BASE}/${manifestId}/entities`, {entityIds}, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            cancelToken,
        }).then(({data}) => mapToManifest(manifestId, data.manifest));
    }

    deleteEntity(manifestId: number, entityId: number, cancelToken?: CancelToken) {
        return HttpClient.delete(`${this.BASE}/${manifestId}/entity/${entityId}`, {cancelToken})
            .then(({data}) => data);
    }

    deleteSubManifest(manifestId: number, subManifestId: number, cancelToken?: CancelToken) {
        return HttpClient.delete(`${this.BASE}/${manifestId}/submanifest/${subManifestId}`, {cancelToken})
            .then(({data}) => data);
    }

    createManifest(manifest: CreateManifest, cancelToken?: CancelToken): Promise<Manifest> {
        return HttpClient.post(this.BASE, manifest, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            cancelToken,
        }).then(({data}) => mapToManifest(data.manifest.id, data.manifest));
    }

    createManifestViaWizard(manifest: CreateManifestWizard, cancelToken?: CancelToken) {
        return HttpClient.post(`${this.BASE}/wizard`, manifest, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            cancelToken,
        }).then(({data}) => data);
    }

    addSubManifest(manifestId: number, subManifestId: number[], cancelToken?: CancelToken) {
        return HttpClient.put(`${this.BASE}/${manifestId}/subManifests`, {
            submanifests: subManifestId,
        }, {
            headers: {'Content-Type': 'application/json'},
            cancelToken,
        }).then(({data}) => mapToManifest(manifestId, data.manifest));
    }

    getParentManifests(subManifestId: number, limit: number = 1000, page: number = 0, cancelToken?: CancelToken) {
        return HttpClient.get(`${this.BASE}/${subManifestId}/parents`, {
            params: {
                limit,
                page,
            },
            headers: {'Content-Type': 'application/json'},
            cancelToken,
        }).then(({data}) => mapToDocumentItems(data.parentManifests));
    }

    getManifestById(id: number, cancelToken?: CancelToken): Promise<Manifest> {
        return HttpClient.get(`${this.BASE}/${id}`, {cancelToken})
            .then(({data}) => mapToManifest(id, data.manifest));
    }

    getManifestsByOrg(org: string, filter: Filter = Filter.ALL, exclude?: number, limit: number = 1000, page: number = 0, search: string = '', cancelToken?: CancelToken): Promise<PaginatedResponse<DocumentItem[]>> {
        return HttpClient.get('/api/manifests.json', {
            params: {
                filter,
                limit,
                page,
                search,
                exclude,
            },
            cancelToken,
        }).then(({data}) => ({
            totalElements: data.totalElements,
            size: data.size,
            page: data.page,
            status: data.result,
            message: data.message,
            result: data.manifests.map(rawPathToManifestPath),
        }));
    }

    getManifests(filter: Filter = Filter.ALL, exclude?: number, limit: number = 1000, page: number = 0, search: string = '', cancelToken?: CancelToken): Promise<PaginatedResponse<DocumentItem[]>> {
        return HttpClient.get(`${this.BASE}`, {
            params: {
                filter,
                limit,
                page,
                search,
                exclude,
            },
            cancelToken,
        }).then(({data}) => ({
            totalElements: data.totalElements,
            size: data.size,
            page: data.page,
            status: data.result,
            message: data.message,
            result: data.manifests.map(rawPathToManifestPath),
        }));
    }

    getFolders(limit: number = 20, page: number = 0, search: string = '', cancelToken?: CancelToken): Promise<PaginatedResponse<DocumentItem[]>> {
        return HttpClient.get(`${this.BASE}/folders`, {
            params: {
                limit,
                page,
                search,
            },
            cancelToken,
        }).then(({data}) => ({
            totalElements: data.totalElements,
            size: data.size,
            page: data.page,
            status: data.result,
            message: data.message,
            result: data.folders.map(mapToFolder),
        }));
    }

    getManifestsByFolder(manifestFolder: string, limit: number = 20, page: number = 0, search: string = '', cancelToken?: CancelToken): Promise<PaginatedResponse<DocumentItem[]>> {
        return HttpClient.get(`${this.BASE}/folders/${manifestFolder}`, {
            params: {
                limit,
                page,
                search,
            },
            cancelToken,
        }).then(({data}) => ({
            totalElements: data.totalElements,
            size: data.size,
            page: data.page,
            status: data.result,
            message: data.message,
            result: data.manifests.map(rawPathToManifestPath),
        }));
    }

    getEntitiesByManifest(manifestId: number | undefined, excludeEntityId: number | undefined, limit: number = 1000, page: number = 0, search: string = '', cancelToken?: CancelToken): Promise<PaginatedResponse<DocumentItem[]>> {
        return HttpClient.get(`${this.BASE}/${manifestId}/entities`, {
            params: {
                excludeEntityId,
                limit,
                page,
                search,
            },
            cancelToken,
        }).then(({data}) => ({
            totalElements: data.totalElements,
            size: data.size,
            page: data.page,
            status: data.result,
            message: data.message,
            result: data.entity.map(rawPathToEntityPath),
        }));
    }

    buildManifest(manifestId: number, buildManifest: BuildManifest, cancelToken?: CancelToken) {
        return HttpClient.post(`${this.BASE}/${manifestId}/build`, buildManifest, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            cancelToken,
        }).then(({data}) => data);
    }

    validateManifest(manifestId: number, cancelToken?: CancelToken) {
        return HttpClient.post(`${this.BASE}/${manifestId}/validate`, {}, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            cancelToken,
        }).then(({data}) => data);
    }

    exportManifestDatabaseStructure(manifestId: number, exportType: ExportTypes, databaseType: string = 'postgresql', applyAutoIncrement: boolean, cancelToken?: CancelToken) {
        return HttpClient.post(`${this.BASE}/${manifestId}/export`, {}, {
            params: {
                databaseType,
                exportType,
                applyAutoIncrement,
            },
            responseType: 'arraybuffer',
            cancelToken,
        }).then(({data}) => data);
    }

    importManifest(manifestFile: FormData, cancelToken?: CancelToken) {
        return HttpClient.post(`${this.BASE}/import`, manifestFile, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            cancelToken,
        }).then(({data}) => data);
    }

    getManifestConfiguration(manifestId: number, cancelToken?: CancelToken): Promise<MartiniProperties> {
        return HttpClient.get(`${this.BASE}/${manifestId}/configuration`, {cancelToken})
            .then(({data}) => rawManifestConfiguration(data?.manifestProperties));
    }

    createManifestConfiguration(manifestId: number, configuration: MartiniProperties, cancelToken?: CancelToken) {
        return HttpClient.post(`${this.BASE}/${manifestId}/configuration`, configuration, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            cancelToken
        }).then(({data}) => rawManifestConfiguration(data));
    }

    createManifestVersion(manifestId: number, version: string, cancelToken?: CancelToken): Promise<void> {
        return HttpClient.post(`${this.BASE}/${manifestId}/version`, {}, {
            params: {
                version,
            },
            cancelToken,
        }).then(({data}) => data);
    }

    getManifestVersion(id: number, version: string, cancelToken?: CancelToken) {
        return HttpClient.get(`${this.BASE}/version/content/${id}`, {
            params: {
                version
            },
            cancelToken
        }).then(({data}) => data);
    }

    getManifestVersionsById(id: number, cancelToken?: CancelToken) {
        return HttpClient.get(`${this.BASE}/${id}/version`, {
            cancelToken
        }).then(({data}) => data);
    }

    async deleteManifestVersion(manifestId: number, version: string,  cancelToken?: CancelToken) {
        try {
            await HttpClient.delete(`${this.BASE}/${manifestId}/version`, {
                params: {
                    version
                },
                cancelToken
            });
        }
        catch (e) {
            return console.error(e);
        }
    }
}

export const rawPathToManifestPath = (raw: any): DocumentItem => {
    return {
        name: raw.name,
        path: raw.path,
        documentType: CDMDocumentType.MANIFEST,
        id: raw?.id || -1,
        isDefault: raw.path.startsWith(CDMManager.PRE_DEFINED),
        type: 'documentItem',
    } as DocumentItem;
}

export const rawManifestConfiguration = (raw: any): MartiniProperties => {
    return {
        manifestName: raw?.manifestName,
        packageProperties: {
            connectionPoolInfo: {
                type: raw?.packageProperties?.connectionPoolInfo?.type,
                databaseName: raw?.packageProperties?.connectionPoolInfo?.databaseName,
                password: raw?.packageProperties?.connectionPoolInfo?.password,
                username: raw?.packageProperties?.connectionPoolInfo?.username,
                url: raw?.packageProperties?.connectionPoolInfo?.url,
            },
            customProperties: raw?.packageProperties?.customProperties,
        },
        configurationBuild: {
            apiConfiguration: {
                type: raw?.configurationBuild?.apiConfiguration?.type,
                security: raw?.configurationBuild?.apiConfiguration?.security,
                excludeOperations: raw?.configurationBuild?.apiConfiguration?.excludeOperations,
            },
            sqlConfiguration: {
                applyAutoIncrement: raw?.configurationBuild?.sqlConfiguration?.applyAutoIncrement,
                applyCoalesce: raw?.configurationBuild?.sqlConfiguration?.applyCoalesce,
                applyLimitOffset: raw?.configurationBuild?.sqlConfiguration?.applyLimitOffset,
                applyJoin: raw?.configurationBuild?.sqlConfiguration?.applyJoin,
            },
            serviceConfiguration: {
                applyMultiTenancy: raw?.configurationBuild?.serviceConfiguration?.applyMultiTenancy,
                applyCustomField: raw?.configurationBuild?.serviceConfiguration?.applyCustomField,
                applySot: raw?.configurationBuild?.serviceConfiguration?.applySot,
                sotEntities: raw?.configurationBuild?.serviceConfiguration?.sotEntities,
            },
            aggregateQueries: raw?.configurationBuild?.aggregateQueries,
        },
    } as MartiniProperties

    
}

export const mapToManifest = (id: number, raw: any, version?: string): Manifest => {
    const manifest = new Manifest(id, raw.name, raw.path);
    manifest.isDefault = raw.path.startsWith(CDMManager.PRE_DEFINED);
    manifest.latestVersion = raw?.latestVersion || 'None';
    manifest.currentVersion = version ? version : manifest.latestVersion;
    for (const entity of raw.entities) {
        manifest.addEntityPath({
            name: entity.name,
            isDefault: manifest.isDefault,
            id: entity?.id || -1,
            documentType: CDMDocumentType.ENTITY,
            parentId: manifest.id,
            type: 'documentItem',
        });
    }
    for (const subManifest of raw.subManifests) {
        manifest.addSubManifestPath({
            name: subManifest.name,
            isDefault: manifest.isDefault,
            id: subManifest.id,
            documentType: CDMDocumentType.SUB_MANIFEST,
            parentId: manifest.id,
            type: 'documentItem',
        });
    }
    return manifest;
}

export const mapToFolder = (raw: any) => {
    return {
        name: raw,
        documentType: CDMDocumentType.MANIFEST_FOLDER,
    }
}

export const mapToUpdateManifest = (manifest: Manifest): UpdateManifest => {
    return {
        subManifests: manifest.subManifestPaths.map(subManifest => subManifest.id),
        entities: manifest.entityPaths.map(entity => entity.id),
        importsPath: [],
    };
}

const mapToDocumentItems = (raw: any[]): DocumentItem[] => {
    return raw.map(({id, name}) => ({
        id,
        name,
        documentType: CDMDocumentType.MANIFEST,
        type: 'documentItem',
    }) as DocumentItem);
}