import React, {useCallback, useState} from "react";
import ConfirmContext from "./ConfirmContext";
import {ConfirmDialog, ConfirmDialogOptions} from "./ConfirmDialog";

interface ConfirmProviderProps {
    defaultOptions?: ConfirmDialogOptions;
}

const ConfirmProvider: React.FC<ConfirmProviderProps> = ({children, defaultOptions}) => {
    const [options, setOptions] = useState(defaultOptions);
    const [resolveReject, setResolveReject] = useState<((reason?: any) => void)[]>([]);
    const [resolve, reject] = resolveReject;

    const confirm = useCallback((option?: ConfirmDialogOptions) => {
        return new Promise((resolve, reject) => {
            setOptions(option);
            setResolveReject([resolve, reject]);
        });
    }, []);

    const handleClose = () => {
        setResolveReject([]);
    };

    const handleCancel = () => {
        reject?.();
        handleClose();
    };

    const handleOkay = () => {
        resolve?.();
        handleClose();
    };

    return <>
        <ConfirmContext.Provider value={confirm}>
            {children}
        </ConfirmContext.Provider>
        <ConfirmDialog
            options={options}
            open={resolveReject.length === 2}
            onClose={handleClose}
            onOkay={handleOkay}
            onCancel={handleCancel}
        />
    </>;
};

export default ConfirmProvider;