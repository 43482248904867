import React, {useEffect, useRef, useState} from "react";
import Select, {SingleValueProps} from "react-select";
import PaginatedResponse from "../../core/services/PaginatedResponse";
import {debounce} from "@material-ui/core";
import {FormatOptionLabelMeta} from "react-select/src/Select";
import {formatGroupLabel} from "react-select/src/builtins";
import axios, {CancelTokenSource} from "axios";

export interface PaginatedSelectProp {
    getOptions: (page: number, limit: number, search?: string, cancelTokenSource?: CancelTokenSource) => Promise<PaginatedResponse<SelectOption[] | SelectGroupOption[]>>;
    limit?: number;
    valueMapper: (value: any) => { label: string, value: any } | null;
    placeholder?: string;
    defaultValue?: any;
    onValueChanged: (value: any) => void;
    update?: number;
    formatOptionLabel?: (option: SelectOption, labelMeta: FormatOptionLabelMeta<SelectOption>) => React.ReactNode;
    formatGroupLabel?: formatGroupLabel<SelectOption>;
    isClearable?: boolean;
    autoFocus?: boolean;
    props?: SingleValueProps<any>;
    disableServerSearch?: boolean;
    hideFilter?: () => void;
    disabled?: boolean;
}

export interface SelectOption {
    label: string;
    value: any;
}

export interface SelectGroupOption {
    label: string;
    options: SelectOption[];
}

export const PaginatedSelect: React.FC<PaginatedSelectProp> = ({
    getOptions,
    limit,
    valueMapper,
    placeholder,
    defaultValue,
    onValueChanged,
    update,
    formatOptionLabel,
    formatGroupLabel,
    isClearable,
    autoFocus,
    props,
    disableServerSearch,
    hideFilter,
    disabled,
}) => {
    const [value, setValue] = useState(defaultValue);
    const [input, setInput] = useState('');
    const [totalElements, setTotalElements] = useState(0);
    const [options, setOptions] = useState<SelectOption[] | SelectGroupOption[]>([]);
    const [page, setPage] = useState(0);
    const NODES_PER_PAGE = limit || 20;
    const [loading, setLoading] = useState(false);
    const prevUpdate = useRef<number>();
    const prevInput = useRef<string>();
    const selectInput = useRef<Select>(null);
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        if (update !== prevUpdate.current) selectInput.current?.select.clearValue();
    }, [update]);

    useEffect(() => {
        if (isOpen) {
            setLoading(true);
            const cancelTokenSource = axios.CancelToken.source();
            getOptions(page, NODES_PER_PAGE, input, cancelTokenSource)
                .then(response => {
                    if (update !== prevUpdate.current || prevInput.current !== input) {
                        prevUpdate.current = update;
                        prevInput.current = input;
                        setOptions(response.result);
                        setPage(0);
                    } else {
                        const mergedOptions = mergeOptions(options, response.result);
                        setOptions(mergedOptions);
                    }
                    setTotalElements(response.totalElements);
                    setLoading(false);
                });
            return () => cancelTokenSource?.cancel();
        }
    }, [page, update, NODES_PER_PAGE, isOpen]);

    useEffect(() => {
        if (isOpen) {
            if(!disableServerSearch) {
                setLoading(true);
                const cancelTokenSource = axios.CancelToken.source();
                getOptions(page, NODES_PER_PAGE, input, cancelTokenSource)
                    .then(response => {
                        if (update !== prevUpdate.current || prevInput.current !== input) {
                            prevUpdate.current = update;
                            prevInput.current = input;
                            setOptions(response.result);
                            setPage(0);
                        } else {
                            const mergedOptions = mergeOptions(options, response.result);
                            setOptions(mergedOptions);
                        }
                        setTotalElements(response.totalElements);
                        setLoading(false);
                    });
                return () => cancelTokenSource?.cancel();
            }
        }
    }, [input]);

    function mergeOptions(currentOptions: SelectOption[] | SelectGroupOption[], newOptions: SelectOption[] | SelectGroupOption[]): SelectOption[] | SelectGroupOption[] {
        if (currentOptions.length === 0)
            return newOptions;
        if (newOptions.length === 0)
            return currentOptions;
        const firstCurrentOption = currentOptions[0];
        if (firstCurrentOption.hasOwnProperty('options')) {
            const lastCurrentOption = currentOptions[currentOptions.length - 1] as SelectGroupOption;
            const firstNewOption = newOptions[newOptions.length - 1] as SelectGroupOption;

            if (!firstNewOption.hasOwnProperty('options'))
                return currentOptions;

            for (const newOption of newOptions) {
                if (newOption.label === lastCurrentOption.label) {
                    lastCurrentOption.options = [...lastCurrentOption.options, ...(newOption as SelectGroupOption).options];
                } else {
                    (currentOptions as SelectGroupOption[]).push(newOption as SelectGroupOption);
                }
            }
        } else if (firstCurrentOption.hasOwnProperty('value')) {
            return [...(currentOptions as SelectOption[]), ...(newOptions as SelectOption[])];
        }
        return currentOptions;
    }

    function handleScrollBottom() {
        if (page < (totalElements / NODES_PER_PAGE))
            setPage(page + 1);
    }

    function handleValueChanged(option: any) {
        onValueChanged(option?.value);
        setValue(option?.value || null)
    }

    const handleInputChanged = debounce(input => setInput(input), 300);

    return <Select
        value={valueMapper(value)}
        onMenuOpen={() => {
            setIsOpen(true);
            if(hideFilter)
                hideFilter();
        }}
        onInputChange={handleInputChanged}
        onChange={handleValueChanged}
        onMenuScrollToBottom={handleScrollBottom}
        options={options}
        isDisabled={loading || disabled}
        isLoading={loading}
        isSearchable
        placeholder={placeholder}
        formatOptionLabel={formatOptionLabel}
        formatGroupLabel={formatGroupLabel}
        isClearable={isClearable}
        autoFocus={autoFocus}
        menuPortalTarget={document.body}
        styles={{menuPortal: base => ({...base, zIndex: 9999})}}
        menuPlacement='auto'
        maxMenuHeight={200}
        ref={selectInput}
        {...props}
    />;
}