import React, {useState, FC, useEffect} from "react";
import {OrgDocument} from "../admin/OrganizationNavigatorContentProvider";
import {Grid, Table, TableBody, TableCell, TableHead, TableRow, Paper, Tab} from "@material-ui/core";
import OrganisationViewMenu from "./OrganisationViewMenu"
import { TabContext, TabList, TabPanel, Skeleton } from "@material-ui/lab";
import { OrganisationPackageView } from "./OrganisationPackageView";
import Entity from "../../core/observables/Entity";
import Manifest from "../../core/observables/Manifest";
import useProperties from "../properties/useProperties";

export const OrganizationViewSkeleton = () => {
    return <div style={{padding: '10px'}}>
        <Skeleton animation='pulse' height={30} width={200}/>
        <Skeleton animation='pulse' height={60}/>
        <div style={{display: 'flex'}}>
            <div style={{flexGrow: 1, paddingRight: '10px'}}>
                <Skeleton animation='pulse' height={40} width={200}/>
            </div>
        </div>
        <div style={{display: 'flex'}}>
            <div style={{flexGrow: 1, paddingRight: '10px'}}>
                <Skeleton animation='pulse' height={20}/>
            </div>
            <div style={{flexGrow: 1, paddingLeft: '10px'}}>
                <Skeleton animation='pulse' height={20}/>
            </div>
        </div>
        <div style={{display: 'flex'}}>
            <div style={{flexGrow: 1, paddingRight: '10px'}}>
                <Skeleton animation='pulse' height={20}/>
            </div>
            <div style={{flexGrow: 1, paddingLeft: '10px'}}>
                <Skeleton animation='pulse' height={20}/>
            </div>
        </div>
        <div style={{display: 'flex'}}>
            <div style={{flexGrow: 1, paddingRight: '10px'}}>
                <Skeleton animation='pulse' height={20}/>
            </div>
            <div style={{flexGrow: 1, paddingLeft: '10px'}}>
                <Skeleton animation='pulse' height={20}/>
            </div>
        </div>
        <div style={{display: 'flex'}}>
            <div style={{flexGrow: 1, paddingRight: '10px'}}>
                <Skeleton animation='pulse' height={20}/>
            </div>
            <div style={{flexGrow: 1, paddingLeft: '10px'}}>
                <Skeleton animation='pulse' height={20}/>
            </div>
        </div>
    </div>;
}

interface OrganisationViewProps {
    org: OrgDocument;
    onCreateEntity: (orgCode: string, entity: Entity) => Promise<string | undefined>;
    onCreateManifest: (orgCode: string, manifest: Manifest) => Promise<string | undefined>;
}

enum TabValue {
    OVERVIEW = 'Overview',
    GENERATED_PACKAGES = 'Generated Packages',
}

const OrganisationView: FC<OrganisationViewProps> = (
    {
        org,
        onCreateEntity,
        onCreateManifest,
    }
) => {

    const [tab, setTab] = useState<TabValue>(TabValue.OVERVIEW);
    const {setProperty} = useProperties();

    useEffect(() => {
        setProperty(org);
    }, []);

    return <div>
        <Grid container>
            <Grid item xs={12} style={{display: 'flex', alignItems: 'center'}}>
                <h3 style={{margin: '5px', cursor: 'pointer', flexGrow: 1}}>[{org.code}] {org.name} </h3>
                <div style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    flexGrow: 1,
                }}>
                    <OrganisationViewMenu
                        orgCode={org.code}
                        orgName={org.name}
                        onCreateEntity={onCreateEntity}
                        onCreateManifest={onCreateManifest}
                    />
                </div>
            </Grid>
        </Grid>
        <TabContext value={tab}>
            <Paper elevation={0} square>
                <TabList
                    value={tab}
                    onChange={(e, value) => {
                        setTab(value);
                    }}
                    variant='fullWidth'
                >
                    {Object.values(TabValue)
                        .map((tab, index) => <Tab key={index} value={tab} label={tab}/>)}
                </TabList>
            </Paper>
            <TabPanel value={TabValue.OVERVIEW}>
                <Grid item xs={12}>
                    <Table size='small'>
                        <TableHead>
                            <TableRow>
                                <TableCell colSpan={2} color='action'>Summary</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow hover>
                                <TableCell>Entities</TableCell>
                                <TableCell>{org.totalEntities}</TableCell>
                            </TableRow>
                            <TableRow hover>
                                <TableCell>Manifests</TableCell>
                                <TableCell>{org.totalManifests}</TableCell>
                            </TableRow>
                            <TableRow hover>
                                <TableCell>Generated Packages</TableCell>
                                <TableCell>{org.totalPackages}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Grid>
            </TabPanel>
            <TabPanel value={TabValue.GENERATED_PACKAGES}>
                <OrganisationPackageView
                    organisationCode={org.code}
                />
            </TabPanel>
        </TabContext>
    </div>;
};

export default OrganisationView;