import React, {useEffect, useState} from "react";
import { Grid, TextField, Button, Checkbox, Box, Dialog, DialogTitle, DialogContent, DialogContentText, FormControl, DialogActions, Table, TableHead, TableRow, TableCell, TableBody, makeStyles, Theme, createStyles, Divider } from "@material-ui/core";
import Select from "react-select";
import AsyncSelect from 'react-select/async'
import { Add, Delete } from "@material-ui/icons";
import Manifest, { AggregateQueriesColumn, AggregateType, AggregateQueries } from "../../core/observables/Manifest";
import useService from "../service/useService";
import { AssociatedEntity } from "../../core/observables/EntityAttribute";
import PaginatedResponse from "../../core/services/PaginatedResponse";
import { SelectGroupOption, SelectOption, PaginatedSelect } from "../select/PaginatedSelect";

interface NewAggregateQueryDialogProp {
    manifest?: Manifest;
    open: boolean;
    onClose: () => void;
    onFinish: (name: string, entity: string, column: AggregateQueriesColumn[] ) => void;
    defaultAggregate?: AggregateQueries;
}

const AggregateQueryDialogStyles = makeStyles((theme: Theme) =>
    createStyles({
        groupStyles: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        groupBadgeStyles: {
            backgroundColor: '#EBECF0',
            borderRadius: '2em',
            color: '#172B4D',
            display: 'inline-block',
            fontSize: 12,
            fontWeight: 'normal',
            lineHeight: '1',
            minWidth: 1,
            padding: '0.16666666666667em 0.5em',
            textAlign: 'center',
        },
        selectStyle: {
            width: '100%'
        }
    }),
);

enum AggregateTypeLabel {
    COUNT = 'Count',
    SUM = 'Sum',
    MIN = 'Min',
    MAX = 'Max',
    AVG = 'Avg',
    GROUPBY = 'Group By',
}

const aggregateTypeToLabel = (label: AggregateType): AggregateTypeLabel => {
    switch (label) {
        case AggregateType.COUNT:
            return AggregateTypeLabel.COUNT;
        case AggregateType.SUM:
            return AggregateTypeLabel.SUM;
        case AggregateType.MIN:
            return AggregateTypeLabel.MIN;
        case AggregateType.MAX:
            return AggregateTypeLabel.MAX;
        case AggregateType.AVG:
            return AggregateTypeLabel.AVG;
        case AggregateType.GROUPBY:
            return AggregateTypeLabel.GROUPBY;
        default:
            return AggregateTypeLabel.COUNT;
    }
};

const aggregateTypeLabelToValue = (label: AggregateTypeLabel): AggregateType => {
    switch (label) {
        case AggregateTypeLabel.COUNT:
            return AggregateType.COUNT;
        case AggregateTypeLabel.SUM:
            return AggregateType.SUM;
        case AggregateTypeLabel.MIN:
            return AggregateType.MIN;
        case AggregateTypeLabel.MAX:
            return AggregateType.MAX;
        case AggregateTypeLabel.AVG:
            return AggregateType.AVG;
        case AggregateTypeLabel.GROUPBY:
            return AggregateType.GROUPBY;
        default:
            return AggregateType.COUNT;
    }
};

export const NewAggregateQueryDialog: React.FC<NewAggregateQueryDialogProp> = ({
    manifest,
    open,
    onClose,
    onFinish,
    defaultAggregate,
}) => {
    const {entityService, manifestService} = useService();
    const classes = AggregateQueryDialogStyles();
    const [name, setName] = useState('');
    const [nameInput, setNameInput] = useState<HTMLInputElement | undefined>();
    const [associatedEntity, setAssociatedEntity] = useState<AssociatedEntity | undefined>(undefined);
    const [entityName, setEntityName] = useState<string>('');
    const [targetEntityAttribute, setTargetEntityAttribute] = useState<string>('');
    const [alias, setAlias] = useState<string>('');
    const [defaultAttributeOptions, setDefaultAttributeOptions] = useState<{ label: string, value: string }[]>([]);
    const [loadingTargetEntityAttribute, setLoadingTargetEntityAttribute] = useState(false);
    const [updateAddEntity, setUpdateAddEntity] = useState(0);
    const [search, setSearch] = useState<string>('');
    const [aggregateTypeOptions, setAggregateTypeOptions] = useState<any>([]);
    const [aggregateType, setAggregateType] = useState<AggregateType>(AggregateType.COUNT);
    const [aggregateColumn, setAggregateColumn] = useState<AggregateQueriesColumn[]>([]);
    const [selectedAggregateColumns, setSelectedAggregateColumns] = useState<AggregateQueriesColumn[]>([]);

    useEffect(() => {
        const aggregateOpts = [] as any;
        Object.entries(AggregateTypeLabel)
            .map(([_, value]) => (aggregateOpts.push({label: value, value, parent: null})));

        setAggregateTypeOptions(aggregateOpts);

        if(defaultAggregate) {
            setName(defaultAggregate.name)
            setAggregateColumn(defaultAggregate.column)
        }
    }, [])

    useEffect(() => {
        if(open) {
            setName('')
            setAssociatedEntity(undefined)
            setEntityName('')
            setTargetEntityAttribute('')
            setAlias('')
            setDefaultAttributeOptions([])
            setLoadingTargetEntityAttribute(false)
            setSearch('')
            setAggregateType(AggregateType.COUNT)
            setAggregateColumn([])
        }
    }, [open])

    useEffect(() => {
        if(aggregateType && targetEntityAttribute) {
            setAlias(targetEntityAttribute + aggregateType);
        }
    }, [aggregateType, targetEntityAttribute])

    useEffect(() => {
        if(associatedEntity) {
            setEntityName(associatedEntity.name)
        }
    }, [associatedEntity])

    useEffect(() => {
        handleLoadTargetEntityAttributeOptions()
            .then(setDefaultAttributeOptions);
    }, [associatedEntity]);

    const handleInputChange = (value: React.SetStateAction<string>) => {
        setSearch(value);
    };

    const getEntitiesByManifest = (page: number = 0, limit: number = 20, search: string = ''): Promise<PaginatedResponse<SelectGroupOption[] | SelectOption[]>> => {
        return manifestService.getEntitiesByManifest(manifest?.id || -1, -1, limit, page, search)
            .then(response => {
                return {
                    ...response,
                    result: response.result.map(entity => ({label: entity.name, value: entity}))
                } as PaginatedResponse<SelectOption[]>;
            });
    };

    async function handleLoadTargetEntityAttributeOptions() {
        setTargetEntityAttribute("");
        setLoadingTargetEntityAttribute(true);
        if (!associatedEntity?.id)
            return [];
        try {
            const entity = await entityService.getEntityById(associatedEntity?.id);
            const attributes = entity.typeAttributes.filter(typeAttribute => typeAttribute.name.toLowerCase().includes(search.toLowerCase()))
                .map(typeAttribute => ({label: typeAttribute.name, value: typeAttribute.name}))
                .sort((a, b) => a.label.localeCompare(b.label));
            setLoadingTargetEntityAttribute(false);
            return attributes;
        } catch (_) {
            return [];
        }
    }

    const formatGroupLabel = (data: any) => (
        <div className={classes.groupStyles}>
            <h2>{data.label}</h2>
            <span className={classes.groupBadgeStyles}>{data.options.length}</span>
        </div>
    );

    const isAggregateColumnSelect = (aggregateColumn: AggregateQueriesColumn) => selectedAggregateColumns.indexOf(aggregateColumn) !== -1;

    const handleAggregateColumnClick = (aggregateColumn: AggregateQueriesColumn) => {
        const selectedIndex = selectedAggregateColumns.indexOf(aggregateColumn)
        let newSelected: AggregateQueriesColumn[] = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selectedAggregateColumns, aggregateColumn);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selectedAggregateColumns.slice(1));
        } else if (selectedIndex === selectedAggregateColumns.length - 1) {
            newSelected = newSelected.concat(selectedAggregateColumns.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selectedAggregateColumns.slice(0, selectedIndex),
                selectedAggregateColumns.slice(selectedIndex + 1),
            );
        }

        setSelectedAggregateColumns(newSelected);
    }

    const deleteSelectedAggregateColumns = (columns: AggregateQueriesColumn[]) => {
        columns.forEach(column => {
            const deleteIndex = aggregateColumn.indexOf(column, 0);
            aggregateColumn.splice(deleteIndex, 1);
        })
        setSelectedAggregateColumns([])
        setAggregateColumn([...aggregateColumn])
    }

    const handleDeleteAggregateColumns = () => {
        if(selectedAggregateColumns.length > 0)
            deleteSelectedAggregateColumns(selectedAggregateColumns)
    }

    return <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{overflowY: 'visible'}}
    >
        <DialogTitle id="alert-dialog-title">New Aggregate Query</DialogTitle>
        <DialogContent dividers style={{overflowY: 'hidden', maxWidth: '500px', width: '500px', minHeight: '500px'}}>
            <DialogContentText>
                Enter the name of your new aggregate query
            </DialogContentText>
            <Grid style={{marginBottom: '10px'}}>
                <TextField
                    inputRef={setNameInput}
                    autoFocus
                    margin="dense"
                    label="Aggregate Query Name"
                    fullWidth
                    variant='filled'
                    value={name}
                    onChange={e => setName(e.target.value)}
                />
            </Grid>
            <Grid style={{marginBottom: '10px'}}>
                <FormControl fullWidth variant='filled'>
                    <PaginatedSelect
                        getOptions={getEntitiesByManifest}
                        valueMapper={value => value ? ({label: value.name, value: value.value}) : null}
                        placeholder='Associated Entity'
                        onValueChanged={setAssociatedEntity}
                        update={updateAddEntity}
                        formatOptionLabel={option => {
                            return <div style={{display: 'flex', flexDirection: 'column'}}>
                                {option.label}
                            </div>;
                        }}
                        disabled={aggregateColumn.length > 0}
                    />
                </FormControl>
            </Grid>
            <Divider/>
            <Grid container style={{marginBottom: '10px', marginTop: '10px'}}>
                <Grid item sm={8}>
                    <DialogContentText>
                        Manage Aggregate Columns
                    </DialogContentText>
                </Grid>
                <Grid item sm={4}>
                    <Box display="flex" justifyContent="flex-end">
                        <Button
                            disabled={!!aggregateColumn.find(column => column.alias === alias)}
                            variant='contained'
                            color='secondary'
                            disableElevation
                            onClick={_ => {
                                setAggregateColumn([...aggregateColumn, { field: targetEntityAttribute, type: aggregateType, alias: alias }])
                            }}
                        >
                            <Add/>
                        </Button>
                        {selectedAggregateColumns.length > 0 && <Button
                            variant='contained'
                            color='secondary'
                            disableElevation
                            onClick={handleDeleteAggregateColumns}
                            style={{marginLeft: '5px'}}
                        >
                            <Delete/>
                        </Button>}
                    </Box>
                </Grid>
            </Grid>
            <Grid container >
                <Grid item xs={6} style={{paddingRight: '5px'}}>
                    <FormControl fullWidth variant='filled'>
                        <AsyncSelect
                            value={targetEntityAttribute ? {
                                label: targetEntityAttribute,
                                value: targetEntityAttribute
                            } : undefined}
                            onChange={(option: any) => setTargetEntityAttribute(option?.value)}
                            loadOptions={handleLoadTargetEntityAttributeOptions}
                            defaultOptions={defaultAttributeOptions}
                            isLoading={loadingTargetEntityAttribute}
                            isDisabled={loadingTargetEntityAttribute}
                            isSearchable
                            onInputChange={handleInputChange}
                            placeholder='Target Entity Attribute'
                            menuPortalTarget={document.body}
                            styles={{menuPortal: base => ({...base, zIndex: 9999})}}
                            menuPlacement='auto'
                            maxMenuHeight={200}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={6} style={{paddingLeft: '5px'}}>
                    <Select
                        options={aggregateTypeOptions}
                        placeholder='Aggregate Type'
                        value={aggregateType && ({
                            label: aggregateTypeToLabel(aggregateType),
                            value: aggregateTypeToLabel(aggregateType),
                            parent: null,
                        })}
                        onChange={(option: any) => {
                            setAggregateType(aggregateTypeLabelToValue(option?.value));
                        }}
                        formatGroupLabel={formatGroupLabel}
                        formatOptionLabel={option => {
                            return <div style={{display: 'flex'}}>
                                {option?.parent !== null ? <div style={{paddingLeft: '10px'}}>
                                    {option.label}
                                </div> : <div>
                                    {option.label}
                                </div>}
                            </div>;
                        }}
                        menuPortalTarget={document.body}
                        styles={{menuPortal: base => ({...base, zIndex: 9999})}}
                        menuPlacement='auto'
                        maxMenuHeight={200}
                        className={classes.selectStyle}
                    />
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12} style={{paddingRight: '5px'}}>
                    <TextField
                        inputRef={setNameInput}
                        margin="dense"
                        label="Alias"
                        fullWidth
                        variant='filled'
                        value={alias}
                        onChange={e => setAlias(e.target.value)}
                    />
                </Grid>
            </Grid>
            {aggregateColumn.length > 0 ? <Grid>
                <Table size='small'>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{width: '5%'}}/>
                            <TableCell style={{width: '50%'}}>Name</TableCell>
                            <TableCell style={{width: '45%'}}>Type</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {aggregateColumn.map((column, index) => (
                            <TableRow
                                onClick={_ => {
                                    handleAggregateColumnClick(column)
                                }}
                                hover
                                key={index}
                                style={{cursor: 'pointer'}}
                                selected={!!selectedAggregateColumns.find(selectedColumn => selectedColumn === column)}
                            >
                            <TableCell padding="checkbox">
                                <Checkbox
                                    checked={isAggregateColumnSelect(column)}
                                    inputProps={{'aria-label': 'Select aggregate column'}}
                                />
                            </TableCell>
                            <TableCell>
                                {column.field}
                            </TableCell>
                            <TableCell>
                                {column.type}
                            </TableCell>
                        </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Grid> :
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                height: '100%',
                padding: '10px'
            }}>No columns to display</div>}
        </DialogContent>
        <DialogActions>
            <Button onClick={onClose} style={{marginRight: 'auto'}}>
                Cancel
            </Button>
            <Button
                disabled={name === '' || entityName === '' || aggregateColumn.length === 0}
                variant='contained'
                color='secondary'
                disableElevation
                onClick={() => {
                    onFinish(name, entityName, aggregateColumn)
                    onClose()
                }}
            >
                Add
            </Button>
        </DialogActions>
    </Dialog>
}