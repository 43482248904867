import React, {CSSProperties, useEffect, useMemo, useState} from "react";
import {TreeItem, TreeView} from "@material-ui/lab";
import {createStyles, makeStyles} from "@material-ui/core/styles";
import {CircularProgress, Icon, Typography, Grid,} from "@material-ui/core";
import {observer} from "mobx-react";
import {uuid} from "uuidv4";
import isPromise from "is-promise";
import {NavigatorContentProvider, PaginatedChildren} from "./NavigatorContentProvider";
import {ChevronRight, ExpandMore} from "@material-ui/icons";
import NavigatorContextMenuProvider from "./NavigatorContextMenuProvider";
import ContextMenu, {Position} from "../ContextMenu";
import axios, {CancelTokenSource} from "axios";
import {debounce} from "lodash";
import { TextHighlighter } from "../text_highlighter/TextHighlighter";
import { NegroniIcon } from "../icon/NegronIIcon";

const NODES_PER_PAGE = 20;

interface NavigatorProps {
    input: any;
    onSelect: (node: any) => void;
    search: string;
    contentProvider: NavigatorContentProvider;
    contextMenuProvider?: NavigatorContextMenuProvider;
    update?: number;
    defaultSelected?: any;
    expanded: string[];
    setExpanded: (expanded: string[]) => void;
    isLoading?: boolean;
    isLoadingText?: string;
}

const NavigatorStyles = makeStyles(theme => createStyles({
    root: {
        '&:hover > $content': {
            backgroundColor: theme.palette.action.hover,
        },
    },
    content: {
        borderTopRightRadius: theme.spacing(2),
        borderBottomRightRadius: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    label: {
        fontWeight: 'inherit',
        color: 'inherit',
    },
    labelRoot: {
        display: 'flex',
        alignItems: 'flex-start',
        padding: theme.spacing(0.5, 0),
        userSelect: 'none',
    },
    labelIcon: {
        marginRight: theme.spacing(1),
    },
    labelText: {
        fontWeight: 'inherit',
        flexGrow: 1,
        lineHeight: 1.5,
    },
    treeWidth: {
        width: '100vw',
    }
}));

const Navigator: React.FC<NavigatorProps> = ({
    input,
    contentProvider,
    onSelect,
    contextMenuProvider,
    search,
    update,
    defaultSelected,
    expanded,
    setExpanded,
    isLoading,
    isLoadingText,
}) => {
    const classes = NavigatorStyles();
    const [selected, setSelected] = useState<string>('');
    const [contextMenuPosition, setContextMenuPosition] = useState<Position | null>(null);
    const [contextMenuNode, setContextMenuNode] = useState();

    useEffect(() => {
        if (defaultSelected) {
            setExpanded(extractExpanded(contentProvider.getId(defaultSelected)));
            setSelected(contentProvider.getId(defaultSelected) || '');
        }
    }, [defaultSelected]);

    const extractExpanded = (id: string) => {
        const expanded = [];
        const path = id.split('~');
        for (let i = 1; i <= path.length; i++) {
            expanded.push(path.slice(0, i).join('~'));
        }
        return expanded;
    }

    const handleCloseContextMenu = () => {
        setContextMenuPosition(null);
    }

    const handleContextMenu = (() => {
        const debounced = debounce((event: React.MouseEvent, node: any) => {
            const position = {mouseX: event.clientX, mouseY: event.clientY};
            if (contextMenuProvider?.supported(node)) {
                setContextMenuNode(node);
                setContextMenuPosition(position);
            }
        }, 300, {leading: true, trailing: false});

        return (event: React.MouseEvent, node: any) => {
            event.persist();
            event.preventDefault();
            debounced(event, node);
        }
    })();

    return <div className={classes.treeWidth}>
        <TreeView
            defaultCollapseIcon={<ExpandMore/>}
            defaultExpandIcon={<ChevronRight/>}
            expanded={expanded}
            selected={selected}
            onNodeToggle={(_, nodes) => setExpanded(nodes)}
            onNodeSelect={(_: any, nodes: string) => setSelected(nodes)}
            style={{flex: '1', overflow: 'auto'}}
        >
            {isLoading && <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
            >
                <Grid item>
                    <CircularProgress style={{marginTop: '10px'}} size='36px' />
                </Grid>
                <Grid item>
                    <h3 style={{color: 'var(--data-heading-color)'}}>{isLoadingText}</h3>
                </Grid>
            </Grid>}
            <TreeNodes
                contentProvider={contentProvider}
                search={search}
                onSelect={onSelect}
                node={input}
                update={update}
                onContextMenu={handleContextMenu}
            />
        </TreeView>
        <ContextMenu
            handleClose={handleCloseContextMenu}
            position={contextMenuPosition}
            children={contextMenuProvider?.supported(contextMenuNode) && contextMenuProvider.getContextMenu(contextMenuNode, handleCloseContextMenu)}
        />
    </div>;
};

const TreeNodes: React.FC<{
    node: any;
    contentProvider: NavigatorContentProvider;
    search: string;
    onSelect: (node: any) => void;
    update?: number;
    onContextMenu?: (e: React.MouseEvent, node: any) => void;
}> = ({contentProvider, search, onSelect, node, update, onContextMenu}) => {
    const [filteredNodes, setFilteredNodes] = useState<any[]>([]);
    const [page, setPage] = useState(0);
    const [previousPage, setPreviousPage] = useState(0);
    const [totalElements, setTotalElements] = useState(0);
    const [loading, setLoading] = useState(false);
    const [currentSearch, setCurrentSearch] = useState<string>('');

    const memoFilteredNodes = useMemo(() => {
        const paginatedNodes = filteredNodes
            .map((n) => (<StyledTreeItem
                key={contentProvider.getId(n)}
                label={contentProvider.getLabel(n)}
                icon={contentProvider.getIcon(n)}
                onSelect={_ => onSelect(n)}
                id={contentProvider.getId(n)}
                path={contentProvider.getPath(n)}
                onContextMenu={e => onContextMenu?.(e, n)}
                search={search}
            >
                {((contentProvider.supported(n) && contentProvider.hasChildren(n)) || contentProvider.isPaginated(n)) &&
                <TreeNodes
                    onSelect={onSelect}
                    search={search}
                    contentProvider={contentProvider}
                    node={n}
                    update={update}
                    onContextMenu={onContextMenu}
                />}
            </StyledTreeItem>));
        if ((totalElements / NODES_PER_PAGE) > (page + 1) && !Array.isArray(node)) {
            paginatedNodes.push(<StyledTreeItem
                key={uuid()}
                label='Load More'
                loading={loading}
                icon='more'
                onSelect={_ => setPage(page + 1)}
                id={uuid()}
                labelStyles={{color: 'var(--primary-color)'}}
                search={search}
            />);
        } else if (loading) {
            paginatedNodes.push(<StyledTreeItem
                loading={loading}
                key={uuid()}
                label='Loading'
                icon=''
                onSelect={_ => {
                }}
                id={uuid()}
                labelStyles={{color: 'var(--primary-color)'}}
                search={search}
            />);
        }
        return paginatedNodes;
    }, [filteredNodes, loading]);

    useEffect(() => {
        if (currentSearch !== search)
            setCurrentSearch(search);
    }, [search]);

    useEffect(() => {
        setLoading(true);
        const sourceCancelToken = axios.CancelToken.source();
        loadNodes(node, page, NODES_PER_PAGE, currentSearch, contentProvider, sourceCancelToken, nodes => {
            if (Array.isArray(nodes)) {
                visitNodes(nodes, node);
                setTotalElements(nodes.length);
                setFilteredNodes(nodes);
            } else if (nodes.totalElements >= 0 && nodes.children) {
                setTotalElements(nodes.totalElements);
                visitNodes(nodes.children, node);
                if (page !== previousPage) {
                    setFilteredNodes([...filteredNodes, ...nodes.children]);
                    setPreviousPage(page);
                } else {
                    setFilteredNodes(nodes.children);
                }
            }
            setLoading(false);
        });
        return () => sourceCancelToken.cancel(); // Cancel it silently
    }, [node, page, update, currentSearch]);

    return <>{memoFilteredNodes}</>
};

export const loadNodes = (node: any, page: number, limit: number, search: string, contentProvider: NavigatorContentProvider, sourceCancelToken: CancelTokenSource, load: (nodes: any[] | PaginatedChildren) => void) => {
    if (contentProvider.isPaginated(node)) {
        const children = contentProvider.paginatedChildren(node, page, limit, search, sourceCancelToken);
        children?.then(load);
    } else if (contentProvider.supported(node) && contentProvider.hasChildren(node)) {
        const nodes = contentProvider.children(node, page, limit, search, sourceCancelToken);

        if (Array.isArray(nodes)) {
            load(nodes);
        } else if (isPromise(nodes)) {
            nodes.then(load);
        } else {
            load([]);
        }
    } else {
        load([]);
    }
}

interface StyledTreeItemProps {
    id: string;
    label: string;
    icon?: string;
    path?: string;
    children?: React.ReactNode;
    onSelect: (e: React.MouseEvent<Element>) => void;
    labelStyles?: CSSProperties;
    loading?: boolean;
    onContextMenu?: (e: React.MouseEvent) => void;
    search: string;
}

export const StyledTreeItem: React.FC<StyledTreeItemProps> = ({
    id,
    label,
    icon,
    path,
    onSelect,
    children,
    labelStyles,
    loading,
    onContextMenu,
    search
}) => {
    const classes = NavigatorStyles();
    return <TreeItem
        nodeId={id}
        onLabelClick={onSelect}
        onContextMenu={onContextMenu}
        label={
            <div className={classes.labelRoot}>
                {loading ? <span style={labelStyles}>
                        <CircularProgress size='12px'/> Fetching...
                    </span> :
                    <div style={{display: 'flex', width: '100%'}} title={path}>
                        {icon && <NegroniIcon iconClass={icon} extraClass="negroni-tree-icon" />}
                        <Typography variant="body2" style={labelStyles} className={classes.labelText}>
                            <TextHighlighter search={search}>{label}</TextHighlighter>
                        </Typography>
                    </div>}
            </div>
        }
        children={children}
    />
}

const visitNodes = (nodes: any[], parent: any) => {
    for (const node of nodes)
        node.parent = parent;
};

export class MockDataItem {
    type = 'mockDataItem';
    children: any[];
    label: string;
    icon: string;
    id: string;
    parent: any;

    constructor(id: string, label: string, icon: string, children: any[] = [], parent?: any) {
        this.id = id;
        this.label = label;
        this.icon = icon;
        this.children = children;
        this.parent = parent;
        children.forEach(item => item.parent = this);
    }

    get name() {
        return this.label;
    }
}

export class PaginatedMockItem {
    type = 'paginatedMockItem';
    paginatedChildren: (page: number, limit: number, search: string) => Promise<PaginatedChildren>;
    label: string;
    icon: string;
    id: string;
    parent: any;

    constructor(id: string, label: string, icon: string, paginatedChildren: (page: number, limit: number, search: string) => Promise<PaginatedChildren>, parent?: any) {
        this.id = id;
        this.label = label;
        this.icon = icon;
        this.paginatedChildren = paginatedChildren;
        this.parent = parent;
    }

    get name() {
        return this.label;
    }
}

export class PaginatedMockItemTree {
    type = 'paginatedMockItem';
    label: string;
    parent: any;

    constructor(label: string, parent: any = undefined) {
        this.label = label;
        this.parent = parent;
    }

    get name() {
        return this.label;
    }
}

export class MockDataItemTree {
    type = 'mockDataItem';
    label: string;
    parent: any;

    constructor(label: string, parent: any = undefined) {
        this.label = label;
        this.parent = parent;
    }

    get name() {
        return this.label;
    }
}

export default observer(Navigator);