import {PropertySourceProvider} from "./PropertySourceProvider";
import {PropertySource} from "./PropertyDescriptor";
import {CDMDocumentType} from "../../core/observables/CDMDocument";
import Manifest, {ManifestPropertySource} from "../../core/observables/Manifest";
import {ManifestService} from "../../core/services/ManifestService";

export default class ManifestPropertySourceProvider implements PropertySourceProvider {
    private readonly manifestService: ManifestService;

    constructor(manifestService: ManifestService) {
        this.manifestService = manifestService;
    }

    getPropertySource(property: any): PropertySource | null {
        return new ManifestPropertySource(property as Manifest, this.manifestService);
    }

    support(property: any): boolean {
        return property?.documentType === CDMDocumentType.MANIFEST;
    }

}