export enum DataType {
    // ANY = 'any',
    // ATTRIBUTE = 'attribute',
    // ATTRIBUTE_GROUP = 'attributeGroup',
    // ATTRIBUTE_NAME = 'attributeName',
    // BIG_INTEGER = 'bigInteger',
    // BINARY = 'binary',
    BOOLEAN = 'boolean',
    // BYTE = 'byte',
    // CDM_OBJECT = 'cdmObject',
    // CHAR = 'char',
    DATE_TIME = 'dateTime',
    // DATA_TYPE = 'dataType',
    // DECIMAL = 'decimal',
    DOUBLE = 'double',
    // ENTITY = 'entity',
    // ENTITY_ID = 'entityId',
    // ENTITY_NAME = 'entityName',
    FLOAT = 'float',
    // GUID = 'guid',
    INTEGER = 'integer',
    // LIST = 'list',
    // LIST_LOOKUP = 'listLookup',
    // LIST_LOOKUP_CORRELATED = 'listLookupCorrelated',
    // LIST_LOOKUP_MULTIPLE = 'listLookupMultiple',
    // LIST_LOOKUP_STRING = 'listLookupString',
    OBJECT = 'object',
    // PURPOSE = 'purpose',
    // SMALL_INTEGER = 'smallInteger',
    STRING = 'string'
    // TRAIT = 'trait',
}