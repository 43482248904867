import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  MenuItem,
} from "@material-ui/core";
import React, { useState } from "react";
import { NegroniIcon } from "../icon/NegronIIcon";
import Manifest from "../../core/observables/Manifest";
import ContextMenu, { Position } from "../ContextMenu";
import { ManifestService } from "../../core/services/ManifestService";

interface ManageManifestVersionDialogProps {
  manifest: Manifest;
  open: boolean;
  onClose: () => void;
  onFinish: (version: string) => Promise<string | undefined>;
  manifestService: ManifestService;
  versions: any[];
}

export const ManageManifestVersionDialog: React.FC<
  ManageManifestVersionDialogProps
> = ({ manifest, open, onClose, onFinish, versions, manifestService }) => {
  const [loading, setLoading] = useState(false);
  const [version, setVersion] = useState<string>("1.0.0");
  const [error, setError] = useState<string | null>();
  const [position, setPosition] = useState<Position | null>(null);
  const [selection, setSelection] = useState<any | null>(null);

  const handleFinish = () => {
    setLoading(true);
    setError(null);
    onFinish(version)
      .then((error: string | undefined) => {
        setLoading(false);
        if (error && error.length > 0) {
          setError(error);
        } else {
          onClose();
        }
      })
      .catch((error) => setError(error));
  };

  function handleContextMenu(
    e: React.MouseEvent<HTMLTableRowElement>,
    typeAttribute: any
  ) {
    setSelection(typeAttribute);

    e.preventDefault();
    setPosition({
      mouseX: e.clientX,
      mouseY: e.clientY,
    });
  }

  function handleCloseContextMenu() {
    setPosition(null);
  }

  async function handleDeleteTypeAttribute() {
    const deleteIndex = versions.findIndex(v => v.version === selection.version);
    versions.splice(deleteIndex, 1);

   await manifestService.deleteManifestVersion(manifest.id, selection.version);
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="form-dialog-title">Manage Versions</DialogTitle>
      <DialogContent dividers style={{ minHeight: "350px", minWidth: "550px" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "1rem",
          }}
        >
          <div style={{ width: "100%" }}>
            <TextField
              label="Manifest Version"
              value={version}
              onChange={(e) => {
                setError(null);
                setVersion(e.target.value);
              }}
              style={{ margin: "5px", width: "98%" }}
            />
            <div
              className="invalid-feedback"
              hidden={!error || error.length === 0}
            >
              {error}
            </div>
          </div>
          <Button
            variant="contained"
            color="secondary"
            style={{ width: "40px", height: "40px" }}
            onClick={handleFinish}
            startIcon={loading && <CircularProgress size={18} />}
          >
            <NegroniIcon
              iconClass="add-square-icon"
              color="#ddd1f0"
              extraClass="negroni-menu-icon"
            />
          </Button>
        </div>
        <TableContainer style={{ maxHeight: 300, overflow: "auto" }}>
          <Table size="small" style={{ wordBreak: "break-word" }} stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell style={{ width: "50%" }}>Version</TableCell>
                <TableCell style={{ width: "25%" }} align="center">
                  Entities
                </TableCell>
                <TableCell style={{ width: "25%" }} align="center">
                  Sub-Manifests
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {versions.map((version, index) => (
                <TableRow
                  onClick={(_) => {}}
                  hover
                  key={index}
                  onContextMenu={(e) => handleContextMenu(e, version)}
                  style={{ cursor: "pointer" }}
                >
                  <TableCell>{version.version}</TableCell>
                  <TableCell align="center">{version.entities}</TableCell>
                  <TableCell align="center">{version.subManifests}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <ContextMenu handleClose={handleCloseContextMenu} position={position}>
          <MenuItem
            disabled={selection === null}
            dense
            onClick={async (_) => {
              handleCloseContextMenu();
              await handleDeleteTypeAttribute();
            }}
          >
            <NegroniIcon iconClass="delete-icon" /> Delete
          </MenuItem>
        </ContextMenu>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ManageManifestVersionDialog;
