import React from "react";

interface NegroniIconProps {
  iconClass: string;
  size?: "small" | "large";
  color?: string;
  extraClass?: string;
}

export const NegroniIcon: React.FC<NegroniIconProps> = ({
  iconClass,
  size,
  color,
  extraClass,
}) => {
  const sizeClass = size ? `negroni-icon-${size}` : "";

  return (
    <i
      className={`negroni-icon negroni-${iconClass} ${sizeClass} ${extraClass}`}
      style={{
        color: `${color}`,
      }}
    />
  );
};
