import React, {useEffect, useRef, useState} from "react";
import {observer} from "mobx-react";
import Package from "../../core/observables/Package";
import useQuery from "../router/useQuery";
import useService from "../service/useService"
import axios, {CancelTokenSource} from "axios";
import { PackageCards } from "../package/PackageCards";
import { LoadingProgress } from "../LoadingProgress";
import { makeStyles } from "@material-ui/core";
import { UploadPackage } from "../../core/observables/Instance";

interface OrganisationPackageViewProps {
    organisationCode: string,
}

const packageStyles = makeStyles({
    content: {
        margin: '10px',
        marginTop: '74px',
        height: 'calc(100% - 74px)',
    }
});

export const OrganisationPackageView: React.FC<OrganisationPackageViewProps> = (
    {
        organisationCode,
    }
) => {
    const classes = packageStyles();
    const [packages, setPackages] = useState<Package[]>([]);
    const {adminOrgService, workspaceService} = useService();
    const query = useQuery();
    const [isLoading, setIsLoading] = useState(false);
    const loadPackagesToken = useRef<CancelTokenSource>();

    useEffect(() => {
        const cancelTokenSource = loadPackages();
        return () => cancelTokenSource.cancel();
    }, []);

    const loadPackages = (): CancelTokenSource => {
        setIsLoading(true);
        const cancelTokenSource = axios.CancelToken.source();
        adminOrgService.getPackagesByOrg(organisationCode, cancelTokenSource.token)
            .then(data => {
                setPackages(data)
                setIsLoading(false);
            });
        return cancelTokenSource;
    }

    const handlePackageStatusChanged = () => {
        if (loadPackagesToken.current) loadPackagesToken.current?.cancel();
        loadPackagesToken.current = loadPackages();
    }

    const handleUploadPackage = (packageId: number, uploadPackage: UploadPackage): Promise<string | undefined> => {
        return workspaceService.uploadPackage(packageId, uploadPackage)
            .then(response => {
                return response.result;
            })
            .catch(error => error || 'Failed to upload package');
    }

    return <div className={classes.content}>
        {packages.length > 0 ? (isLoading ? <LoadingProgress/> : <PackageCards
            selection={query.get('new')}
            defaultPackages={packages}
            onPackageStatusChanged={handlePackageStatusChanged}
            loadPackages={loadPackages}
            onPackageUpload={handleUploadPackage}
            viewMode='Admin'
        />) : <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%',
            padding: '10px'
        }}>No packages to display</div>}
    </div>
}
export default observer(OrganisationPackageView);