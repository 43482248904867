export enum Trait {
    DOES = 'does',
    DOES_ELEVATE_ATTRIBUTE = 'does.elevateAttribute',
    DOES_HAVE_DEFAULT = 'does.haveDefault',
    HAS = 'has',
    IS = 'is',
    IS_CDM = 'is.CDM',
    IS_CDM_ATTRIBUTE_GROUP = 'is.CDM.attributeGroup',
    IS_CDM_ENTITY_VERSION = 'is.CDM.entityVersion',
    IS_ADDED_IN_SUPPORT_OF = 'is.addedInSupportOf',
    IS_APPLICATION = 'is.application',
    IS_APPLICATION_RELEASE_VERSION = 'is.application.releaseVersion',
    IS_CALCULATION_OF = 'is.calculationOf',
    IS_CONSTRAINED = 'is.constrained',
    IS_CONSTRAINED_LIST = 'is.constrainedList',
    IS_CONSTRAINED_LIST_CORRELATED = 'is.constrainedList.correlated',
    IS_CONSTRAINED_LIST_STRING = 'is.constrainedList.string',
    IS_CORRELATED_WITH = 'is.correlatedWith',
    IS_DATA_FORMAT = 'is.dataFormat',
    IS_DATA_FORMAT_ARRAY = 'is.dataFormat.array',
    IS_DATA_FORMAT_BIG = 'is.dataFormat.big',
    IS_DATA_FORMAT_BOOLEAN = 'is.dataFormat.boolean',
    IS_DATA_FORMAT_BYTE = 'is.dataFormat.byte',
    IS_DATA_FORMAT_CHARACTER = 'is.dataFormat.character',
    IS_DATA_FORMAT_DATE = 'is.dataFormat.date',
    IS_DATA_FORMAT_FLOATING_POINT = 'is.dataFormat.floatingPoint',
    IS_DATA_FORMAT_GUID = 'is.dataFormat.guid',
    IS_DATA_FORMAT_INTEGER = 'is.dataFormat.integer',
    IS_DATA_FORMAT_NUMERIC = 'is.dataFormat.numeric',
    IS_DATA_FORMAT_NUMERIC_SHAPED = 'is.dataFormat.numeric.shaped',
    IS_DATA_FORMAT_SMALL = 'is.dataFormat.small',
    IS_DATA_FORMAT_TIME = 'is.dataFormat.time',
    IS_DATA_FORMAT_TIME_OFFSET = 'is.dataFormat.timeOffset',
    IS_HIDDEN = 'is.hidden',
    IS_IDENTIFIED_BY = 'is.identifiedBy',
    IS_LINKED_ENTITY = 'is.linkedEntity',
    IS_LINED_ENTITY_ARRAY = 'is.linkedEntity.array',
    IS_LINED_ENTITY_ARRAY_COUNT = 'is.linkedEntity.array.count',
    IS_LINED_ENTITY_IDENTIFIER = 'is.linkedEntity.identifier',
    IS_LINED_ENTITY_NAME = 'is.linkedEntity.name',
    IS_LOCALIZED = 'is.localized',
    IS_LOCALIZED_DESCRIBED_AS = 'is.localized.describedAs',
    IS_LOCALIZED_DISPLAYED_AS = 'is.localized.displayedAs',
    IS_LOCATABLE = 'is.locatable',
    IS_MANAGED_BY = 'is.managedBy',
    IS_MODEL_CONVERSION = 'is.modelConversion',
    IS_MODEL_CONVERSION_MODEL_VERSION = 'is.modelConversion.modelVersion',
    IS_MODEL_CONVERSION_OTHER_ANNOTATIONS = 'is.modelConversion.otherAnnotations',
    IS_MODEL_CONVERSION_REFERENCE_MODEL_MAP = 'is.modelConversion.referenceModelMap',
    IS_NAMED = 'is.named',
    IS_NULLABLE = 'is.nullable',
    IS_ORDERED = 'is.ordered',
    IS_PARTITION = 'is.partition',
    IS_PARTITION_CULTURE = 'is.partition.culture',
    IS_PARTITION_FORMAT = 'is.partition.format',
    IS_PARTITION_FORMAT_CSV = 'is.partition.format.CSV',
    IS_PARTITION_FORMAT_CSV_PARQUET = 'is.partition.format.parquet',
    IS_READ_ONLY = 'is.readOnly',
    IS_REQUIRED = 'is.required',
    IS_REQUIRED_AT_LEVEL = 'is.requiredAtLevel',
    IS_SECRET = 'is.secret',
    IS_SENSITIVE = 'is.sensitive',
    MEANS = 'means',
    MEANS_CONTENT = 'means.content',
    MEANS_CONTENT_BINARY = 'means.content.binary',
    MEANS_CONTENT_BINARY_IMAGE = 'means.content.binary.image',
    MEANS_CONTENT_BINARY_IMAGE_BMP = 'means.content.binary.image.BMP',
    MEANS_CONTENT_BINARY_IMAGE_GIF = 'means.content.binary.image.GIF',
    MEANS_CONTENT_BINARY_IMAGE_JPG = 'means.content.binary.image.JPG',
    MEANS_CONTENT_BINARY_IMAGE_PNG = 'means.content.binary.image.PNG',
    MEANS_CONTENT_BINARY_IMAGE_TIFF = 'means.content.binary.image.TIFF',
    MEANS_CONTENT_TEXT = 'means.content.text',
    MEANS_CONTENT_TEXT_CSV = 'means.content.text.CSV',
    MEANS_CONTENT_TEXT_HTML = 'means.content.text.HTML',
    MEANS_CONTENT_TEXT_JSON = 'means.content.text.JSON',
    MEANS_CONTENT_TEXT_XML = 'means.content.text.XML',
    MEANS_ENTITY_NAME = 'means.entityName',
    MEANS_ENTITY_NAME_SPECIFIC = 'means.entityName.specific',
    MEANS_ENTITY_STATE = 'means.entityState',
    MEANS_IDENTITY = 'means.identity',
    MEANS_IDENTITY_ENTITY_ID = 'means.identity.entityId',
}