import {observable} from "mobx";
import CDMDocument, { CDMDocumentType } from "./CDMDocument";

export default class Instance implements CDMDocument {
    @observable hostname: string;
    @observable url: string;
    private _parent: any;
    readonly documentType = CDMDocumentType.INSTANCE;

    constructor(hostname: string, url: string) {
        this.hostname = hostname;
        this.url = url;
    }

    set parent(parent: any) {
        this._parent = parent;
    }

    get parent() {
        return this._parent;
    }
}

export enum PackageState {
    LOADED = 'LOADED',
    STARTED = 'STARTED'
}

export interface UploadPackage {
    password: string,
    instanceUrl: string,
    version: string,
    state: PackageState,
}

export const mapToUploadPackage = (password: string, instanceUrl: string, version: string, state?: PackageState): UploadPackage => {
    return {
        password: password,
        instanceUrl: instanceUrl,
        version: version,
        state: state || PackageState.LOADED,
    };
}