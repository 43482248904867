import React, {useEffect, useState} from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Input,
    Switch,
    TextField,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Grid,
    Tooltip,
    Toolbar,
    Typography,
    IconButton,
} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import { Add } from "@material-ui/icons";
import { NegroniIcon } from "../icon/NegronIIcon";

export interface CellEditor<T> {
    value: T;
    validate?: (value: T) => string | undefined | Promise<string | undefined>;
    onApply?: (value: T) => void;
}

export interface CellEditorProps<T> {
    editor: CellEditor<T>;
}

export interface TextCellEditorProps extends CellEditorProps<string> {
    minLength?: number,
    maxLength?: number,
    multi?: boolean,
    label: string,
    description: string,
}

export interface BooleanCellEditorProps extends CellEditorProps<boolean> {
    readOnly?: boolean,
}

export interface ArrayCellEditorProps extends CellEditorProps<string[]> {
    choices: string[],
    label: string,
    description: string,
}

export const TextCellEditor: React.FC<TextCellEditorProps> = ({editor, minLength, maxLength, multi, label, description}) => {
    const getValue = () =>
        editor.value === undefined ? "" : editor.value;
    const valueChanged = React.useRef(false);
    const [value, setValue] = React.useState(getValue());
    const [error, setError] = React.useState("");
    const [openTextDialog, setOpenTextDialog] = useState(false);

    const validate = async (input: string): Promise<string | undefined> => {
        return editor.validate?.(input);
    };

    const onChange = async (input: string) => {
        valueChanged.current = true;
        setValue(input);
        try {
            await validate(input);
            setError('');
        } catch (e) {
            setError(e.message);
        }
    };

    const apply = async (input: string): Promise<boolean> => {
        try {
            await validate(input);
            if (editor.onApply) {
                editor.onApply(input);
                return true;
            }
        } catch (e) {
            return false;
        }
        return false;
    };

    const handleKeyUp = async (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") await apply(value);
        else if (e.key === "Escape") {
            setValue(getValue());
            setError("");
        }
    };

    const handleBlur = async (input: string) => {
        if (valueChanged.current) {
            if (!await apply(input))
                setValue(getValue());
        }
        setError("");
        valueChanged.current = false;
    };

    React.useEffect(() => setValue(getValue()), [editor.value]);
    React.useEffect(() => setError(""), [editor.value]);

    function handleOpenTextDialog() {
        setOpenTextDialog(true);
    }

    function handleCloseTextDialog() {
        setOpenTextDialog(false);
    }

    return <div style={{display: 'flex'}}>
        <Input
            value={value}
            onChange={e => onChange(e.target.value)}
            onBlur={e => handleBlur(e.target.value)}
            onKeyUp={handleKeyUp}
            fullWidth
            error={!!error && error.length > 0}
            title={error}
        />
        <Button size='small' onClick={handleOpenTextDialog}>
            <NegroniIcon iconClass="more-horiz-icon" />
        </Button>
        <TextEditorDialog
            open={openTextDialog}
            onClose={handleCloseTextDialog}
            title={label}
            label={label}
            description={description}
            defaultValue={value}
            onSave={value => {
                onChange(value)
                    .then(_ => apply(value));
                return true;
            }}
            validate={val => validate(val)}
        />
    </div>
}

export const ArrayCellEditor: React.FC<ArrayCellEditorProps> = ({editor, choices, label, description}) => {
    const getValue = () =>
        editor.value === undefined ? [] : editor.value;
    const valueChanged = React.useRef(false);
    const [value, setValue] = React.useState(getValue());
    const [openTextDialog, setOpenTextDialog] = useState(false);
    const [error, setError] = React.useState("");

    const validate = async (input: string[]): Promise<string | undefined> => {
        return editor.validate?.(input);
    };

    const onChange = async (input: string[]) => {
        valueChanged.current = true;
        setValue(input);
        try {
            await validate(input);
            setError('');
        } catch (e) {
            setError(e.message);
        }
    };

    const apply = async (input: string[]): Promise<boolean> => {
        try {
            await validate(input);
            if (editor.onApply) {
                editor.onApply(input);
                return true;
            }
        } catch (e) {
            return false;
        }
        return false;
    };

    function handleOpenTextDialog() {
        setOpenTextDialog(true);
    }

    function handleCloseTextDialog() {
        setOpenTextDialog(false);
    }

    return <div style={{display: 'flex'}}>
        <Button size='small' onClick={handleOpenTextDialog}>
            <NegroniIcon iconClass="more-horiz-icon" />
        </Button>
        <ArrayEditorDialog
            open={openTextDialog}
            onClose={handleCloseTextDialog}
            title={label}
            label={label}
            description={description}
            defaultValue={value}
            onSave={value => {
                onChange(value)
                    .then(_ => apply(value));
                return true;
            }}
            validate={val => validate(val)}
        />
    </div>
}

export const BooleanCellEditor: React.FC<BooleanCellEditorProps> = ({editor, readOnly}) => {
    const [checked, setChecked] = useState(false);

    useEffect(() => {
        setChecked(editor.value);
    }, []);

    const onChange = (value: boolean) => {
        setChecked(value);
        if (editor.onApply)
            editor.onApply(value);
    }

    return <div>
        <Switch
            readOnly={readOnly}
            checked={checked}
            onChange={(e) => onChange(e.target.checked)}
            inputProps={{'aria-label': 'primary checkbox'}}
        />
    </div>
}

export interface TextEditorDialogProp {
    open: boolean;
    onClose: () => void;
    title: string;
    label: string;
    description: string;
    defaultValue: string;
    onSave: (value: string) => boolean;
    validate: (value: string) => Promise<string | undefined>;
}

export const TextEditorDialog: React.FC<TextEditorDialogProp> = ({open, onClose, title, label, description, defaultValue, onSave, validate}) => {
    const [value, setValue] = useState<string>(defaultValue);
    const [error, setError] = useState<string | undefined>('');

    useEffect(() => {
        setValue(defaultValue);
    }, [defaultValue]);

    useEffect(() => {
        validate(value)
            .then(_ => setError(''))
            .catch(e => setError(e.message));
    }, [value]);

    return <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{overflowY: 'visible'}}
    >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent style={{overflowY: 'visible', maxWidth: '500px', width: '500px', minHeight: '300px'}}>
            <DialogContentText>
                {description}
            </DialogContentText>
            <form action="#">
                <TextField
                    multiline
                    rows={6}
                    autoFocus
                    margin="dense"
                    label={label}
                    size='small'
                    fullWidth
                    variant='filled'
                    value={value}
                    onChange={e => setValue(e.target.value)}
                    helperText={error}
                    error={!!error && error.length > 0}
                />
            </form>
        </DialogContent>
        <DialogActions>
            <Button onClick={onClose}>
                Cancel
            </Button>
            <Button
                variant='contained'
                disabled={!!error && error.length > 0}
                onClick={_ => {
                    if (onSave(value))
                        onClose();
                }}
                autoFocus
                color='secondary'
                disableElevation
            >
                Save
            </Button>
        </DialogActions>
    </Dialog>;
}

export interface ArrayEditorDialogProp {
    open: boolean;
    onClose: () => void;
    title: string;
    label: string;
    description: string;
    defaultValue: string[];
    onSave: (value: string[]) => boolean;
    validate: (value: string[]) => Promise<string | undefined>;
}

export const ArrayEditorDialog: React.FC<ArrayEditorDialogProp> = ({open, onClose, title, label, description, defaultValue, onSave, validate}) => {
    const [value, setValue] = useState<string[]>(defaultValue);
    const [error, setError] = useState<string | undefined>('');
    const [selectedChoices, setSelectedChoices] = useState<string[]>([]);
    const [nameInput, setNameInput] = useState<HTMLInputElement>();
    const [choiceName, setChoiceName] = useState<string>('');

    useEffect(() => {
        if(open) {
            setValue(defaultValue)
            setChoiceName('')
            setError('')
            setSelectedChoices([])
        }
    }, [open]);

    useEffect(() => {
        setValue(defaultValue);
    }, [defaultValue]);

    useEffect(() => {
        validate(value)
            .then(_ => setError(''))
            .catch(e => setError(e.message));
    }, [value]);

    const isSelected = (choice: string) => selectedChoices.indexOf(choice) !== -1;

    function handleClick(choice: string) {
        const selectedIndex = selectedChoices.indexOf(choice)
        let newSelected: string[] = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selectedChoices, choice);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selectedChoices.slice(1));
        } else if (selectedIndex === selectedChoices.length - 1) {
            newSelected = newSelected.concat(selectedChoices.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selectedChoices.slice(0, selectedIndex),
                selectedChoices.slice(selectedIndex + 1),
            );
        }
        setSelectedChoices(newSelected);
    }

    const addChoices = (choice: string) => {
        setValue([...value, choice]);
    }

    const deleteChoices = (choices: string[]) => {
        choices.forEach(choice => {
            const deleteIndex = value.indexOf(choice, 0);
            value.splice(deleteIndex, 1);
        })
        setSelectedChoices([])
        setValue([...value])
    }

    const handleDeleteChoices = () => {
        if(selectedChoices.length > 0)
            deleteChoices(selectedChoices)
    }

    const isChoiceExisting = (choice: string):boolean => {
        return !!value.find(c => c === choice)
    }

    return <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{overflowY: 'visible'}}
    >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent style={{overflowY: 'visible', maxWidth: '500px', width: '500px', minHeight: '300px'}}>
            <DialogContentText>
                {description}
            </DialogContentText>
            <Grid container>
                <Grid item xs={10}>
                    <TextField
                        inputRef={setNameInput}
                        autoFocus
                        margin="dense"
                        label="Choices Name"
                        fullWidth
                        variant='filled'
                        value={choiceName}
                        onChange={e => setChoiceName(e.target.value)}
                        helperText={error}
                        error={!!error}
                    />
                </Grid>
                <Grid item xs={2} style={{placeSelf: 'center'}}>
                    <Tooltip
                        title={'Add Choices'}
                    >
                        <Button
                            disabled={isChoiceExisting(choiceName)}
                            variant="contained"
                            color="secondary"
                            style={{marginLeft: '10px'}}
                            onClick={() => { addChoices(choiceName)}}
                        >
                            <Add/>
                        </Button>
                    </Tooltip>
                </Grid>
            </Grid>
            {selectedChoices.length > 0 && <Toolbar className='table-toolbar' variant='dense'>
                <Typography color="inherit" variant="subtitle1" component="div">
                    {selectedChoices.length} Selected
                </Typography>
                <Tooltip title="Delete">
                    <IconButton onClick={handleDeleteChoices} color="inherit">
                        <NegroniIcon iconClass="delete-icon" color="#fff" />
                    </IconButton>
                </Tooltip>
            </Toolbar>}
            {value.length > 0 ? <>
                <Table size='small'>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{width: '5%'}}/>
                            <TableCell style={{width: '95%'}}>Name</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {value.map((choice, index) => (
                                <TableRow
                                    onClick={_ => {
                                        handleClick(choice);
                                    }}
                                    hover
                                    key={index}
                                    style={{cursor: 'pointer'}}
                                    selected={!!selectedChoices.find(sc => sc === choice)}
                                >
                                    <TableCell padding="checkbox">
                                        <Checkbox
                                            checked={isSelected(choice)}
                                            inputProps={{'aria-label': 'Select choices'}}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        {choice}
                                    </TableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
            </> : <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                height: '100%',
                padding: '10px'
            }}>No choices to display</div>}
        </DialogContent>
        <DialogActions>
            <Button onClick={onClose}>
                Cancel
            </Button>
            <Button
                variant='contained'
                disabled={!!error && error.length > 0}
                onClick={_ => {
                    if (onSave(value))
                        onClose();
                }}
                autoFocus
                color='secondary'
                disableElevation
            >
                Save
            </Button>
        </DialogActions>
    </Dialog>;
}