import React, {useContext} from "react";
import ContentContext from "./ContentContext";
import {CancelTokenSource} from "axios";
import CDMDocument from "../../core/observables/CDMDocument";

export interface ContentViewOptions {
    loader?: React.ReactElement;
}

export interface LoadContentValue {
    document: ((cancelToken: CancelTokenSource) => Promise<CDMDocument>) | undefined;
    view: (document: CDMDocument) => React.ReactElement;
    options?: ContentViewOptions;
}

const useContent = () => useContext(ContentContext);

export default useContent;