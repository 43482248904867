import React from "react";
import {Menu} from "@material-ui/core";

interface ContextMenuProps {
    position: Position | null,
    children?: React.ReactNode,
    handleClose: () => void,
}

export interface Position {
    mouseX: number,
    mouseY: number,
}

const ContextMenu: React.FC<ContextMenuProps> = ({position, handleClose, children}) => {
    return <>
        <Menu
            onContextMenu={e => e.preventDefault()}
            keepMounted
            open={position != null}
            onClose={handleClose}
            anchorReference='anchorPosition'
            anchorPosition={position ? {top: position?.mouseY || 100, left: position?.mouseX || 100} : {
                top: 100,
                left: 100
            }}
        >
            {children}
        </Menu>
    </>
};

export default ContextMenu;