import { Button, Tooltip } from "@material-ui/core";
import React, { FC } from "react";
import { NegroniIcon } from "../icon/NegronIIcon";

interface ReloadContentProps {
    onReload?: () => void;
}

const ReloadContent:FC<ReloadContentProps> = ({onReload}) => {
    return <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%'}}>
        <Tooltip title='Retry'>
            <Button onClick={onReload} size='large' style={{margin: '10px'}}><NegroniIcon iconClass="refresh-icon" /></Button>
        </Tooltip>
    </div>;
};

export default ReloadContent;