import React, {useState} from "react";
import {CellEditorProps} from "./CellEditor";
import Select from "react-select";
import AsyncSelect from 'react-select/async';
import {PaginatedSelect, PaginatedSelectProp} from "../select/PaginatedSelect";
import {FormControl, FormHelperText} from "@material-ui/core";
import isPromise from "is-promise";

export interface SelectCellEditorProps extends CellEditorProps<string> {
    options: { label: string, value: string }[];
}

export interface AsyncSelectCellEditorProps extends CellEditorProps<string> {
    loadOptions: (inputValue: string, callback: ((options: { label: string, value: string }[]) => void)) => Promise<any> | void;
}

export interface PaginatedSelectCellEditorProps extends CellEditorProps<string>, PaginatedSelectProp {
}

export const SelectCellEditor: React.FC<SelectCellEditorProps> = ({editor, options}) => {
    const getValue = () =>
        editor.value === undefined ? '' : editor.value;
    const [value, setValue] = useState(editor.value);

    function handleValueChanged(option: any) {
        setValue(option.value);
        if (editor.onApply)
            editor.onApply(option?.value);
    }

    React.useEffect(() => {
        setValue(getValue());
    }, [editor.value]);

    return <Select
        value={{label: value, value}}
        onChange={handleValueChanged}
        options={options}
        isSearchable
        menuPortalTarget={document.body}
        styles={{menuPortal: base => ({...base, zIndex: 9999})}}
        menuPlacement='auto'
        maxMenuHeight={200}
    />
}

export const AsyncSelectCellEditor: React.FC<AsyncSelectCellEditorProps> = ({editor, loadOptions}) => {
    const getValue = () =>
        editor.value === undefined ? '' : editor.value;
    const [value, setValue] = useState(editor.value);
    const [defaultOptions, setDefaultOptions] = useState<{ label: string, value: any }[]>([]);

    function handleValueChanged(option: any) {
        setValue(option.value);
        if (editor.onApply)
            editor.onApply(option?.value);
    }

    function handleLoadOptions() {
        const options = loadOptions(value, setDefaultOptions);
        if (isPromise(options))
            options.then(setDefaultOptions)
    }

    React.useEffect(() => {
        handleLoadOptions();
    }, []);

    React.useEffect(() => setValue(getValue()), [editor.value]);

    return <div>
        <AsyncSelect
            value={{label: value, value}}
            onChange={handleValueChanged}
            loadOptions={loadOptions}
            defaultOptions={defaultOptions}
            isSearchable
            onMenuOpen={handleLoadOptions}
            menuPortalTarget={document.body}
            styles={{menuPortal: base => ({...base, zIndex: 9999})}}
            menuPlacement='auto'
            maxMenuHeight={200}
        />
    </div>
};

export const PaginatedSelectCellEditor: React.FC<PaginatedSelectCellEditorProps> = (props) => {
    const getValue = () =>
        props.editor.value === undefined ? '' : props.editor.value;
    const [value, setValue] = useState(props.editor.value);
    const [error, setError] = useState<string>();

    const validate = async (value: any): Promise<string | undefined> => {
        if (props.editor.validate)
            return props.editor.validate(value)
    }

    const getCoreRelativePath = (location: string): string => {
        location = location.replace('cdm:/core/', '');
        let splitLocation = location.split('/');
        splitLocation.splice(splitLocation.length - 1, 1);
        return splitLocation.join('/');
    }

    function handleValueChanged(value: any) {
        validate(value).then(error => {
            setError(error);
            if (!error) {
                setValue(value)
                if (props.editor.onApply)
                    props.editor.onApply(value);
            }
        });
    }

    React.useEffect(() => setValue(getValue()), [props.editor.value]);

    return <FormControl error={error !== undefined || error !== ''} fullWidth>
        <PaginatedSelect
            {...props}
            onValueChanged={handleValueChanged}
            defaultValue={value}
            formatOptionLabel={option => {
                return <div style={{display: 'flex', flexDirection: 'column'}}>
                    {option.label}
                    {(option.value?.isDefault && option.value?.path) && <small
                        style={{color: 'var(--label-1-color)'}}>{getCoreRelativePath(option?.value?.path)}</small>}
                </div>;
            }}
        />
        <FormHelperText hidden={error === undefined || error === ''}>{error}</FormHelperText>
    </FormControl>
}