import {PropertySourceProvider} from "./PropertySourceProvider";
import {PropertySource} from "./PropertyDescriptor";
import {CDMDocumentType} from "../../core/observables/CDMDocument";
import Entity from "../../core/observables/Entity";
import EntityAttribute, {EntityAttributePropertySource} from "../../core/observables/EntityAttribute";
import {EntityService} from "../../core/services/EntityService";

export default class implements PropertySourceProvider {
    private readonly entityService: EntityService;

    constructor(entityService: EntityService) {
        this.entityService = entityService;
    }

    getPropertySource(property: any): PropertySource | null {
        const entity = property?.parent as Entity;
        return new EntityAttributePropertySource(property as EntityAttribute, entity.entityAttributes, this.entityService);
    }

    support(property: any): boolean {
        return property?.documentType === CDMDocumentType.ENTITY_ATTRIBUTE && property?.parent?.documentType === CDMDocumentType.ENTITY;
    }

}