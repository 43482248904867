import {observable} from "mobx";
import {PropertyDescriptor, PropertySource} from "../../component/properties/PropertyDescriptor";
import {BooleanCellEditor, CellEditorProps, TextCellEditor, ArrayCellEditor} from "../../component/properties/CellEditor";
import React from "react";
import CDMDocument, {CDMDocumentType} from "./CDMDocument";
import {DataType} from "../dataType";
import {SelectCellEditor} from "../../component/properties/SelectCellEditor";
import {Purpose} from "../purpose";
import * as yup from "yup";
import {NAME_PATTERN} from "../constants";
import Entity from "./Entity";

export default class TypeAttribute implements CDMDocument {
    static MIN_NAME_LENGTH = 2;
    static MAX_NAME_LENGTH = 50;
    @observable name: string;
    @observable dataType: DataType = DataType.STRING;
    @observable purpose: Purpose = Purpose.HAS_A;
    @observable description: string = '';
    @observable nullable: boolean = false;
    @observable isDefault: boolean = true;
    @observable isPrimaryKey: boolean = false;
    @observable choices: string[] = [];
    @observable maxLength?: number;

    public _parent?: Entity;
    readonly documentType = CDMDocumentType.TYPE_ATTRIBUTE;

    constructor(name: string, dataType: DataType = DataType.STRING, isDefault: boolean = true) {
        this.name = name;
        this.dataType = dataType;
        this.isDefault = isDefault;
    }

    set parent(parent: Entity | undefined) {
        this._parent = parent;
    }

    get parent() {
        return this._parent;
    }

    clone() {
        const typeAttribute = new TypeAttribute(this.name, this.dataType);
        typeAttribute.purpose = this.purpose;
        typeAttribute.description = this.description;
        typeAttribute.nullable = this.nullable;
        typeAttribute.isDefault = this.isDefault;
        typeAttribute.maxLength = this.maxLength
        return typeAttribute;
    }

}

export class TypeAttributePropertySource implements PropertySource {

    private readonly typeAttribute: TypeAttribute;
    private readonly descriptors: PropertyDescriptor[];
    documentType = CDMDocumentType.TYPE_ATTRIBUTE;

    constructor(typeAttribute: TypeAttribute, typeAttributes: TypeAttribute[]) {
        this.typeAttribute = typeAttribute;

        const primaryKeyOptions = Object.values(DataType)
            .filter(dataType => dataType === DataType.INTEGER || dataType === DataType.STRING)
            .map(value => ({label: value, value}))
            .sort((a, b) => a.label.localeCompare(b.label));

        const dataTypeOptions = Object.values(DataType)
            .map(value => ({label: value, value,}))
            .sort((a, b) => a.label.localeCompare(b.label));

        this.descriptors = [
            {
                readOnly: typeAttribute.isDefault,
                defaultValue: this.typeAttribute.name,
                displayName: 'Name',
                description: 'The name of type attribute',
                hasDefaultValue: true,
                name: 'name',
                value: this.typeAttribute.name,
                target: this.typeAttribute,
                getCellEditor: (props: CellEditorProps<any>) => <TextCellEditor {...props} label='Name'
                                                                                description='The name of type attribute'/>,
                validate: value => yup.string()
                    .required()
                    .min(TypeAttribute.MIN_NAME_LENGTH)
                    .max(TypeAttribute.MAX_NAME_LENGTH)
                    .matches(NAME_PATTERN, 'this must only contain alphanumeric and no underscore consecutively')
                    .notOneOf(typeAttributes.map(t => t.name)
                        .filter(name => name !== this.typeAttribute.name))
                    .validateSync(value),
            },
            {
                readOnly: typeAttribute.isDefault,
                defaultValue: this.typeAttribute.dataType,
                displayName: 'Data Type',
                description: 'Data type of the attribute',
                hasDefaultValue: true,
                name: 'dataType',
                value: this.typeAttribute.dataType,
                target: this.typeAttribute,
                getCellEditor: (props: CellEditorProps<any>) => <SelectCellEditor {...props}
                                                                                  options={this.typeAttribute.isPrimaryKey ? primaryKeyOptions : dataTypeOptions}/>,
                validate: _ => undefined,
            },
            {
                readOnly: typeAttribute.isDefault,
                defaultValue: this.typeAttribute.description,
                displayName: 'Description',
                description: 'Description of the attribute',
                hasDefaultValue: true,
                name: 'description',
                value: this.typeAttribute.description,
                target: this.typeAttribute,
                getCellEditor: (props: CellEditorProps<any>) => <TextCellEditor {...props} label='Description'
                                                                                description='The description of attribute'/>,
                validate: _ => undefined,
            },
            {
                readOnly: typeAttribute.isDefault,
                defaultValue: this.typeAttribute.nullable,
                displayName: 'Nullable',
                description: 'Whether the attribute is nullable or not.',
                hasDefaultValue: true,
                name: 'nullable',
                value: this.typeAttribute.nullable,
                target: this.typeAttribute,
                getCellEditor: (props: CellEditorProps<any>) => <BooleanCellEditor {...props} />,
                validate: _ => undefined,
            },
            {
                readOnly: typeAttribute.isPrimaryKey,
                defaultValue: this.typeAttribute.choices,
                displayName: 'Choices',
                description: 'Add choices for the attribute.',
                hasDefaultValue: true,
                name: 'choices',
                value: this.typeAttribute.choices,
                target: this.typeAttribute,
                getCellEditor: (props: CellEditorProps<any>) => <ArrayCellEditor {...props} choices={this.typeAttribute.choices} label='Choices' description='The choices of the attribute' />,
                validate: _ => undefined,
            }
        ];

        if (this.typeAttribute.dataType === 'string') this.descriptors.push({
            readOnly: typeAttribute.isDefault,
            defaultValue: this.typeAttribute.maxLength,
            displayName: 'Maximum Length',
            description: 'Maximum length of the attribute',
            hasDefaultValue: false,
            name: 'maxLength',
            value: this.typeAttribute.maxLength,
            target: this.typeAttribute,
            getCellEditor: (props: CellEditorProps<any>) => <TextCellEditor {...props} label='Max Length' description='Maximum length of the attribute'/>,
            validate: value => yup.string().matches(/^\d+$/).validateSync(value),
        });
    }

    get iconClass(): string {
        return '';
    }

    get title(): string {
        return 'Type Attribute';
    }

    dispose(): void {
    }

    getPropertyDescriptors(): PropertyDescriptor[] {
        return this.descriptors;
    }

    async setProperty(name: string, newValue: any): Promise<void> {
        switch (name) {
            case 'name':
                if(this.typeAttribute.isPrimaryKey) {
                    this.typeAttribute.parent?.setPrimaryKey(newValue);
                }
                this.typeAttribute.name = newValue as string;
                break;
            case 'description':
                this.typeAttribute.description = newValue as string;
                break;
            case 'dataType':
                this.typeAttribute.dataType = newValue as DataType;
                break;
            case 'purpose':
                this.typeAttribute.purpose = newValue as Purpose;
                break;
            case 'nullable':
                this.typeAttribute.nullable = Boolean(newValue);
                break;
            case 'choices':
                this.typeAttribute.choices = newValue as string[];
                break;
            case 'maxLength':
                this.typeAttribute.maxLength = parseInt(newValue);
            break;
        }
    }

    getModel(): CDMDocument {
        return this.typeAttribute;
    }

}