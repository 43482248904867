import React from "react";
import {EntityService} from "../../core/services/EntityService";
import {ManifestService} from "../../core/services/ManifestService";
import PackageService from "../../core/services/PackageService";
import {AdminReloadService} from "../../core/services/admin/AdminReloadService";
import {AdminOrganisationService} from "../../core/services/admin/AdminOrganisationService";
import { AdminEntityService } from "../../core/services/admin/AdminEntityService";
import { AdminManifestService } from "../../core/services/admin/AdminManifestService";
import { WorkspaceService } from "../../core/services/WorkspaceService";
import { NavigatorService } from "../../core/services/NavigatorService";

export interface ServiceContextValue {
    entityService: EntityService;
    manifestService: ManifestService;
    packageService: PackageService;
    adminReloadService: AdminReloadService;
    adminOrgService: AdminOrganisationService;
    adminEntityService: AdminEntityService;
    adminManifestService: AdminManifestService;
    navigatorService: NavigatorService;
    workspaceService: WorkspaceService;
}

export const ServiceContext = React.createContext({} as ServiceContextValue);