import React from "react";
import DocumentItem from "../../core/observables/DocumentItem";
import Manifest from "../../core/observables/Manifest";
import Entity, {CreateEntityType, mapToCreateEntity} from "../../core/observables/Entity";
import TypeAttribute from "../../core/observables/TypeAttribute";
import {DataType} from "../../core/dataType";
import {Purpose} from "../../core/purpose";
import ManifestView from "./ManifestView";
import CDMDocument from "../../core/observables/CDMDocument";
import {observer} from "mobx-react";
import {useSnackbar} from "notistack";
import useService from "../service/useService";
import useProperties from "../properties/useProperties";
import {MockDataItemTree} from "../navigator/Navigator";
import useBuildManifest from "./build/useBuildManifest";
import { CDMManager } from "../../core/CDMManager";
import { ExportTypes } from "./ExportManifestDialog";

interface ManifestContainerProps {
    manifest: Manifest;
    onChange: (document: CDMDocument | DocumentItem | null, message?: string) => void;
    viewMode: string;
    manifestOrg?: string;
    orgLabel?: string;
}

const ManifestViewContainer: React.FC<ManifestContainerProps> = ({manifest, onChange, viewMode, manifestOrg, orgLabel}) => {
    const {manifestService, entityService, adminReloadService} = useService();
    const {enqueueSnackbar} = useSnackbar();
    const {setProperty} = useProperties();
    const openBuildManifest = useBuildManifest();

    const handleAddSubManifest = (subManifest: DocumentItem, manifest: Manifest): Promise<string | undefined> => {
        return manifestService.addSubManifest(manifest.id, [subManifest.id])
            .then(_ => {
                localStorage.setItem(CDMManager.NEGRONI_RESET_SELECTED_NODE, 'true');
                subManifest.parent = new MockDataItemTree('Sub-Manifests', manifest);
                onChange(subManifest, 'Successfully added sub-manifest to manifest.')
            })
            .catch(error => error || 'Failed to add sub-manifest to manifest');
    };

    const handleAddEntity = (entity: DocumentItem, manifest: Manifest): Promise<string | undefined> => {
        entity.parent = new MockDataItemTree('Entities', manifest);
        return manifestService.addEntity(manifest.id, [entity.id])
            .then(_ => {
                localStorage.setItem(CDMManager.NEGRONI_RESET_SELECTED_NODE, 'true');
                onChange(entity, 'Successfully added entity to manifest.')
            }).catch(error => error || 'Failed to add entity to manifest');
    };

    const handleCreateExclusiveEntity = (entity: Entity, manifest: Manifest): Promise<string | undefined> => {
        const primaryKey = new TypeAttribute('id', DataType.INTEGER);
        primaryKey.purpose = Purpose.IDENTIFIED_BY;
        entity.typeAttributes = [primaryKey];
        entity.targetManifest = manifest.id;

        return entityService.createEntity(mapToCreateEntity(entity, entity?.type || CreateEntityType.EXCLUSIVE, manifest.id))
            .then(entity => {
                entity.parent = new MockDataItemTree('Entities', manifest);
                localStorage.setItem(CDMManager.NEGRONI_RESET_SELECTED_NODE, 'true');
                onChange(entity, 'Successfully created local entity to manifest.');
            })
            .catch(error => error || 'Failed to create local entity to manifest');
    };

    const handleDeleteManifest = (manifest: Manifest) => {
        if (manifest.parentManifestId !== undefined) {
            manifestService.deleteSubManifest(manifest.parentManifestId, manifest.id)
                .then(_ => {
                    localStorage.removeItem(CDMManager.NEGRONI_CURRENT_SELECTED_NODE);
                    onChange(null, 'Successfully deleted sub-manifest!');
                    setProperty(null);
                }).catch(error => enqueueSnackbar(error || 'Failed to delete sub-manifest', {variant: 'error'}));
        } else {
            manifestService.deleteManifest(manifest.id)
                .then(_ => {
                    localStorage.removeItem(CDMManager.NEGRONI_CURRENT_SELECTED_NODE);
                    onChange(null, 'Successfully deleted manifest!');
                    setProperty(null);
                }).catch(error => enqueueSnackbar(error || 'Failed to delete manifest', {variant: 'error'}));
        }
    }

    const handleReload = (manifest:Manifest) => {
        return adminReloadService.reloadManifest(manifest.id);
    };

    const handleExportManifest = (manifest: Manifest, exportType: ExportTypes, databaseType: string = 'postgresql', applyAutoIncrement: boolean): Promise<string | undefined> => {
        return manifestService.exportManifestDatabaseStructure(manifest.id, exportType, databaseType, applyAutoIncrement)
            .then(data => {
                const tempLink = document.createElement('a');
                tempLink.href = window.URL.createObjectURL(new Blob([data], {type: 'application/zip;charset=UTF-8'}));
                tempLink.download = `${manifest.name}-${exportType === ExportTypes.DATABASE_SCHEMA ? databaseType : 'cdm'}.zip`
                tempLink.click();
            }).catch(error => {
                return error || 'Failed to export manifest';
            });
    };

    return <ManifestView
        manifest={manifest}
        setSelection={setProperty}
        onCreateExclusiveEntity={handleCreateExclusiveEntity}
        onAddEntity={handleAddEntity}
        onAddSubManifest={handleAddSubManifest}
        onDeleteManifest={handleDeleteManifest}
        onBuildManifestClicked={openBuildManifest}
        onReload={handleReload}
        onExportManifest={handleExportManifest}
        viewMode={viewMode}
        manifestOrg={manifestOrg}
        orgLabel={orgLabel}
    />
};

export default observer(ManifestViewContainer);