import React, {useEffect, useRef, useState} from "react";
import {observer} from "mobx-react";
import { Menu, MenuItem, Button } from "@material-ui/core";
import CreateEntityByOrgDialog from "./entity/CreateEntityByOrgDialog";
import Entity from "../../core/observables/Entity";
import { CreateManifestByOrgDialog } from "./manifest/CreateManifestByOrgDialog";
import Manifest from "../../core/observables/Manifest";
import { NegroniIcon } from "../icon/NegronIIcon";

interface OrganisationViewMenuProps {
    orgCode: string;
    orgName: string;
    onCreateEntity: (orgCode: string, entity: Entity) => Promise<string | undefined>;
    onCreateManifest: (orgCode: string, manifest: Manifest) => Promise<string | undefined>;
}

export const OrganisationViewMenu: React.FC<OrganisationViewMenuProps> = (
    {
        orgCode,
        orgName,
        onCreateEntity,
        onCreateManifest,
    }
) => {
    const [open, setOpen] = useState<boolean>(false);
    const [openCreateEntity, setOpenCreateEntity] = useState<boolean>(false);
    const [openCreateManifest, setOpenCreateManifest] = useState<boolean>(false);
    const anchorRef = useRef<HTMLButtonElement>(null);

    function handleOpen() {
        setOpen((prevOpen) => !prevOpen);
    }

    function handleMenuClose(event: React.MouseEvent<EventTarget>) {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }
        setOpen(false);
    }

    const handleCreateEntityByOrgOpen = () => {
        setOpenCreateEntity(true);
        setOpen(false)
    }

    const handleCreateEntityByOrgClose = () => {
        setOpenCreateEntity(false);
    }

    const handleCreateManifestByOrgOpen = () => {
        setOpenCreateManifest(true);
        setOpen(false)
    }

    const handleCreateManifestByOrgClose = () => {
        setOpenCreateManifest(false);
    }

    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current && !open)
            anchorRef.current!.focus();

        prevOpen.current = open;
    }, [open]);

    return <>
        <Button
            style={{margin: '10px'}}
            ref={anchorRef}
            onClick={handleOpen}
            size='small'
            variant='contained'
            disableElevation
        >
            <NegroniIcon iconClass="settings-icon" />
        </Button>
        <Menu
            anchorEl={anchorRef.current}
            open={open}
            onClose={handleMenuClose}
        >
            <MenuItem onClick={handleCreateEntityByOrgOpen}><NegroniIcon iconClass="description-icon" /> Create Global Entity</MenuItem>
            <MenuItem onClick={handleCreateManifestByOrgOpen}><NegroniIcon iconClass="assignment-icon" /> Create Manifest</MenuItem>
        </Menu>
        <CreateEntityByOrgDialog
            orgCode={orgCode}
            orgName={orgName}
            defaultEntityName={'new_entity'}
            open={openCreateEntity}
            onClose={handleCreateEntityByOrgClose}
            onCancel={handleCreateEntityByOrgClose}
            onFinish={onCreateEntity}
        />
        <CreateManifestByOrgDialog 
            orgCode={orgCode}
            orgName={orgName}
            open={openCreateManifest}
            onClose={handleCreateManifestByOrgClose}
            onCancel={handleCreateManifestByOrgClose}
            onFinish={onCreateManifest}
        />
    </>
}

export default observer(OrganisationViewMenu)