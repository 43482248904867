import React, {FC, useEffect, useState} from "react";
import {TreeItem, TreeView} from "@material-ui/lab";
import {createStyles, Icon, Typography} from "@material-ui/core";
import useService from "../../service/useService";
import {makeStyles} from "@material-ui/core/styles";
import { NegroniIcon } from "../../icon/NegronIIcon";

const useManifestTreeNodeItemStyles = makeStyles(theme => createStyles({
    root: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0.5, 0),
    },
    icon: {
        marginRight: theme.spacing(1),
    },
    text: {
        fontWeight: 'inherit',
        flexGrow: 1,
    }
}));

export interface ManifestNode {
    id: number;
    name: string;
    subManifests?: ManifestNode[];
    parent?: ManifestNode;
}

interface ManifestTreeProps {
    manifestNode: ManifestNode;
    onSelectedNode: (nodeId: string) => void;
}

interface ManifestTreeNodeProps {
    manifestNode: ManifestNode;
}

const ManifestTree: FC<ManifestTreeProps> = ({manifestNode, onSelectedNode}) => {
    return <TreeView
        style={{flex: '1'}}
        onNodeSelect={(_: any, nodeId: string) => onSelectedNode(nodeId)}
        defaultCollapseIcon={<NegroniIcon iconClass="expand-more-icon" />}
        defaultExpandIcon={ <NegroniIcon iconClass="chevron-right" />}
    >
        <ManifestTreeNode manifestNode={manifestNode}/>
    </TreeView>
};

const ManifestTreeNode: FC<ManifestTreeNodeProps> = ({manifestNode}) => {
    const [subManifests, setSubManifests] = useState<ManifestNode[]>([]);
    const {manifestService} = useService();

    useEffect(() => {
        if (!manifestNode?.subManifests) {
            manifestService.getManifestById(manifestNode.id)
                .then(manifest => {
                    if (manifest?.subManifestPaths) {
                        const subManifests = manifest.subManifestPaths.map(({id, name}) => ({
                            id, name
                        }));
                        setSubManifests(subManifests);
                        manifestNode.subManifests = subManifests;
                    } else {
                        manifestNode.subManifests = [];
                    }
                });
        }
    }, []);

    useEffect(() => {
        if (manifestNode?.subManifests && manifestNode.subManifests.length > 0) {
            setSubManifests(manifestNode?.subManifests);
        }
    }, [manifestNode.subManifests]);

    return <ManifestTreeNodeItem manifestNode={manifestNode}>
        {subManifests?.map(subManifestNode => (
            <ManifestTreeNode key={subManifestNode.id} manifestNode={subManifestNode}/>
        ))}
    </ManifestTreeNodeItem>
};

const ManifestTreeNodeItem: FC<ManifestTreeNodeProps> = ({manifestNode, children}) => {
    const classes = useManifestTreeNodeItemStyles();
    return <TreeItem
        nodeId={`${manifestNode.id}~${manifestNode.name}`}
        label={
            <div className={classes.root}>
                <NegroniIcon iconClass="assignment-icon" />
                <Typography variant='body2' className={classes.text}>
                    {manifestNode.name}
                </Typography>
            </div>
        }
    >
        {children}
    </TreeItem>
};

export default ManifestTree;