import {PropertySourceProvider} from "./PropertySourceProvider";
import {PropertySource} from "./PropertyDescriptor";
import {CDMDocumentType} from "../../core/observables/CDMDocument";
import Entity, {EntityPropertySource} from "../../core/observables/Entity";
import {EntityService} from "../../core/services/EntityService";

export default class EntityPropertySourceProvider implements PropertySourceProvider {

    private readonly entityService: EntityService;

    constructor(entityService: EntityService) {
        this.entityService = entityService;
    }

    getPropertySource(property: any): PropertySource | null {
        return new EntityPropertySource(property as Entity, this.entityService);
    }

    support(property: any): boolean {
        return property?.documentType === CDMDocumentType.ENTITY;
    }

}