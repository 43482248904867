import React, {FC} from "react";
import AuthContext, {AuthContextValue} from "./AuthContext";
import HttpClient from "axios";
import Cookies from "js-cookie";
import config from "../../config";
import useSession from "../session/useSession";
import AuthState from "../auth/AuthState";
import { SESSION_KEY, REFRESH_TOKEN, ID_TOKEN, USER_KEY } from "../session/SessionProvider";
import { uuid } from "uuidv4";

const BASE = `/${config.api_version}/cdm`;

const AuthProvider: FC = ({children}) => {
    const {setUser} = useSession();

    const logout = () => {
        Cookies.remove(USER_KEY);
        Cookies.remove(SESSION_KEY);
        Cookies.remove(REFRESH_TOKEN);
        Cookies.remove(ID_TOKEN)
        localStorage.removeItem('authState');
        localStorage.removeItem('state');
        setUser(undefined);
    };

    const generateState = (): string => {
        return uuid();
    }

    const storeState = (stateToken: string) => {
        localStorage.setItem('state', stateToken);
    }

    const getState = (): string => {
        return localStorage.getItem('state') || '';
    }

    const getAuthState = (): AuthState => {
        let authState = localStorage.getItem('authState');
        if(!authState) {
            return { isAuthRunning: false, isAuthenticated: false}
        }

        return JSON.parse(authState)
    }

    const updateAuthState = (authState: AuthState) => {
        localStorage.setItem('authState', JSON.stringify(authState));
    }

    const ssoSignIn = (code: string) => {
        return HttpClient.post(`${BASE}/sso/signin?code=${code}`)
            .then(_ => {
                return ''
            })
    };

    const ssoRefreshToken = (refreshToken: string) => {
        return HttpClient.post(`${BASE}/sso/refresh?refreshToken=${refreshToken}`)
            .then(_ => {
                return ''
            })
    }

    return <>
        <AuthContext.Provider value={{
            logout,
            generateState,
            storeState,
            getState,
            getAuthState,
            updateAuthState,
            ssoSignIn,
            ssoRefreshToken,
        } as AuthContextValue}>
            {children}
        </AuthContext.Provider>
    </>
}

export default AuthProvider;