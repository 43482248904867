import React from "react";
import {createMuiTheme} from "@material-ui/core";

export const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#722E9A',
            dark: '#459ecb',
            light: '#81bddc',
        },
        secondary: {
            main: '#9b59b6',
            dark: '#82409d',
            light: '#da90f8',
        },
        // primary: {
        //     main: '#00838f',
        //     dark: '#005662',
        //     light: '#4fb3bf',
        // },
        // secondary: {
        //     main: '#005662',
        //     dark: '#005662',
        //     light: '#005662',
        // },
        success: {
            main: '#1ac222',
            light: '#1ac222',
            dark: '#01a909',
        },
        error: {
            main: '#c62c29',
            light: '#c62c29',
            dark: '#930000',
        },
    },
    typography: {
        fontFamily: '"Helvetica",sans-serif',
        body1: {
            color: 'var(--data-heading-color)',
        },
        body2: {
            color: 'var(--data-heading-color))',
        },
    },
});