import React from "react";
import {Redirect} from "react-router-dom";
import useAuth from "./useAuth";
import { Grid, CircularProgress, Typography } from "@material-ui/core";

export const Logout: React.FC = () => {
    const {logout} = useAuth();
    const {getAuthState} = useAuth();
    const isAuthenticated = getAuthState()?.isAuthenticated
    logout();
    const signOutUrl = process.env.REACT_APP_SSO_LOGOUT_URL;
    if ( signOutUrl && isAuthenticated ) {
        window.location.href = signOutUrl
    }
    return <>
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justify="center"
            style={{ minHeight: '100vh' }}
        >
            <Grid
                style={{ marginBottom: '20px' }}
            >
                <CircularProgress size={75}/>
            </Grid>
            <Grid>
            <Typography variant="h5">
                Signing Out...
            </Typography>
            </Grid>
        </Grid>
    </>;
}

export default Logout;