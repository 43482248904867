import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import {
    Dialog, 
    DialogTitle, 
    DialogContent,
    DialogActions,
    Button,
    FormControlLabel,
    Checkbox,
    FormControl,
    Tooltip,
    FormHelperText,
    TextField,
} from "@material-ui/core";
import useService from "../service/useService";
import { Filter } from "../../core/Filter";
import { CancelTokenSource } from "axios";
import PaginatedResponse from "../../core/services/PaginatedResponse";
import { SelectGroupOption, SelectOption, PaginatedSelect } from "../select/PaginatedSelect";
import { Clear, FilterList } from "@material-ui/icons";
import Entity, { ExtendsEntity } from "../../core/observables/Entity";

export interface ExtendEntityDialogProp {
    entity: Entity;
    open: boolean;
    onClose: () => void;
    onFinish: (extendEntity: ExtendsEntity | undefined) => void;
    viewMode: string;
    manifestOrg?: string;
    orgLabel?: string;
}

export const ExtendEntityDialog: React.FC<ExtendEntityDialogProp> = ({
    entity,
    open,
    onClose,
    onFinish,
    viewMode,
    manifestOrg,
    orgLabel,
}) => {
    const {entityService, adminOrgService} = useService();
    const [filter, setFilter] = React.useState<Filter>(Filter.RESOLVED);
    const [updateAddEntity, setUpdateAddEntity] = useState(0);
    const [advanceFilter, setAdvanceFilter] = useState(false);
    const [folderFilter, setFolderFilter] = useState(false);
    const [folderFilterValue, setFolderFilterValue] = useState<string>('');
    const [pathFilter, setPathFilter] = useState(false);
    const [pathFilterValue, setPathFilterValue] = useState<string>('');
    const [error, setError] = useState<string | null>();
    const [extendEntity, setExtendEntity] = useState<ExtendsEntity | undefined>(undefined);

    useEffect(() => {
        setError(!extendEntity ? 'this is a required field' : '');
    }, [extendEntity]);

    const refreshAddEntity = () => {
        setUpdateAddEntity(updateAddEntity + 1);
    }

    useEffect(() => {
        if(filter === Filter.PRIVATE) {
            resetFilter();
        }
        refreshAddEntity();
    }, [filter]);

    const handleAdvanceFilter = () => setAdvanceFilter(!advanceFilter);

    const handleFolderFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFolderFilter(event.target.checked);
        setFolderFilterValue('');
        refreshAddEntity();
    }

    const handlePathFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPathFilter(event.target.checked);
        setPathFilterValue('');
        refreshAddEntity();
    }

    const handleFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFilter(event.target.checked ? Filter.RESOLVED : Filter.PRIVATE);
        if( filter === Filter.PRIVATE ) {
            resetFilter();
        }
    }

    const resetFilter = () => {
        setAdvanceFilter(false);
        setFolderFilter(false);
        setFolderFilterValue('');
        setPathFilter(false);
        setPathFilterValue('');
        refreshAddEntity();
    }

    const getEntities = (page: number = 0, limit: number = 20, search: string = '', cancelTokenSource?: CancelTokenSource): Promise<PaginatedResponse<SelectGroupOption[] | SelectOption[]>> => {
        if(viewMode === 'Admin' && filter === Filter.PRIVATE) {
            return adminOrgService.getEntitiesByOrg(manifestOrg || 'TORO', limit, page, search, cancelTokenSource?.token)
                .then(response => {
                    return {
                        ...response,
                        result: response.result.map(entity => ({label: entity.name, value: entity}))
                    } as PaginatedResponse<SelectOption[]>;
                });
        }
        return entityService.getEntities(filter, limit, page, search, entity.id, folderFilterValue, pathFilterValue, cancelTokenSource?.token)
            .then(response => {
                return {
                    ...response,
                    result: response.result.map(entity => ({label: entity.name, value: entity}))
                } as PaginatedResponse<SelectOption[]>;
            });
    };

    const getCoreRelativePath = (location: string): string => {
        location = location.replace('cdm:/core/', '');
        let splitLocation = location.split('/');
        splitLocation.splice(splitLocation.length - 1, 1);
        return splitLocation.join('/');
    }

    const hideFilter = () => {
        setAdvanceFilter(false);
    }

    const handleApply = () => {
        onFinish(extendEntity);
        onClose();
    }

    return <Dialog
        open={open}
        onClose={onClose}
    >
        <form onSubmit={e => {
            e.preventDefault();
            handleApply();
        }}>
        <DialogTitle>Extend Entity</DialogTitle>
            <DialogContent dividers
                        style={{
                            overflowY: 'hidden',
                            maxWidth: '500px',
                            width: '500px',
                            minHeight: '400px'
                        }}>
            { viewMode === 'Admin' && filter === Filter.PRIVATE ?
                        <p className='dialog-label'>Select an entity from <b>{orgLabel}</b> organisation to extend in <b>{entity?.name}</b> extend.</p> :
                        <p className='dialog-label'>Select an entity to extend in <b>{entity?.name}</b> entity.</p> }
            <FormControlLabel
                control={<Checkbox checked={filter === Filter.RESOLVED} onChange={handleFilter}
                                    inputProps={{'aria-label': 'Select custom entities'}}/>}
                label="Show Core Entities"
            />
            <FormControl variant='filled' fullWidth={true} style={{display: 'flex', flexDirection: 'row'}}>
                <div style={{width: '100%'}}>
                    <PaginatedSelect
                        autoFocus={true}
                        getOptions={getEntities}
                        valueMapper={value => value ? ({label: value.name, value: value.value}) : null}
                        onValueChanged={setExtendEntity}
                        update={updateAddEntity}
                        formatOptionLabel={option => {
                            return <div style={{display: 'flex', flexDirection: 'column'}}>
                                {option.label}
                                {(filter === Filter.RESOLVED && option.value?.path) && <small
                                    style={{color: 'var(--label-1-color)'}}>{getCoreRelativePath(option?.value?.path)}</small>}
                            </div>;
                        }}
                        hideFilter={hideFilter}
                    />
                </div>
                {filter === Filter.RESOLVED ? <Tooltip
                    title={advanceFilter ? 'Hide Advance Filters' : 'Advance Filters'}
                >
                    <Button
                        variant="contained"
                        color="secondary"
                        style={{marginLeft: '10px'}}
                        onClick={handleAdvanceFilter}
                    >
                        {advanceFilter ? <Clear/> : <FilterList/>}
                    </Button>
                </Tooltip> : undefined}
            </FormControl>
            <FormHelperText error={!!error && error.length > 0}>{error}</FormHelperText>
            { advanceFilter && filter === Filter.RESOLVED  ? <FormControl variant='filled' fullWidth={true} style={{display: 'flex', flexDirection: 'column'}}>
                <div>
                    <FormControlLabel
                        control={<Checkbox checked={folderFilter} onChange={handleFolderFilter}
                                        inputProps={{'aria-label': 'Filter by folder'}}/>}
                        label="By Folder"
                    />
                    { folderFilter ? <TextField
                        value={folderFilterValue}
                        onChange={e => {
                            setFolderFilterValue(e.target.value);
                            refreshAddEntity();
                        }}
                        type='text'
                        autoFocus
                        fullWidth={true}
                    /> : undefined }
                </div>
                <div>
                    <FormControlLabel
                        control={<Checkbox checked={pathFilter} onChange={handlePathFilter}
                                        inputProps={{'aria-label': 'Filter by path'}}/>}
                        label="By Path"
                    />
                    { pathFilter ? <TextField
                        value={pathFilterValue}
                        onChange={e => {
                            setPathFilterValue(e.target.value);
                            refreshAddEntity();
                        }}
                        type='text'
                        autoFocus
                        fullWidth={true}
                    /> : undefined }
                </div>
            </FormControl> : undefined}
        </DialogContent>
        <DialogActions>
            <Button onClick={onClose}>
                Close
            </Button>
            <Button
                type='submit'
                variant='contained'
                disableElevation
                color='secondary'
                disabled={!!error && error.length > 0}
                onClick={handleApply}
            >
                Apply
            </Button>
        </DialogActions>
        </form>
    </Dialog>
}

export default observer(ExtendEntityDialog);