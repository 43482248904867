import React, {useEffect, useRef} from "react";
import { observer } from "mobx-react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Button,
    Avatar,
    Icon,
    makeStyles,
    Theme,
    createStyles
} from "@material-ui/core";
import LaunchIcon from '@material-ui/icons/Launch';
import { NegroniIcon } from "../icon/NegronIIcon";

interface GuidedToursProp {
    open: boolean;
    onClose: () => void;
    startTour: (type: string) => void;
}

const guidedTourStyle = makeStyles((theme: Theme) =>
    createStyles({
        guidedTourText: {
            fontSize: '10em !important',
            paddingLeft: 20,
        },
        largeAvatar: {
            width: theme.spacing(10),
            height: theme.spacing(10),
            backgroundImage: 'linear-gradient(315deg,var(--dark-secondary-color) 0,var(--data-heading-color) 50%)',
        },
    }),
);

const GuidedTours: React.FC<GuidedToursProp> = ({open, onClose, startTour}) => {
    const classes = guidedTourStyle();

    return <Dialog
        open={open}
        onClose={onClose}
        maxWidth='xl'
    >
        <DialogTitle>Guided Tours</DialogTitle>
        <DialogContent dividers>
            <List>
                <ListItem>
                    <ListItemAvatar>
                        <Avatar className={classes.largeAvatar}>
                            <NegroniIcon iconClass="new-manifest-icon" size="large" color="#ddd1f0" />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        className={classes.guidedTourText}
                        primary='Create Manifest Tour'
                    />
                    <Button
                        onClick={_ => {
                            startTour('CreateManifestTour')
                            onClose()
                        }}
                        style={{margin: '10px'}}
                        size='small'
                        color='secondary'
                        variant='contained'
                    >
                        <LaunchIcon style={{paddingRight: '10px'}}/> Start
                    </Button>
                </ListItem>
                <ListItem>
                    <ListItemAvatar>
                        <Avatar className={classes.largeAvatar}>
                            <NegroniIcon iconClass="add-square-icon" size="large" color="#ddd1f0" />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        className={classes.guidedTourText}
                        primary='Create Entity Tour'
                    />
                    <Button
                        onClick={_ => {
                            startTour('CreateEntityTour')
                            onClose()
                        }}
                        style={{margin: '10px'}}
                        size='small'
                        color='secondary'
                        variant='contained'
                    >
                        <LaunchIcon style={{paddingRight: '10px'}}/> Start
                    </Button>
                </ListItem>
            </List>
        </DialogContent>
    </Dialog>
}

export default observer(GuidedTours)