import config from "../../../config";
import HttpClient, {CancelToken} from "axios";
import DocumentItem from "../../observables/DocumentItem";
import PaginatedResponse from "../PaginatedResponse";
import { rawPathToEntityPath } from "../EntityService";
import { rawPathToManifestPath } from "../ManifestService";
import { mapToPackage } from "../PackageService";
import { CDMDocumentType } from "../../observables/CDMDocument";

export class AdminOrganisationService {
    readonly BASE = `/${config.api_version}/cdm/admin/org`;

    getOrgs(limit: number = 1000, page: number = 0, search: string = '', cancelToken?: CancelToken) : Promise<PaginatedResponse<DocumentItem[]>> {
        return HttpClient.get(`${this.BASE}`, {
            params: {
                limit,
                page,
                search,
            },
            cancelToken
        }).then(({data}) => ({
            totalElements: data.totalElements,
            size: data.size,
            page: data.page,
            status: data.result,
            message: data.message,
            result: data.organisations.map(rawOrgToDocument),
        }));
    }

    getOrgSummary(org: string, cancelToken?: CancelToken) {
        return HttpClient.get(`${this.BASE}/${org}/summary`, {cancelToken})
            .then(({data}) => data);
    }

    getEntitiesByOrg(org: string, limit: number = 1000, page: number = 0, search: string = '', cancelToken?: CancelToken) : Promise<PaginatedResponse<DocumentItem[]>> {
        return HttpClient.get(`${this.BASE}/${org}/entities`, {
            params: {
                limit,
                page,
                search,
            },
            cancelToken,
        }).then(({data}) => ({
            totalElements: data.totalElements,
            size: data.size,
            page: data.page,
            status: data.result,
            message: data.message,
            result: data.entities.map(rawPathToEntityPath),
        }));
    }

    getManifestsByOrg(org: string, limit: number = 1000, page: number = 0, search: string = '', excludeManifest: number | undefined, cancelToken?: CancelToken) : Promise<PaginatedResponse<DocumentItem[]>> {
        return HttpClient.get(`${this.BASE}/${org}/manifests`, {
            params: {
                limit,
                page,
                search,
                excludeManifest,
            },
            cancelToken,
        }).then(({data}) => ({
            totalElements: data.totalElements,
            size: data.size,
            page: data.page,
            status: data.result,
            message: data.message,
            result: data.manifests.map(rawPathToManifestPath),
        }));
    }

    getPackagesByOrg(org: string, cancelToken?: CancelToken) {
        return HttpClient.get(`${this.BASE}/${org}/packages`, {cancelToken})
            .then(({data}) => data.packages.map(mapToPackage))
    }
}

export const rawOrgToDocument = (raw: any): DocumentItem => {
    return {
        name: `[${raw?.code}] ${raw?.name}`,
        path: raw?.code,
        documentType: CDMDocumentType.ORG,
        id: raw?.id || -1,
        isDefault: false,
        type: 'documentItem',
    } as DocumentItem;
}