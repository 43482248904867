import CDMDocument, { CDMDocumentType } from "./CDMDocument";
import { observable } from "mobx";

export default class ManifestWorkspace implements CDMDocument {
    @observable id: number;
    @observable name: string;
    private _parent: any;
    readonly documentType = CDMDocumentType.MANIFEST_WORKSPACE;
    readonly type = 'documentItem';

    constructor(id: number, name: string) {
        this.id = id;
        this.name = name;
        this._parent = null;
    }

    set parent(parent: any) {
        this._parent = parent;
    }

    get parent() {
        return this._parent;
    }

    get label() {
        return this.name;
    }
}