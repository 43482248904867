import config from "../../../config";
import HttpClient, {CancelToken} from "axios";
import { AdminCreateManifest } from "../../observables/Manifest";
import { mapToManifest } from "../ManifestService";

export class AdminManifestService {
    readonly BASE = `/${config.api_version}/cdm/admin/manifest`;

    createManifest(manifest: AdminCreateManifest, cancelToken?: CancelToken) {
        return HttpClient.post(this.BASE, manifest, {
            headers: {'Content-Type': 'application/json'},
            cancelToken,
        }).then(({data}) => mapToManifest(data.manifest.id, data.manifest))
    }
}