import React, {useEffect, useRef, useState} from "react";
import Manifest, { ApiTypes, ConnectionPoolType, MartiniProperties, ApiSecurity, AggregateType } from "../../../core/observables/Manifest";
import {observer} from "mobx-react";
import ManifestConfiguration from "./ManifestConfiguration"
import {_noop} from "../../../util";
import { Formik } from "formik";
import useService from "../../service/useService";

interface ManifestConfigurationContainerProps {
    manifest: Manifest;
    upsertConfiguration: (manifest: Manifest, configuration: MartiniProperties) => Promise<string | undefined>;
}

const ManifestConfigurationContainer: React.FC<ManifestConfigurationContainerProps> = ({
    manifest,
    upsertConfiguration,
}) => {
    const [currentBuild, setCurrentBuild] = useState<MartiniProperties>({
        manifestName: manifest.name,
        packageProperties: {
            connectionPoolInfo: {
                type: ConnectionPoolType.PostgreSQL,
                databaseName: '',
                password: '',
                username: '',
                url: '',
            },
            customProperties: [{
                name: '',
                value: '',
            }],
        },
        configurationBuild: {
            apiConfiguration: {
                type: [ApiTypes.REST],
                security: ApiSecurity.NONE,
                excludeOperations: [],
            },
            sqlConfiguration: {
                applyAutoIncrement: true,
                applyCoalesce: false,
                applyLimitOffset: false,
                applyJoin: false,
            },
            serviceConfiguration: {
                applyMultiTenancy: false,
                applyCustomField: false,
                applySot: false,
                sotEntities: [],
            },
            aggregateQueries: [],
        },
    });

    return <Formik
    initialValues={currentBuild}
    initialTouched={{
        manifestName: true,
        packageProperties: {
            connectionPoolInfo: {
                databaseName: true,
                password: true,
                type: true,
                url: true,
                username: true,
            }
        }
    }}
    enableReinitialize={true}
    validateOnMount={true}
    validate={async (values: MartiniProperties) => {
    }}
    onSubmit={_noop}
>
    {props => <ManifestConfiguration
        manifest={manifest}
        upsertConfiguration={upsertConfiguration}
        values={props.values}
        setFieldValue={props.setFieldValue}
        handleBlur={props.handleBlur}
        handleChange={props.handleChange}
    />}
</Formik>;
}

export default observer(ManifestConfigurationContainer);