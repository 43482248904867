import React, {useEffect, useState} from "react";
import {
    Button,
    createStyles,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    fade,
    InputBase,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Grid
} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import Toolbar from "@material-ui/core/Toolbar";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import StarIcon from '@material-ui/icons/Star';
import {makeStyles} from "@material-ui/core/styles";
import TypeAttribute from "../../core/observables/TypeAttribute";
import {observer} from "mobx-react";
import ContextMenu, {Position} from "../ContextMenu";
import {DataType} from "../../core/dataType";
import Select from "react-select";
import * as yup from "yup";
import {NAME_PATTERN} from "../../core/constants";
import { NegroniIcon } from "../icon/NegronIIcon";

interface TypeAttributeViewProps {
    typeAttributes: TypeAttribute[],
    onSelectTypeAttribute?: (typeAttribute: TypeAttribute | null) => void,
    readOnly?: boolean,
    onAddTypeAttribute: (typeAttribute: TypeAttribute) => void,
    onDeleteTypeAttribute: () => void,
    primaryKey?: string,
}

const TypeAttributeViewStyles = makeStyles(theme => createStyles({
    search: {
        display: 'flex',
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: "#DDD1F0",
        '&:hover': {
            backgroundColor: fade("#DDD1F0", 0.2),
        },
        width: '100%',
        margin: '10px',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(1),
            width: 'auto',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '0.1rem',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
    primaryKeyIcon: {
        color: "var(--yellow-color)",
        fontSize: '1.4rem',
    },
}));

const TypeAttributeView: React.FC<TypeAttributeViewProps> = ({
    typeAttributes,
    onSelectTypeAttribute,
    readOnly,
    onAddTypeAttribute,
    onDeleteTypeAttribute,
    primaryKey,
}) => {
    const classes = TypeAttributeViewStyles();
    const [position, setPosition] = useState<Position | null>(null);
    const [selectedTypeAttributes, setSelectedTypeAttributes] = useState<TypeAttribute[]>([]);
    const [selection, setSelection] = useState<TypeAttribute | null>(null);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(15);
    const [search, setSearch] = useState('');
    const [filteredAttributes, setFilteredAttributes] = React.useState<TypeAttribute[]>();

    useEffect(() => {
        setFilteredAttributes(typeAttributes.filter(typeAttribute => typeAttribute.name.toLowerCase().includes(search.toLowerCase())))
        setPage(0);
    }, [search, typeAttributes]);

    function handleClick(typeAttribute: TypeAttribute) {
        if (readOnly) return;

        const selectedIndex = selectedTypeAttributes.indexOf(typeAttribute)
        let newSelected: TypeAttribute[] = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selectedTypeAttributes, typeAttribute);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selectedTypeAttributes.slice(1));
        } else if (selectedIndex === selectedTypeAttributes.length - 1) {
            newSelected = newSelected.concat(selectedTypeAttributes.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selectedTypeAttributes.slice(0, selectedIndex),
                selectedTypeAttributes.slice(selectedIndex + 1),
            );
        }

        if (onSelectTypeAttribute){
             if (newSelected.length === 1) onSelectTypeAttribute(newSelected[0]);
             else onSelectTypeAttribute(null);
        }

        setSelectedTypeAttributes(newSelected);
    }

    function handleContextMenu(e: React.MouseEvent<HTMLTableRowElement>, typeAttribute: TypeAttribute) {
        setSelection(typeAttribute);
        if (readOnly) return;

        e.preventDefault();
        setPosition({
            mouseX: e.clientX,
            mouseY: e.clientY,
        });
    }

    function handleCloseContextMenu() {
        setPosition(null);
    }

    const isSelected = (typeAttribute: TypeAttribute) => selectedTypeAttributes.indexOf(typeAttribute) !== -1;

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    function deleteTypeAttributes(attributes: TypeAttribute[]) {
        attributes.forEach(typeAttribute => {
            const deleteIndex = typeAttributes.indexOf(typeAttribute, 0);
            typeAttributes.splice(deleteIndex, 1);
        });
        setSelectedTypeAttributes([]);
        setSelection(null);
        if (onSelectTypeAttribute)
            onSelectTypeAttribute(null);
        onDeleteTypeAttribute();
        setFilteredAttributes(undefined);
    }

    function handleDeleteButton() {
        if (selectedTypeAttributes.length > 0) {
            deleteTypeAttributes(selectedTypeAttributes)
        }
    }

    function handleDeleteTypeAttribute() {
        if (selection) {
            deleteTypeAttributes([selection])
        }
    }

    return <div>
        <div style={{display: 'flex', justifyContent: 'flex-end'}}>
            {typeAttributes?.length > 0 &&
            <div className={classes.search}>
                <div className={classes.searchIcon}>
                    <NegroniIcon iconClass="search-icon" />
                </div>
                <InputBase
                    placeholder="Search…"
                    classes={{
                        root: classes.inputRoot,
                        input: classes.inputInput,
                    }}
                    inputProps={{'aria-label': 'search'}}
                    value={search}
                    onChange={e => setSearch(e.target.value)}
                />
            </div>
            }
        </div>
        {typeAttributes.length > 0 ? <>
                <TableContainer style={{width: '100%', maxHeight: 540}}>
                    {selectedTypeAttributes.length > 0 && <Toolbar className='table-toolbar' variant='dense'>
                        <Typography color="inherit" variant="subtitle1" component="div">
                            {selectedTypeAttributes.length} Selected
                        </Typography>
                        <Tooltip title="Delete">
                            <IconButton onClick={handleDeleteButton} color="inherit">
                               <NegroniIcon iconClass="delete-icon" color="#fff" />
                            </IconButton>
                        </Tooltip>
                    </Toolbar>}
                    <Table size='small' stickyHeader>
                        <TableHead>
                            <TableRow>
                                {!readOnly && <TableCell style={{width: '5%'}}/>}
                                <TableCell style={{width: '70%'}}>Name</TableCell>
                                <TableCell style={{width: readOnly ? '30%' : '25%'}}>Type</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {typeAttributes.filter(typeAttribute => typeAttribute.name.toLowerCase().includes(search.toLowerCase()))
                                .sort((b:any, a:any) => a.isPrimaryKey - b.isPrimaryKey)
                                .slice(page * rowsPerPage, (page * rowsPerPage) + rowsPerPage)
                                .map((typeAttribute, index) => (
                                    <TableRow
                                        onClick={_ => handleClick(typeAttribute)}
                                        hover
                                        key={index}
                                        style={{cursor: 'pointer'}}
                                        onContextMenu={e => handleContextMenu(e, typeAttribute)}
                                        selected={!!selectedTypeAttributes.find(ta => ta.name === typeAttribute.name)}
                                    >
                                        {!readOnly ? <TableCell padding="checkbox">
                                            <Checkbox
                                                checked={isSelected(typeAttribute)}
                                                inputProps={{'aria-label': 'Select type attribute'}}
                                            />
                                        </TableCell> : undefined}
                                        <TableCell>
                                            {typeAttribute.isPrimaryKey ?
                                            <Grid container direction="row" alignItems="center">
                                                <StarIcon className={classes.primaryKeyIcon}/> {typeAttribute.name}
                                            </Grid>
                                            : typeAttribute.name
                                            }
                                        </TableCell>
                                        <TableCell>
                                            {typeAttribute.dataType}
                                        </TableCell>
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[15, 30, 50, 100, 200]}
                    component='div'
                    count={filteredAttributes?.length || typeAttributes.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    nextIconButtonProps={(filteredAttributes?.length || typeAttributes.length) <= rowsPerPage ? { style: { display: "none" } } : undefined}
                    backIconButtonProps={(filteredAttributes?.length || typeAttributes.length) <= rowsPerPage ? { style: { display: "none" } } : undefined}
                />
                <ContextMenu handleClose={handleCloseContextMenu} position={position}>
                    <MenuItem
                        disabled={selection === null}
                        dense
                        onClick={_ => {
                            handleCloseContextMenu();
                            handleDeleteTypeAttribute();
                        }}
                    >
                         <NegroniIcon iconClass="delete-icon" /> Delete
                    </MenuItem>
                </ContextMenu></> :
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                height: '100%',
                padding: '10px'
            }}>No type attributes to display</div>}

    </div>
};

export interface NewTypeAttributeDialogProp {
    existingNames: string[];
    open: boolean;
    onClose: () => void;
    defaultName?: string;
    onFinish: (name: string, dataType: DataType) => void;
}

export const NewTypeAttributeDialog: React.FC<NewTypeAttributeDialogProp> = ({existingNames, open, onClose, defaultName, onFinish}) => {
    const [name, setName] = useState(defaultName || 'new_type_attribute');
    const [dataType, setDataType] = useState<DataType>(DataType.STRING);
    const [nameInput, setNameInput] = useState<HTMLInputElement | undefined>();
    const dataTypeOptions = Object.values(DataType).map(value => ({label: value, value}));
    const [nameError, setNameError] = useState<string>('');

    useEffect(() => {
        if (open && nameInput)
            nameInput.select();
    }, [open, nameInput]);

    useEffect(() => {
        if (open) {
            setName(defaultName || 'new_type_attribute');
            setDataType(DataType.STRING);
            validateName();
        }
    }, [open]);
    useEffect(() => validateName(), [name]);

    const handleFinish = () => {
        onClose();
        onFinish(name, dataType);
    };

    const validateName = () => {
        try {
            yup.string()
                .required()
                .min(TypeAttribute.MIN_NAME_LENGTH)
                .max(TypeAttribute.MAX_NAME_LENGTH)
                .matches(NAME_PATTERN, 'this must only contain alphanumeric and no underscore consecutively')
                .notOneOf(existingNames)
                .validateSync(name);
            setNameError('');
        } catch (e) {
            setNameError(e.message);
        }
    }

    return <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{overflowY: 'visible'}}
    >
        <DialogTitle id="alert-dialog-title">New Type Attribute</DialogTitle>
        <DialogContent dividers style={{overflowY: 'hidden', maxWidth: '500px', width: '500px', minHeight: '500px'}}>
            <DialogContentText>
                Enter the name of your new type attribute
            </DialogContentText>
            <TextField
                inputRef={setNameInput}
                autoFocus
                margin="dense"
                label="Type Attribute Name"
                fullWidth
                variant='filled'
                value={name}
                onChange={e => setName(e.target.value)}
                onKeyPress={e => {
                    if (nameError.length === 0 && e.key === 'Enter') handleFinish();
                }}
                helperText={nameError}
                error={nameError.length > 0}
            />
            <Select
                isClearable
                isSearchable
                placeholder='Data Type'
                value={{label: dataType, value: dataType}}
                onChange={(option: any) => setDataType(option?.value || DataType.STRING)}
                options={dataTypeOptions}
                menuPortalTarget={document.body}
                styles={{menuPortal: base => ({...base, zIndex: 9999})}}
                menuPlacement='auto'
                maxMenuHeight={200}
            />
        </DialogContent>
        <DialogActions>
            <Button onClick={onClose}>
                Cancel
            </Button>
            <Button
                disabled={nameError.length > 0}
                onClick={handleFinish}
                variant='contained'
                color='secondary'
                disableElevation
            >
                Finish
            </Button>
        </DialogActions>
    </Dialog>;
}

export default observer(TypeAttributeView);