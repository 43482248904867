import config from "../../config";
import HttpClient, { CancelToken } from "axios";
import { MockDataItem } from "../../component/navigator/Navigator";
import DocumentItem from "../observables/DocumentItem";
import { CDMDocumentType } from "../observables/CDMDocument";
import { EntityNode } from "../../component/entity/tree/EntityTree";

export class NavigatorService {
    readonly BASE = `/${config.api_version}/cdm/navigator`;
    readonly ADMIN_BASE = `/${config.api_version}/cdm/admin/navigator/generate`;

    getNavigator(cancelToken?: CancelToken) {
        return HttpClient.get(`${this.BASE}`, {cancelToken})
            .then(({data}) => data);
    }

    getVersion(cancelToken?: CancelToken) {
        return HttpClient.get(`${this.BASE}/version`, {cancelToken})
            .then(({data}) => data);
    }

    regenerateNavigator(cancelToken?: CancelToken) {
        return HttpClient.post(`${this.ADMIN_BASE}`, {cancelToken})
            .then(({data}) => data);
    }

    constructNavigator(data: any[], search: string = ''): MockDataItem {
        let coreNavigator = new MockDataItem('core', 'Core', '');
        data.filter(e => e.name === 'Core').map(node => {
            if(node?.nodes.length > 0) {
                coreNavigator.children = this.constructChildren(node?.nodes, search);
            }
        });
        return coreNavigator;
    }

    constructChildren(data: any[], search: string = ''): any[] {
        const currentSearch = search.toLowerCase();
        let childrenItems: any[] = [];
        let entityItems = [] as any;
        let manifestItems = [] as any;
        let folderItems = [] as any;
        data.map(node => {
            if(node?.type === 'entity') {
                let entityItem = {
                    name: node?.name,
                    path: '',
                    documentType: CDMDocumentType.ENTITY,
                    id: node?.id || -1,
                    isDefault: true,
                    type: 'documentItem',
                } as DocumentItem;
                if(entityItem.name && entityItem.name.toLowerCase().indexOf(currentSearch) > -1) {
                    entityItems.push(entityItem);
                }
            } else if(node?.type === 'manifest') {
                let manifestItem = {
                    name: node?.name,
                    path: '',
                    documentType: CDMDocumentType.MANIFEST,
                    id: node?.id || -1,
                    isDefault: true,
                    type: 'documentItem',
                } as DocumentItem;
                if(manifestItem.name && manifestItem.name.toLowerCase().indexOf(currentSearch) > -1) {
                    manifestItems.push(manifestItem);
                }
            } else if(node?.type === 'folder') {
                let mockItem = new MockDataItem(node?.name, node?.name, 'folder');
                if(node?.nodes.length > 0) {
                    mockItem.children = this.constructChildren(node?.nodes, currentSearch);
                    if(mockItem.children.length === 1 &&
                    !mockItem.children.some(node => node?.documentType === CDMDocumentType.ENTITY) &&
                    !mockItem.children.some(node => node?.documentType === CDMDocumentType.MANIFEST) &&
                    mockItem.children.some(node => node instanceof MockDataItem)) {
                        mockItem.label = mockItem.label + ' / ' + mockItem.children[0]?.label;
                    }
                }
                if(mockItem.children.length > 0) {
                    if(mockItem.children.length === 1 &&
                    !mockItem.children.some(node => node?.documentType === CDMDocumentType.ENTITY) &&
                    !mockItem.children.some(node => node?.documentType === CDMDocumentType.MANIFEST) &&
                    mockItem.children.some(node => node instanceof MockDataItem)) {
                        mockItem.children = this.getFolderChildren(mockItem.children[0]);
                        folderItems.push(mockItem);
                    } else {
                        folderItems.push(mockItem);
                    }
                }
            }
        });
        childrenItems.push(...folderItems);
        childrenItems.push(...entityItems);
        childrenItems.push(...manifestItems);
        return childrenItems;
    }

    getFolderChildren(folder: MockDataItem): any[] {
        let childrenItems = [] as any;
        if(folder.children.length === 1 &&
        !folder.children.some(node => node?.documentType === CDMDocumentType.ENTITY) &&
        !folder.children.some(node => node?.documentType === CDMDocumentType.MANIFEST) &&
        folder.children.some(node => node instanceof MockDataItem)) {
            childrenItems = this.getFolderChildren(folder.children[0]);
        } else {
            childrenItems = folder.children;
        }
        return childrenItems;
    }

    constructEntityNavigator(data: any[], search: string = ''): EntityNode {

        let coreEntity = { id: -1, name: 'Core', type: 'folder' } as EntityNode
        data.filter(e => e.name === 'Core').map(node => {
            if(node?.nodes.length > 0) {
                coreEntity.children = this.constructEntityChildren(node?.nodes, search);
            }
        });
        return coreEntity
    }

    constructEntityChildren(data: any[], search: string = ''): EntityNode[] {
        const currentSearch = search.toLowerCase();
        let childrenItems: any[] = [];
        let entityItems = [] as any;
        let folderItems = [] as any;

        data.map(node => {
            if(node?.type === 'entity') {
                let entityItem = {
                    name: node?.name,
                    id: node?.id || -1,
                    type: 'entity',
                } as EntityNode;
                if(entityItem.name && entityItem.name.toLowerCase().indexOf(currentSearch) > -1) {
                    entityItems.push(entityItem);
                }
            } else if(node?.type === 'folder') {
                let folderItem = {
                    name: node?.name,
                    id: node?.id || -1,
                    type: 'folder',
                } as EntityNode
                if(node?.nodes.length > 0) {
                    folderItem.children = this.constructEntityChildren(node?.nodes, currentSearch);
                    if(folderItem.children.length === 1 &&
                    !folderItem.children.some(node => node?.type === 'folder') &&
                    !folderItem.children.some(node => node?.type === 'entity')) {
                        folderItem.name = folderItem.name + ' / ' + folderItem.children[0]?.name;
                    }             
                }
                if(folderItem.children) {
                    if(folderItem.children.length > 0) {
                        folderItems.push(folderItem)
                    }
                }
            }
        });
        childrenItems.push(...folderItems);
        childrenItems.push(...entityItems);
        return childrenItems;
    }
}