import React, {useEffect} from "react";
import PropertiesView from "./PropertiesView";
import useProperties from "./useProperties";
import useContent from "../content/useContent";
import {_noop} from "../../util";
import EntityPropertySourceProvider from "./EntityPropertySourceProvider";
import ManifestPropertySourceProvider from "./ManifestPropertySourceProvider";
import useService from "../service/useService";
import TypeAttributePropertySourceProvider from "./TypeAttributePropertySourceProvider";
import EntityAttributePropertySourceProvider from "./EntityAttributePropertySourceProvider";
import {useSnackbar} from "notistack";
import OrganisationPropertySourceProvider from "./OrganisationPropertySourceProvider";
import { CDMDocumentType } from "../../core/observables/CDMDocument";

enum PROPS_KEY {
    ENTITY = 'entity',
    MANIFEST = 'manifest',
    TYPE_ATTRIBUTE = 'type_attribute',
    ENTITY_ATTRIBUTE = 'entity_attribute',
    ORG = 'org',
}

const PropertiesContainer = () => {
    const {onPropertySourceApply, setOnPropertySourceApply, propertySource, registerPropertySourceProvider, unregisterPropertySourceProvider, getPropertyIgnoreDirtyPredicates} = useProperties();
    const {setDirty, content} = useContent();
    const {entityService, manifestService} = useService();
    const {enqueueSnackbar} = useSnackbar();

    useEffect(() => {
        setOnPropertySourceApply((source, name, value) => {
            source.setProperty(name, value)
                .then(_ => {
                    if(source?.documentType === CDMDocumentType.ENTITY_ATTRIBUTE) {
                        enqueueSnackbar('Successfully updated entity attribute.', {variant: 'success'});
                    }
                })
                .catch(error => enqueueSnackbar(error, {variant: 'error'}));
            if (!getPropertyIgnoreDirtyPredicates().find(propertyIgnoreDirtyPredicate => propertyIgnoreDirtyPredicate(source)))
                setDirty(true);
        });

        registerPropertySourceProvider(PROPS_KEY.ORG, new OrganisationPropertySourceProvider());
        registerPropertySourceProvider(PROPS_KEY.ENTITY, new EntityPropertySourceProvider(entityService));
        registerPropertySourceProvider(PROPS_KEY.MANIFEST, new ManifestPropertySourceProvider(manifestService));
        registerPropertySourceProvider(PROPS_KEY.TYPE_ATTRIBUTE, new TypeAttributePropertySourceProvider());
        registerPropertySourceProvider(PROPS_KEY.ENTITY_ATTRIBUTE, new EntityAttributePropertySourceProvider(entityService));
        return () => {
            setOnPropertySourceApply(_noop);
            unregisterPropertySourceProvider(PROPS_KEY.ENTITY);
            unregisterPropertySourceProvider(PROPS_KEY.MANIFEST);
            unregisterPropertySourceProvider(PROPS_KEY.TYPE_ATTRIBUTE);
            unregisterPropertySourceProvider(PROPS_KEY.ENTITY_ATTRIBUTE);
            unregisterPropertySourceProvider(PROPS_KEY.ORG);
        };
    }, []);

    return <PropertiesView
        source={content === null ? null : propertySource}
        onApply={onPropertySourceApply}
    />
}

export default PropertiesContainer;