import config from "../../../config";
import HttpClient, {CancelToken} from "axios";

export class AdminReloadService {

    readonly BASE = `/${config.api_version}/cdm/admin/reload`;

    reloadManifest(manifestId: number, cancelToken?: CancelToken) {
        return HttpClient.put(`${this.BASE}/manifest/${manifestId}`, {cancelToken})
            .then(({data}) => data);
    }

    reloadEntity(entityId: number, cancelToken?: CancelToken) {
        return HttpClient.put(`${this.BASE}/entity/${entityId}`, {cancelToken})
            .then(({data}) => data);
    }
}