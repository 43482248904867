import React, {useEffect, useRef, useState} from "react";
import EntityView from "./EntityView";
import Entity, {mapToUpdateEntity, UpdateEntityContent} from "../../core/observables/Entity";
import {NewTypeAttributeDialog} from "./TypeAttributeView";
import {NewEntityAttributeDialog, NewEntityAttributeValue} from "./EntitiyAttributeView";
import CDMDocument, {CDMDocumentType} from "../../core/observables/CDMDocument";
import DocumentItem from "../../core/observables/DocumentItem";
import {DataType} from "../../core/dataType";
import {uniqueName} from "../../util";
import TypeAttribute from "../../core/observables/TypeAttribute";
import {useSnackbar} from "notistack";
import useContent from "../content/useContent";
import axios from "axios";
import useService from "../service/useService";
import useProperties from "../properties/useProperties";
import {PaginatedMockItemTree} from "../navigator/Navigator";
import Workspace from "../../core/observables/Workspace";
import {CDMManager} from "../../core/CDMManager";
import {Trait} from "../../core/trait";
import {Purpose} from "../../core/purpose";
import EntityAttribute from "../../core/observables/EntityAttribute";

interface EntityViewContainerProps {
    entity: Entity;
    onChange: (document: CDMDocument | null, message?: string) => void;
    refreshStructure: () => void;
    viewMode: string;
    entityOrg?: string;
    orgLabel?: string;
}

const EntityViewContainer: React.FC<EntityViewContainerProps> = ({
    entity,
    onChange,
    refreshStructure,
    viewMode,
    entityOrg,
    orgLabel
}) => {
    const {entityService, manifestService, adminReloadService, adminEntityService} = useService();
    const [openNewTypeAttributeDialog, setOpenNewTypeAttributeDialog] = useState(false);
    const [openNewEntityAttributeDialog, setOpenNewEntityAttributeDialog] = useState(false);
    const {enqueueSnackbar} = useSnackbar();
    const {setDirty, addOnSaveChangesListener, removeOnSaveChangesListener} = useContent();
    const isMounted = useRef(false);
    const cancelToken = useRef(axios.CancelToken.source());
    const {setProperty} = useProperties();

    useEffect(() => {
        isMounted.current = true;
        const saveChangesListener = async (document: CDMDocument): Promise<boolean> => {
            if (document.documentType === CDMDocumentType.ENTITY) {
                await entityService.updateEntity(entity.id, mapToUpdateEntity(entity));
                enqueueSnackbar('Entity saved successfully!', {variant: 'success'});
                return true;
            }
            return false;
        }
        addOnSaveChangesListener(saveChangesListener);
        return () => {
            cancelToken.current?.cancel();
            isMounted.current = false;
            removeOnSaveChangesListener(saveChangesListener);
        };
    }, []);


    const handleAddEntityToManifest = (manifest: DocumentItem, entity: Entity): Promise<string | undefined> => {
        cancelToken.current = axios.CancelToken.source();
        return manifestService.addEntity(manifest.id, [entity.id], cancelToken.current?.token)
            .then(manifest => {
                if (isMounted.current) {
                    enqueueSnackbar('Successfully added entity to manifest.', {variant: 'success'});
                    if(viewMode === 'Admin') {
                        const orgWorkspace = entity?.parent?.parent;
                        manifest.parent = new PaginatedMockItemTree('Manifests', orgWorkspace);
                    } else {
                        const workspace = new Workspace(CDMManager.USER_DEFINED, false);
                        manifest.parent = new PaginatedMockItemTree('Manifests', workspace);
                    }
                    localStorage.setItem(CDMManager.NEGRONI_RESET_SELECTED_NODE, 'true');
                    onChange(manifest);
                }
            })
            .catch(error => error || 'Failed to add entity to manifest');
    }

    function handleAddTypeAttribute(name: string, dataType: DataType, entity: Entity) {
        const newTypeAttributeName = uniqueName(name, entity.typeAttributes.map(ta => ta.name));
        const newTypeAttribute = new TypeAttribute(newTypeAttributeName, dataType, entity.isDefault);
        entity.addTypeAttribute(newTypeAttribute);
        setDirty(true);
        setProperty(newTypeAttribute);
    }

    function handleAddTypeAttributeClicked() {
        setOpenNewTypeAttributeDialog(true);
    }

    const handleAddEntityAttribute = async ({
        foreignKeyTypeAttribute,
        foreignKeyDataType,
        targetEntityAttribute,
        associatedEntity
    }: NewEntityAttributeValue, entity: Entity): Promise<void> => {
        localStorage.setItem(CDMManager.NEGRONI_RESET_SELECTED_NODE, 'true');
        return entityService.createEntityAttribute(entity.id, {
            associatedEntityId: associatedEntity?.id,
            associatedEntityName: associatedEntity?.name,
            associatedEntityPath: associatedEntity?.path,
            associatedEntityTypeAttribute: targetEntityAttribute,
            description: '',
            simple: 'false',
            traitReference: Trait.IS_IDENTIFIED_BY,
            foreignKeyAttribute: {
                typeAttributeName: foreignKeyTypeAttribute,
                dataType: foreignKeyDataType,
                purpose: Purpose.HAS_A,
            },
            version: entity.currentVersion
        }).then(async entityAttribute => {
            entity.addEntityAttribute(entityAttribute);
            const index = entity.typeAttributes.findIndex(attrib => attrib.name === foreignKeyTypeAttribute);
            if (index !== -1) entity.typeAttributes.splice(index, 1);
            setProperty(entityAttribute);
            enqueueSnackbar(`Successfully added '${foreignKeyTypeAttribute}' entity attribute!`, {variant: 'success'});
            await handleSaveEntity(entity, false);
        });
    }

    function handleAddEntityAttributeClicked() {
        setOpenNewEntityAttributeDialog(true);
    }

    function handleDeleteEntity(entity: Entity) {
        let deleteEntity;
        if (entity.targetManifest) {
            deleteEntity = manifestService.deleteEntity(entity.targetManifest, entity.id)
        } else {
            deleteEntity = entityService.deleteEntity(entity.id);
        }

        deleteEntity.then(_ => {
            localStorage.removeItem(CDMManager.NEGRONI_CURRENT_SELECTED_NODE);
            enqueueSnackbar('Successfully deleted entity!', {variant: 'success'});
            setProperty(null);
            onChange(null);
        }).catch(error => {
            enqueueSnackbar(error || 'Failed to delete entity', {variant: 'error'});
        });
    }

    function handleSaveEntity(entity: Entity, showSnackbar: boolean = true): Promise<string | undefined> {
        localStorage.setItem(CDMManager.NEGRONI_RESET_SELECTED_NODE, 'true');
        return entityService.updateEntity(entity.id, mapToUpdateEntity(entity))
            .then(_ => {
                setDirty(false);
                if (showSnackbar) enqueueSnackbar('Entity saved successfully!', {variant: 'success'});
                onChange(entity);
            }).catch(error => error || 'Failed to save entity.');
    }

    const handleDeleteEntityAttribute = (entityAttributes: EntityAttribute[]): Promise<void> => {
        return Promise.all(entityAttributes.map(entityAttribute => {
            if (entityAttribute?.associatedEntity?.id)
                return entityService.deleteEntityAttribute(entity.id, entityAttribute.associatedEntity.id);
            return undefined;
        }).filter(promise => promise !== undefined))
            .then(async _ => {
                enqueueSnackbar('Successfully deleted entity attributes!', {variant: 'success'});
                entity.entityAttributes = entity.entityAttributes.filter( ( attrib ) => !entityAttributes.includes( attrib ) );
                await handleSaveEntity(entity, false);
            });
    }

    const handleReload = (entity: Entity) => {
        return adminReloadService.reloadEntity(entity.id);
    };

    const onUpdateEntityContent = (content: UpdateEntityContent, refresh: boolean): Promise<string | undefined> => {
        return adminEntityService.updateEntityContent(content)
            .then(response => {
                if(refresh) {
                    refreshStructure();
                }
                enqueueSnackbar(response?.message, {variant: 'success'}); 
                onChange(entity);
            })
            .catch(error => {
                enqueueSnackbar(error || 'Invalid entity json content', {variant: 'error'});
                return error || 'Invalid entity json content';
            });
    }

    return <>
        <EntityView
            setSelection={setProperty}
            entity={entity}
            onAddToManifest={handleAddEntityToManifest}
            onAddTypeAttribute={handleAddTypeAttributeClicked}
            onAddEntityAttribute={handleAddEntityAttributeClicked}
            onDeleteEntity={handleDeleteEntity}
            onDeleteEntityAttribute={handleDeleteEntityAttribute}
            onSave={handleSaveEntity}
            onChanges={_ => setDirty(true)}
            onReload={handleReload}
            onUpdateEntityContent={onUpdateEntityContent}
            viewMode={viewMode}
            entityOrg={entityOrg}
            orgLabel={orgLabel}
        />
        <NewTypeAttributeDialog
            existingNames={entity.typeAttributes.map(t => t.name)}
            open={openNewTypeAttributeDialog}
            onClose={() => setOpenNewTypeAttributeDialog(false)}
            onFinish={(name, dataType) => handleAddTypeAttribute(name, dataType, entity)}
        />
        <NewEntityAttributeDialog
            open={openNewEntityAttributeDialog}
            onClose={() => setOpenNewEntityAttributeDialog(false)}
            onFinish={value => handleAddEntityAttribute(value, entity)}
            entity={entity}
        />
    </>;
};

export default EntityViewContainer;