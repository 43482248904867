import {observable} from "mobx";
import {PackageStatus} from "../services/PackageService";

export default class Package {
    @observable id: number;
    @observable userId: number;
    @observable guid: string;
    @observable manifestName: string;
    @observable manifestPath: string;
    @observable status: PackageStatus = PackageStatus.FAILED;
    @observable versions: string[];
    @observable isDefault: boolean = true;

    constructor(id: number, userId: number, guid: string, manifestName: string, manifestPath: string, versions: string[]) {
        this.id = id;
        this.userId = userId;
        this.guid = guid;
        this.manifestName = manifestName;
        this.manifestPath = manifestPath;
        this.versions = versions;
    }
}