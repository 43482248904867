import {PropertySourceProvider} from "./PropertySourceProvider";
import {PropertySource} from "./PropertyDescriptor";
import {CDMDocumentType} from "../../core/observables/CDMDocument";
import TypeAttribute, {TypeAttributePropertySource} from "../../core/observables/TypeAttribute";
import Entity from "../../core/observables/Entity";

export default class TypeAttributePropertySourceProvider implements PropertySourceProvider {

    getPropertySource(property: any): PropertySource | null {
        const entity = property?.parent as Entity;
        return new TypeAttributePropertySource(property as TypeAttribute, entity.typeAttributes);
    }

    support(property: any): boolean {
        return property?.documentType === CDMDocumentType.TYPE_ATTRIBUTE && property?.parent?.documentType === CDMDocumentType.ENTITY;
    }

}