import React, {FC, useRef, useState} from "react";
import PropertiesContext from "./PropertiesContext";
import {PropertySourceProvider} from "./PropertySourceProvider";
import {PropertySource} from "./PropertyDescriptor";

export interface PropertiesContextValue {
    setProperty: (property: any) => void;
    propertySource: PropertySource | null;
    registerPropertySourceProvider: (name: string, propertySourceProvider: PropertySourceProvider) => void;
    unregisterPropertySourceProvider: (name: string) => void;
    setOnPropertySourceApply: (setPropertySourceApply: (source: PropertySource, name: string, value: any) => void) => void;
    onPropertySourceApply: (source: PropertySource, name: string, value: any) => void;
    addPropertyIgnoreDirtyPredicate: (key: string, predicate: (source: PropertySource) => boolean) => void;
    removePropertyIgnoreDirtyPredicate: (key: string) => void;
    getPropertyIgnoreDirtyPredicates: () => ((source: PropertySource) => boolean)[];
}

const PropertiesProvider: FC = ({children}) => {
    const propertySourceProviders = useRef<Map<string, PropertySourceProvider>>(new Map([]));
    const onPropertySourceApply = useRef<((source: PropertySource, name: string, value: any) => void) | undefined>(undefined);
    const [propertySource, setPropertySource] = useState<PropertySource | null>();
    const propertyIgnoreDirtyPredicates = useRef<Map<string, ((property: PropertySource) => boolean)>>(new Map([]));

    const setProperty = (property: any) => {
        const propertySourceProvider = Array.from(propertySourceProviders.current.values())
            .find(propertySourceProvider => propertySourceProvider.support(property));
        setPropertySource(propertySourceProvider?.getPropertySource(property) || null);
    };

    const registerPropertySourceProvider = (name: string, propertySourceProvider: PropertySourceProvider) => {
        propertySourceProviders.current.set(name, propertySourceProvider);
    }

    const unregisterPropertySourceProvider = (name: string) => {
        propertySourceProviders.current.delete(name);
    }

    const addPropertyIgnoreDirtyPredicate = (key: string, predicate: (source: PropertySource) => boolean) => {
        propertyIgnoreDirtyPredicates.current.set(key, predicate);
    }

    const removePropertyIgnoreDirtyPredicate = (key: string) => {
        propertyIgnoreDirtyPredicates.current.delete(key);
    };

    return <>
        <PropertiesContext.Provider value={{
            propertySource,
            setProperty,
            registerPropertySourceProvider,
            unregisterPropertySourceProvider,
            setOnPropertySourceApply: value => onPropertySourceApply.current = value,
            onPropertySourceApply: onPropertySourceApply.current,
            addPropertyIgnoreDirtyPredicate,
            removePropertyIgnoreDirtyPredicate,
            getPropertyIgnoreDirtyPredicates: () => Array.from(propertyIgnoreDirtyPredicates.current.values()),
        } as PropertiesContextValue}>
            {children}
        </PropertiesContext.Provider>
    </>;
};

export default PropertiesProvider;