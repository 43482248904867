import React, {createContext, createRef} from "react";
import {LoadContentValue} from "./useContent";
import CDMDocument, { CDMDocumentType } from "../../core/observables/CDMDocument";
import {_noop} from "../../util";
import { TriggerType } from "./ContentProvider";

export default createContext({
    content: <div/>,
    setLoadContent: (value: LoadContentValue | undefined) => {
    },
    setTriggerType: (value: TriggerType | undefined) => {
    },
    dirty: false,
    setDirty: (dirty: boolean) => {
    },
    busy: createRef(),
    addOnSaveChangesListener: (onSaveChangesListener: ((document: CDMDocument) => Promise<boolean>)) => {
    },
    removeOnSaveChangesListener: (onSaveChangesListener: (document: CDMDocument) => Promise<boolean>) => {
    },
    clearOnSaveChangesListener: _noop,
    setOnCancelWindow: (onCancelWindow: () => void) => {
    },
});