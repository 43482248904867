import {observable} from "mobx";
import {PropertyDescriptor, PropertySource} from "../../component/properties/PropertyDescriptor";
import {CellEditorProps, TextCellEditor} from "../../component/properties/CellEditor";
import React from "react";
import CDMDocument, {CDMDocumentType} from "./CDMDocument";
import {
    AsyncSelectCellEditor,
    PaginatedSelectCellEditor,
    SelectCellEditor
} from "../../component/properties/SelectCellEditor";
import {Purpose} from "../purpose";
import {DataType} from "../dataType";
import {Trait} from "../trait";
import {CreateEntityAttribute, EntityService, UpdateEntityAttribute} from "../services/EntityService";
import {Filter} from "../Filter";
import PaginatedResponse from "../services/PaginatedResponse";
import Entity from "./Entity";
import * as yup from "yup";
import {NAME_PATTERN} from "../constants";
import {_noop} from "../../util";

export default class EntityAttribute implements CDMDocument {

    static readonly MIN_NAME_LENGTH = 2;
    static readonly MAX_NAME_LENGTH = 50;
    static readonly PROP_ASSOCIATED_ENTITY = 'associatedEntity';
    static readonly PROP_ASSOCIATED_ENTITY_TYPE_ATTRIBUTE = 'associatedEntityTypeAttribute';
    static readonly PROP_DESCRIPTION = 'description';
    static readonly PROP_TRAIT_REFERENCE = 'traitReference';
    static readonly PROP_SIMPLE = 'simple';
    static readonly PROP_FOREIGN_KEY_NAME = 'foreignKeyName';
    static readonly PROP_FOREIGN_KEY_TYPE = 'foreignKeyTypeAttribute';
    static readonly PROP_FOREIGN_KEY_PURPOSE = 'foreignKeyPurpose';
    static readonly PROP_FOREIGN_KEY_DATATYPE = 'foreignKeyDataType';

    @observable associatedEntity?: AssociatedEntity;
    @observable associatedEntityTypeAttribute: string;
    @observable description: string = '';
    @observable traitReference: Trait = Trait.IS_IDENTIFIED_BY;
    @observable simple: boolean = false;
    @observable foreignKeyTypeAttribute: string;
    @observable foreignKeyPurpose: Purpose = Purpose.HAS_A;
    @observable foreignKeyDataType: DataType;
    @observable isDefault: boolean = true;
    private _parent: CDMDocument | undefined;
    readonly documentType = CDMDocumentType.ENTITY_ATTRIBUTE;

    constructor(associatedEntity: AssociatedEntity | undefined,
                associatedEntityTypeAttribute: string,
                attributeExplanation: string = '',
                foreignKeyTypeAttribute: string,
                foreignKeyDataType: DataType,
                isDefault: boolean = true) {
        this.associatedEntity = associatedEntity;
        this.associatedEntityTypeAttribute = associatedEntityTypeAttribute;
        this.description = attributeExplanation;
        this.foreignKeyTypeAttribute = foreignKeyTypeAttribute;
        this.foreignKeyDataType = foreignKeyDataType;
        this.isDefault = isDefault;
    }

    set parent(parent: CDMDocument | undefined) {
        this._parent = parent;
    }

    get parent() {
        return this._parent;
    }

    clone() {
        return new EntityAttribute(this.associatedEntity,
            this.associatedEntityTypeAttribute,
            this.description,
            this.foreignKeyTypeAttribute,
            this.foreignKeyDataType,
            this.isDefault);
    }

}

export class EntityAttributePropertySource implements PropertySource {
    private readonly entityAttribute: EntityAttribute;
    private readonly descriptors: PropertyDescriptor[];
    private readonly entityService: EntityService;
    documentType = CDMDocumentType.ENTITY_ATTRIBUTE;

    constructor(entityAttribute: EntityAttribute, entityAttributes: EntityAttribute[], entityService: EntityService) {
        this.entityAttribute = entityAttribute;
        this.entityService = entityService;

        async function handleTargetEntityAttributeOptions() {
            if (!entityAttribute.associatedEntity)
                return [];
            return await entityService.getEntityById(entityAttribute.associatedEntity.id)
                .then(entity => entity.typeAttributes.map(typeAttr => ({label: typeAttr.name, value: typeAttr.name}))
                    .sort((a, b) => a.label.localeCompare(b.label)));
        }

        const dataTypeOptions = Object.values(DataType)
            .map(value => ({label: value, value,}))
            .sort((a, b) => a.label.localeCompare(b.label));

        this.descriptors = [
            {
                readOnly: true,
                defaultValue: this.entityAttribute?.associatedEntity,
                displayName: 'Associated Entity',
                description: 'The entity associated with attribute.',
                hasDefaultValue: true,
                name: 'associatedEntity',
                value: this.entityAttribute?.associatedEntity,
                displayValue: this.entityAttribute?.associatedEntity?.name,
                target: this.entityAttribute,
                getCellEditor: (props: CellEditorProps<any>) => <PaginatedSelectCellEditor
                    {...props}
                    getOptions={(page, limit, search, cancelTokenSource) => {
                        return entityService.getEntities(Filter.ALL, limit, page, search, -1, undefined, undefined, cancelTokenSource?.token)
                            .then(response => ({
                                ...response,
                                result: response.result.filter(entity => {
                                    const parentEntity = entityAttribute.parent as Entity;
                                    return entity.id !== parentEntity.id;
                                }).map(entity => ({
                                    label: entity.name, value: entity
                                }))
                            } as PaginatedResponse<{ label: string, value: any }[]>));
                    }}
                    onValueChanged={_noop}
                    valueMapper={value => value ? ({label: value.name, value: value.value}) : null}
                />,
                validate: async (value) => {
                    if (value) {
                        try {
                            // TODO: implement primary key for entity
                            // const entity = await entityService.getEntityById(value.id);
                            // if (!entity.primaryKey)
                            //     return 'Invalid selected entity.';
                        } catch (e) {
                        }
                        return undefined;
                    }
                    return 'Required';
                },
            },
            {
                readOnly: entityAttribute.isDefault,
                defaultValue: this.entityAttribute.associatedEntityTypeAttribute,
                displayName: 'Associated Entity Type Attribute',
                description: 'The entity associated with attribute.',
                hasDefaultValue: true,
                name: 'associatedEntityTypeAttribute',
                value: this.entityAttribute.associatedEntityTypeAttribute,
                target: this.entityAttribute,
                getCellEditor: (props: CellEditorProps<any>) => <AsyncSelectCellEditor
                    {...props}
                    loadOptions={handleTargetEntityAttributeOptions}
                />,
                validate: _ => undefined,
            },
            {
                readOnly: entityAttribute.isDefault,
                defaultValue: this.entityAttribute.foreignKeyTypeAttribute,
                displayName: 'Foreign Key Type Attribute Name',
                description: 'The type of foreign key',
                hasDefaultValue: true,
                name: 'foreignKeyTypeAttribute',
                value: this.entityAttribute.foreignKeyTypeAttribute,
                target: this.entityAttribute,
                getCellEditor: (props: CellEditorProps<any>) => <TextCellEditor {...props}
                                                                                label='Foreign Key Type Attribute Name'
                                                                                description='The type of foreign key attribute'/>,
                validate: value => yup.string()
                    .required()
                    .min(EntityAttribute.MIN_NAME_LENGTH)
                    .max(EntityAttribute.MAX_NAME_LENGTH)
                    .matches(NAME_PATTERN, 'this must only contain alphanumeric and no underscore consecutively')
                    .notOneOf(entityAttributes.map(t => t.foreignKeyTypeAttribute)
                        .filter(name => name !== this.entityAttribute.foreignKeyTypeAttribute))
                    .validateSync(value),
            },
            {
                readOnly: entityAttribute.isDefault,
                defaultValue: this.entityAttribute.foreignKeyDataType,
                displayName: 'Foreign Key Data Type',
                description: 'The data type of foreign key',
                hasDefaultValue: true,
                name: 'foreignKeyDataType',
                value: this.entityAttribute.foreignKeyDataType,
                target: this.entityAttribute,
                getCellEditor: (props: CellEditorProps<any>) => <SelectCellEditor {...props}
                                                                                  options={dataTypeOptions}/>,
                validate: _ => undefined,
            },
            {
                readOnly: entityAttribute.isDefault,
                defaultValue: this.entityAttribute.description,
                displayName: 'Description',
                description: 'The description of entity.',
                hasDefaultValue: true,
                name: 'description',
                value: this.entityAttribute.description,
                target: this.entityAttribute,
                getCellEditor: (props: CellEditorProps<any>) => <TextCellEditor {...props} label='Description'
                                                                                description='The description of entity'/>,
                validate: _ => undefined,
            },
        ];
    }

    get iconClass(): string {
        return '';
    }

    get title(): string {
        return 'Entity Attribute';
    }

    dispose(): void {
    }

    getPropertyDescriptors(): PropertyDescriptor[] {
        return this.descriptors;
    }

    async setProperty(name: string, newValue: any): Promise<void> {
        const oldAssociatedEntityId = this.entityAttribute.associatedEntity?.id;
        let oldValue: any;
        let update = false;
        switch (name) {
            case EntityAttribute.PROP_FOREIGN_KEY_PURPOSE:
                oldValue = this.entityAttribute.foreignKeyPurpose;
                if (oldValue !== newValue as Purpose) {
                    this.entityAttribute.foreignKeyPurpose = newValue as Purpose;
                    update = true;
                }
                break;
            case EntityAttribute.PROP_FOREIGN_KEY_TYPE:
                oldValue = this.entityAttribute.foreignKeyTypeAttribute;
                if (oldValue !== newValue) {
                    this.entityAttribute.foreignKeyTypeAttribute = newValue as string;
                    update = true;
                }
                break;
            case EntityAttribute.PROP_FOREIGN_KEY_DATATYPE:
                oldValue = this.entityAttribute.foreignKeyDataType;
                if (oldValue !== newValue as DataType) {
                    this.entityAttribute.foreignKeyDataType = newValue as DataType;
                    update = true;
                }
                break;
            case EntityAttribute.PROP_ASSOCIATED_ENTITY:
                oldValue = this.entityAttribute.associatedEntity;
                if (oldValue !== newValue) {
                    this.entityAttribute.associatedEntity = newValue as AssociatedEntity;
                    update = true;
                }
                break;
            case EntityAttribute.PROP_ASSOCIATED_ENTITY_TYPE_ATTRIBUTE:
                oldValue = this.entityAttribute.associatedEntityTypeAttribute;
                if (oldValue !== newValue) {
                    this.entityAttribute.associatedEntityTypeAttribute = newValue as string;
                    update = true;
                }
                break;
            case EntityAttribute.PROP_DESCRIPTION:
                oldValue = this.entityAttribute.description;
                if (oldValue !== newValue) {
                    this.entityAttribute.description = newValue;
                    update = true;
                }
                break;
        }
        if (update && this.entityAttribute.parent?.documentType === CDMDocumentType.ENTITY && oldAssociatedEntityId) {
            const entity = this.entityAttribute.parent as Entity;
            await this.entityService.updateEntityAttribute(entity.id, oldAssociatedEntityId, mapToUpdateEntityAttribute(this.entityAttribute));
        }
    }

    getModel(): CDMDocument {
        return this.entityAttribute;
    }

}

export interface AssociatedEntity {
    id: number;
    name: string;
    path: string;
}

export const mapToCreateEntityAttribute = (entityAttribute: EntityAttribute): Partial<CreateEntityAttribute> => {
    return {
        traitReference: entityAttribute.traitReference,
        simple: entityAttribute.simple.toString(),
        associatedEntityId: entityAttribute.associatedEntity?.id,
        associatedEntityName: entityAttribute.associatedEntity?.name,
        associatedEntityPath: entityAttribute.associatedEntity?.path,
        associatedEntityTypeAttribute: entityAttribute.associatedEntityTypeAttribute,
        description: entityAttribute.description,
        foreignKeyAttribute: {
            purpose: entityAttribute.foreignKeyPurpose,
            dataType: entityAttribute.foreignKeyDataType,
            typeAttributeName: entityAttribute.foreignKeyTypeAttribute,
        }
    }
};

export const mapToUpdateEntityAttribute = (entityAttribute: EntityAttribute): Partial<UpdateEntityAttribute> => {
    return mapToCreateEntityAttribute(entityAttribute);
};